import * as React from "react";

import { TextFieldProps } from "@material-ui/core/TextField";
import AsyncAutocomplete from "../../../../../components/AsyncAutocomplete";
import subjectsListHttp from "../../../../../utils/http/subjectsList-http";

interface MateriaFieldProps {
  setMateria: (materia: any) => void;
  textFieldProps?: TextFieldProps;
}
const MateriaField: React.FC<MateriaFieldProps> = (props) => {
  const { setMateria, textFieldProps } = props;

  function fetchOptions(search: string) {
    return subjectsListHttp
      .list({
        queryParam: {
          title: search,
        },
      })
      .then((response) => response.data.data);
  }

  return (
    <AsyncAutocomplete
      fetchOptions={fetchOptions}
      AutocompleteProps={{
        freeSolo: true,
        getOptionLabel: (option) => {
          return option.title;
        },
        onChange: (event, value) => {
          setMateria(value.title);
        },
        disableClearable: true,
      }}
      TextFieldProps={{
        label: "Matéria",
        onChange: (event) => setMateria(event.target.value),
        ...textFieldProps,
      }}
    />
  );
};

export default MateriaField;
