import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components/macro";
import {
  Card as MuiCard,
  CardContent,
  TextField as MuiTextField,
  Typography,
  Box,
  FormLabel,
  FormControlLabel,
  MenuItem,
  Checkbox,
  FormControl,
  FormGroup,
  FormHelperText,
} from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import * as yup from "../../../../../vendor/yup";
import { spacing } from "@material-ui/system";
import ButtonIcon from "../../../../../components/ButtonIcon";
import InputMask from "react-input-mask";
import { useSnackbar } from "notistack";
import { ResponseType } from "../../../../../types/response";
import { makeStyles } from "@material-ui/styles";
import { ActivityMetasForm } from "../../../../../types/activities";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../../../redux/reducers";
import { TopicType } from "../../../../../types/topics";
import { SubjectType } from "../../../../../types/subjects";
import TypesField from "../../../../Activity/components/TypesField";
import { timeToSec } from "../../../../../utils/Times";
import { weekDays } from "../../../../../config/constantes";
import { Type } from "../../../../../types/types";
import typesHttp from "../../../../../utils/http/types-http";
import { isEmpty } from "../../../../../utils/functions";
import planningsHttp from "../../../../../utils/http/plannings-http";

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const useStyles = makeStyles({
  root: {
    maxWidth: 600,
    marginLeft: "auto",
    marginRight: "auto",
  },
});

interface FormProps {
  onClose: () => void;
  getPlanning: () => void;
}
// const validationUrl = yup.object().shape({
//   subjectId: yup
//     .string()
//     .transform((value) => (!value ? undefined : value))
//     .default(""),
//   topicId: yup
//     .string()
//     .transform((value) => (!value ? undefined : value))
//     .default(""),
//   types: yup.mixed().transform(function (value) {
//     const newValue: number[] = [];
//     value.forEach((row: any) => {
//       if (!isNaN(row)) {
//         newValue.push(row);
//       }
//     });
//     return newValue;
//   }),
//   seconds: yup.mixed().transform(function (value) {
//     if (!isNaN(value)) {
//       return value;
//     }
//     return 0;
//   }),
// });

const SchemaValidation = yup.object({
  days: yup.array().min(1, "Dia é Requerido").label("Dia").required(),
  subject_id: yup.string().nullable().label("Matéria").required("Obrigatório"),
  time: yup
    .string()
    .required("Obrigatório")
    .min(5, "Inválido")
    .transform((value, originalValue) => {
      let hour = parseInt(originalValue.substr("0", "2"), 10);
      let minute = parseInt(originalValue.substr("3", "2"), 10);
      let seg = parseInt(originalValue.substr("6", "6"), 10);

      hour = Number.isNaN(hour) ? 0 : hour;
      minute = Number.isNaN(minute) ? 0 : minute;
      seg = Number.isNaN(seg) ? 0 : seg;

      if (hour > 23) {
        return "f";
      }
      if (minute > 59) {
        return "f";
      }
      if (seg > 59) {
        return "f";
      }
      return (
        hour.toString().padStart(2, "0") +
        ":" +
        minute.toString().padStart(2, "0") +
        ":" +
        seg.toString().padStart(2, "0")
      );
    }),
  types: yup.array().min(1, "Tipo é Requerido").label("Tipo").required(),
});

const Form: FC<FormProps> = ({ onClose, getPlanning }) => {
  const INITIAL_FORM = useMemo(() => {
    return {
      days: [],
      subject_id: "",
      topic_id: "",
      types: [],
      time: "",
      createDate: 0,
    };
  }, []);
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<ActivityMetasForm>({
    resolver: yupResolver(SchemaValidation),
    defaultValues: INITIAL_FORM,
  });
  const classes = useStyles();
  const snackbar = useSnackbar();
  const [topics, setTopics] = useState<TopicType[]>([]);

  const [optionsType, setOptionsType] = useState<Type[]>([]);

  const plan = useSelector((state: AppStateType) => state.planReducer);

  const watchSubjectId = watch("subject_id");

  const handleSetOptionsTopics = useCallback(() => {
    const subject: SubjectType | undefined = plan.active?.subjects?.find(
      (item) => item.id === watch("subject_id")
    );
    setTopics(subject?.topics || []);
  }, [plan.active.subjects, watch]);

  const changeTypes = useCallback(
    (value) => {
      setValue("types", value);
    },
    [setValue]
  );

  useEffect(() => {
    let active = true;
    (async () => {
      const response = await typesHttp.list<ResponseType<Type[]>>();
      if (active) {
        setOptionsType(response.data.data);
      }
    })();

    return () => {
      active = false;
    };
  }, []);

  useEffect(() => {
    handleSetOptionsTopics();
  }, [handleSetOptionsTopics, watchSubjectId]);

  function handleSelect(checkedName: string) {
    const checkedValues = getValues("days");
    const newNames = checkedValues?.includes(checkedName)
      ? checkedValues?.filter((name) => name !== checkedName)
      : [...(checkedValues ?? []), checkedName];

    return newNames;
  }

  const onSubmit: SubmitHandler<ActivityMetasForm> = async (formData) => {
    if (isEmpty(plan.active)) {
      return;
    }
    // const sendData = omit(formData, ["date", "hours"]);
    formData["duration"] = timeToSec(
      formData.time.substr(0, 2),
      formData.time.substr(3, 2),
      formData.time.substr(6, 2)
    );
    formData["createDate"] = new Date().getTime();

    try {
      await planningsHttp.createRelationship(
        `schedule/${plan?.active?.id}`,
        formData
      );
      getPlanning();
      onClose();

      snackbar.enqueueSnackbar("Salvo com sucesso", {
        variant: "success",
      });
    } catch (error) {
      console.log(error);
      snackbar.enqueueSnackbar("Não é possível salvar a atividade", {
        variant: "error",
      });
    }
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Adicionar Meta
        </Typography>
        <Typography variant="body2" gutterBottom>
          Planeje seus estudos futuros
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Dia da Semana</FormLabel>
            <FormGroup row={true}>
              <Controller
                name="days"
                control={control}
                defaultValue={[]}
                render={({ field: { onChange, value } }) => {
                  return (
                    <>
                      {weekDays.map((row) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={value.includes(row.cod)}
                              onChange={() => onChange(handleSelect(row.cod))}
                            />
                          }
                          key={row.cod}
                          label={row.codLabel}
                        />
                      ))}
                    </>
                  );
                }}
              />
            </FormGroup>
            <FormHelperText error>
              {errors.days !== undefined && "Selecione o dia da semana"}
            </FormHelperText>
          </FormControl>

          <Controller
            name="subject_id"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                margin={"normal"}
                select
                fullWidth
                error={errors.subject_id !== undefined}
                helperText={errors.subject_id?.message}
                id="subject-id"
                label="Materia"
                variant={"outlined"}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  setValue("topic_id", null);
                  field.onChange(e);
                }}
              >
                {plan?.active?.subjects?.map((item) => (
                  <MenuItem value={item.id} key={item.id}>
                    {item.title}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />

          <Controller
            name="topic_id"
            control={control}
            render={({ field }) => (
              <TextField
                margin={"normal"}
                select
                fullWidth
                id="topic-id"
                error={errors.topic_id !== undefined}
                helperText={errors.topic_id?.message}
                label="Assunto"
                variant={"outlined"}
                InputLabelProps={{ shrink: true }}
                {...field}
              >
                {topics.map((item) => (
                  <MenuItem value={item.id} key={item.id}>
                    {item.title}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <Controller
            name="time"
            control={control}
            render={({ field }) => (
              <InputMask {...field} mask={"99:99:99"}>
                {(inputProps: any) => (
                  <TextField
                    {...inputProps}
                    margin={"normal"}
                    id="time-id"
                    label="Tempo de Estudo"
                    variant="outlined"
                    fullWidth
                    inputProps={{ inputMode: "numeric" }}
                    error={errors.time !== undefined}
                    helperText={errors.time?.message}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              </InputMask>
            )}
          />
          <TypesField
            options={optionsType}
            types={watch("types")}
            setTypes={(value) => changeTypes(value)}
            error={errors.types}
          />

          <Box style={{ marginTop: "10px" }}>
            <ButtonIcon
              title={"Salvar Meta"}
              titleTootip={"Salvar informações"}
              icon={"save"}
              type={"submit"}
            />
          </Box>
        </form>
      </CardContent>
    </Card>
  );
};

export default Form;
