import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import "react-dragula/dist/dragula.css";
import { Grid, Typography as MuiTypography } from "@material-ui/core";

import { spacing } from "@material-ui/system";
import {
  a11yProps,
  AntTab,
  AntTabs,
  TabPanel,
} from "../../components/Material/CustomizedTabs";
import { useSelector } from "react-redux";
import { AppStateType } from "../../redux/reducers";
import { ResponseType } from "../../types/response";
import reviewsHttp from "../../utils/http/reviews-http";
import { useSnackbar } from "notistack";
import { ReviewType } from "../../types/reviews";
import { add, isEqual, isPast, parseISO, startOfDay } from "date-fns";

import Loader from "../../components/Loader";
import ButtonIcon from "../../components/ButtonIcon";
import { confirmAlert } from "react-confirm-alert";
import EmAberto from "./components/EmAberto";
import Futures from "./components/Future";

const Typography = styled(MuiTypography)(spacing);

function Revisoes() {
  const snackbar = useSnackbar();
  const [reviewsToday, setReviewsToday] = useState<ReviewType[]>([]);
  const [reviewsPast, setReviewsPast] = useState<ReviewType[]>([]);
  const [reviewsFutures, setReviewsFutures] = useState<ReviewType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [value, setValue] = React.useState(0);

  const plan = useSelector((state: AppStateType) => state.planReducer);

  const getReviews = useCallback(async () => {
    setLoading(true);
    if (plan.active.id) {
      try {
        const { data } = await reviewsHttp.listRelationship<
          ResponseType<ReviewType[]>
        >(plan.active?.id);
        setReviewsToday(
          data.data.filter((row) =>
            isEqual(parseISO(row.date.substr(0, 10)), startOfDay(new Date()))
          )
        );
        setReviewsPast(
          data.data.filter((row) =>
            isPast(
              add(parseISO(row.date), { hours: 23, minutes: 59, seconds: 59 })
            )
          )
        );
      } catch (error) {
        console.error(error);
        snackbar.enqueueSnackbar("Não foi possível carregar as informações", {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    }
  }, [plan.active, snackbar]);

  const getReviewsFuture = useCallback(async () => {
    setLoading(true);
    if (plan.active.id) {
      try {
        const { data } = await reviewsHttp.listRelationshipResource<
          ResponseType<ReviewType[]>
        >(plan.active?.id, "futures");
        setReviewsFutures(data.data);
      } catch (error) {
        console.error(error);
        snackbar.enqueueSnackbar("Não foi possível carregar as informações", {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    }
  }, [plan.active, snackbar]);

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getReviews();
      getReviewsFuture();
    }
    return () => {
      isSubscribed = false;
    };
  }, [getReviews, getReviewsFuture]);

  const destroyAll = async () => {
    confirmAlert({
      title: "Deseja realmente excluir todas revisões?",
      message: "Suas revisões de estudo serão excluídas permanente",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            if (plan.active.id) {
              reviewsHttp.deleteResource(plan.active.id, "all").then(() => {
                getReviews();
              });
            }
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <Helmet title="Revisões" />

      <Grid justify="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            Revisões
          </Typography>
        </Grid>
        {reviewsToday.length > 0 ||
          (reviewsPast.length > 0 && (
            <Grid item>
              <ButtonIcon
                title={"Deletar Tudo"}
                icon={"delete"}
                titleTootip={"Deletar todas revisões"}
                onClick={destroyAll}
              />
            </Grid>
          ))}
        <Grid item xs={12}>
          <AntTabs
            value={value}
            onChange={handleChange}
            aria-label="ant example"
          >
            <AntTab label="EM ABERTO" {...a11yProps("em-aberto")} />
            <AntTab label="FUTURAS" {...a11yProps("futuras")} />
          </AntTabs>
        </Grid>
      </Grid>
      <TabPanel index={0} value={value}>
        <EmAberto
          reviewsToday={reviewsToday}
          reviewsPast={reviewsPast}
          getReviews={getReviews}
        />
      </TabPanel>
      <TabPanel index={1} value={value}>
        <Futures
          title={"Próximas revisões"}
          onDelete={() => {}}
          reviews={reviewsFutures.filter((row) => row.date !== null)}
        />
        <Futures
          title={"Revisões aguardando..."}
          onDelete={() => {}}
          reviews={reviewsFutures.filter((row) => row.date === null)}
        />
      </TabPanel>
    </React.Fragment>
  );
}

export default Revisoes;
