import * as React from "react";
import "draft-js/dist/Draft.css";
import { FC, useCallback, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import ButtonIcon from "../../../../components/ButtonIcon";
import notebooksHttp from "../../../../utils/http/notebooks-http";
import { useSnackbar } from "notistack";
import { convertFromRaw, convertToRaw, EditorState, RichUtils } from "draft-js";
import "draft-js/dist/Draft.css";
import createToolbarPlugin from "@draft-js-plugins/static-toolbar";
import Editor from "@draft-js-plugins/editor";

interface CadernoType {
  subjectId: string;
}
const toolbarPlugin = createToolbarPlugin();
const { Toolbar } = toolbarPlugin;

const Caderno: FC<CadernoType> = ({ subjectId }) => {
  const snackbar = useSnackbar();
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [editor, setEditor] = useState("");

  useEffect(() => {
    async function getNotebook() {
      try {
        const { data } = await notebooksHttp.get(subjectId);
        if (!data.data.notes) {
          setEditorState(EditorState.createEmpty());
        } else {
          const text = JSON.parse(data.data.notes);
          if (text) {
            setEditorState(EditorState.createWithContent(convertFromRaw(text)));
          }
        }
      } catch (e) {
        console.log(e.error);
        snackbar.enqueueSnackbar("Não foi possível salvar as informações", {
          variant: "error",
        });
      }
    }
    getNotebook();
  }, [subjectId, snackbar]);

  const onSave = () => {
    if (!editor) {
      return;
    }

    try {
      notebooksHttp.createRelationship(subjectId, {
        notes: editor,
      });
      snackbar.enqueueSnackbar("Caderno Salvo!", {
        variant: "success",
      });
    } catch (e) {
      console.log(e.error);
      snackbar.enqueueSnackbar("Não foi possível salvar as informações", {
        variant: "error",
      });
    }
  };

  const onChange = useCallback(
    (editorStateNew: any) => {
      setEditorState(editorStateNew);
      const stateContent = editorStateNew.getCurrentContent();
      setEditor(JSON.stringify(convertToRaw(stateContent)));
    },
    [setEditor]
  );
  const handleKeyCommand = (command: any, editorState: any) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      onChange(newState);
      return "handled";
    }

    return "not-handled";
  };
  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <div className="editor">
          <Toolbar />
          <Editor
            plugins={[toolbarPlugin]}
            editorState={editorState}
            onChange={onChange}
            handleKeyCommand={handleKeyCommand}
            placeholder={"Resumo da matéria"}
          />
        </div>
      </Grid>
      <Grid item xs={12}>
        <ButtonIcon
          title={"Salvar"}
          titleTootip={"Salvar resumo"}
          icon={"save"}
          onClick={onSave}
        />
      </Grid>
    </Grid>
  );
};

export default Caderno;
