import React from "react";
import styled from "styled-components/macro";
import { rgba } from "polished";
import { Box, Container, Grid, Typography } from "@material-ui/core";
import {
  Calendar as CalendarIcon,
  Clock as ClockIcon,
  BarChart2 as BarChart2Icon,
  Clipboard as ClipboardIcon,
  Repeat as RepetIcon,
  RefreshCcw as RefreshCcwIcon,
} from "react-feather";

const TypographyOverline = styled(Typography)`
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.primary.main};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;
const FeatureWrapper = styled.div`
  display: flex;
  text-align: left;
  padding: 18px 20px;
`;
const FeatureIcon = styled.div`
  svg {
    flex-shrink: 0;
    width: auto;
    height: 48px;
    width: 48px;
    background: ${(props) => rgba(props.theme.palette.primary.main, 0.15)};
    color: ${(props) => props.theme.palette.primary.main};
    padding: 10px;
    border-radius: 50%;
  }
`;

type FeatureProps = {
  Icon: React.ElementType;
  title: string;
  description: string;
};
const Feature: React.FC<FeatureProps> = ({ Icon, title, description }) => {
  return (
    <Grid item xs={12} sm={6} md={4} lg={4}>
      <FeatureWrapper>
        <FeatureIcon>
          <Icon />
        </FeatureIcon>
        <Box ml={6}>
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {description}
          </Typography>
        </Box>
      </FeatureWrapper>
    </Grid>
  );
};
function Features() {
  return (
    <Container>
      <TypographyOverline variant="body2" gutterBottom>
        Funcionalidades
      </TypographyOverline>

      <Box mb={8} />
      <Grid container spacing={6}>
        <Feature
          Icon={RefreshCcwIcon}
          title="Ciclo de Estudos"
          description="Ciclo de estudos é uma técnica de estudo para melhoria da aprendizagem e gestão do tempo. Muito utilizada por aprovados em concursos difíceis."
        />
        <Feature
          Icon={CalendarIcon}
          title="Cronograma Semanal"
          description="Técnica muito usada antes do ciclo de estudo. Você também pode organizar seus estudos através de cronogramas semanais."
        />
        <Feature
          Icon={ClockIcon}
          title="Tempo de Estudo"
          description="Saiba suas horas efetivas de estudo de cada matéria. Marque seu tempo com o cronômetro e descubra seu tempo médio de estudo."
        />
        <Feature
          Icon={RepetIcon}
          title="Revisões"
          description="Revisões personalizadas por assunto. Decida em quantos dias você deseja revisar um assunto. Não fique mais perdido!"
        />
        <Feature
          Icon={BarChart2Icon}
          title="Desempenho"
          description="Saiba a porcentagem de acerto e erros de cada matéria estudada. Ideal para você atingir o equilíbrio no seu estudo."
        />
        <Feature
          Icon={ClipboardIcon}
          title="Relatórios"
          description="Relatórios completos mostram com precisão aquilo que você mais precisa saber: qual disciplina você tem maior dificuldade e facilidade."
        />
      </Grid>
    </Container>
  );
}
export default Features;
