import { Time } from "./utils";
import { useInterval } from "./hooks";
import { useDispatch, useSelector } from "react-redux";
import {
  resetTime,
  setIntervalId,
  setIsRunning,
  setTime,
} from "../../redux/actions/timeStudyActions";
import { useCallback } from "react";
import { setSettingsTimer } from "../../redux/actions/settingsActions";

export default function useStopwatch() {
  const dispatch = useDispatch();
  const timeStudy = useSelector((state: any) => state.timeStudyReducer);

  useInterval(
    () => {
      dispatch(
        setTime({
          seconds:
            timeStudy.time.passedSeconds +
            Time.getSecondsFromPrevTime(timeStudy.time.prevTime, true),
        })
      );
    },
    timeStudy.isRunning ? 1000 : null,
    timeStudy.interval
  );

  const killInterval = useCallback(() => {
    clearInterval(timeStudy.interval);
    dispatch(setIntervalId(null));
  }, [dispatch, timeStudy.interval]);

  const start = useCallback(() => {
    const newPrevTime = Date.now();
    dispatch(setIsRunning(true));
    dispatch(setSettingsTimer(true));
    dispatch(
      setTime({
        prevTime: newPrevTime,
      })
    );
  }, [dispatch]);

  const pause = useCallback(() => {
    dispatch(
      setTime({
        passedSeconds: timeStudy.time.seconds,
      })
    );
    dispatch(setIsRunning(false));
    dispatch(setSettingsTimer(false));
    killInterval();
  }, [dispatch, killInterval, timeStudy.time.seconds]);

  function reset() {
    dispatch(resetTime());
    dispatch(setSettingsTimer(false));
  }

  return {
    ...Time.getTimeFromSeconds(timeStudy.time.seconds),
    start,
    pause,
    reset,
    killInterval,
    isRunning: timeStudy.isRunning,
  };
}
