import axios from "../utils/axios";

import {
  ResetPasswordType,
  SignInGoogleType,
  SignInType,
  SignUpType,
} from "../types/auth";

import httpAxios from "../utils/http";
import userHttp from "../utils/http/user-http";

export function signIn(
  credentials: SignInType | SignInGoogleType,
  url: string
) {
  return new Promise((resolve, reject) => {
    httpAxios.get("sanctum/csrf-cookie").then(() => {
      httpAxios
        .post(url, credentials)
        .then((response) => {
          if (response.status === 200) {
            userHttp.list().then((res) => {
              const data = res.data.data;
              data["created"] = response.data.created ?? false;
              resolve(data);
            });
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  });
}

export function signUp(credentials: SignUpType) {
  return new Promise((resolve, reject) => {
    httpAxios.get("sanctum/csrf-cookie").then(() => {
      httpAxios
        .post("/register", credentials)
        .then((response) => {
          userHttp.list().then((res) => {
            resolve(res.data.data);
          });
          //reject(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  });
}

export function getUser() {
  return new Promise((resolve, reject) => {
    httpAxios
      .get("api/user")
      .then((resp) => {
        resolve(resp.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function resetPassword(credentials: ResetPasswordType) {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/auth/reset-password", credentials)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function logout() {
  return new Promise((resolve, reject) => {
    httpAxios
      .post("logout")
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
