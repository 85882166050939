import React, { useState } from "react";
import styled from "styled-components/macro";
import {
  Container,
  Grid,
  Typography as MuiTypography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import ListPlans from "./Components/ListPlans";
import ButtonIcon from "../../../components/ButtonIcon";
import { useHistory } from "react-router-dom";
import PlanoFormCreateDialog from "../meus/PlanoFormCreateDialog";

const Typography = styled(MuiTypography)(spacing);

const Wrapper = styled.div`
  padding-top: 3.5rem;
  position: relative;
  text-align: center;
  overflow: hidden;
`;

const Content = styled.div`
  padding: ${(props) => props.theme.spacing(6)}px 0;
  line-height: 150%;
`;

const Title = styled(Typography)`
  opacity: 0.9;
  line-height: 1.4;
  font-size: 1.75rem;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};

  ${(props) => props.theme.breakpoints.up("sm")} {
    font-size: 2rem;
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 2.5rem;
  }

  span {
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

const Subtitle = styled(Typography)`
  font-size: ${(props) => props.theme.typography.h6.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  font-family: ${(props) => props.theme.typography.fontFamily};
  margin: ${(props) => props.theme.spacing(2)}px 0;
`;

const FullScreen = () => {
  const history = useHistory();
  const [open, setOpen] = useState<boolean>(false);

  const handleCallbackCopy = () => {
    history.push("/planos/meus-planos");
  };

  return (
    <Wrapper>
      <Container maxWidth="xl">
        <Grid container alignItems="center" justify="center" spacing={4}>
          <Grid item xs={12} sm={9} md={8} lg={7}>
            <Content>
              <Title variant="h1" gutterBottom>
                Escolha um Plano de Estudo ou Crie o Seu!
              </Title>
              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} lg={10}>
                  <Subtitle color="textSecondary">
                    Para começar usar a plataforma, você deve ter um plano de
                    estudo. Você pode selecionar nossos planos prontos ou criar
                    o seu!
                  </Subtitle>
                </Grid>
                <Grid item xs={12}>
                  <Grid justify="space-between" container spacing={10}>
                    <Grid item>
                      <Typography variant="h3" gutterBottom display="inline">
                        Planos de Estudo
                      </Typography>
                    </Grid>
                    <Grid item>
                      <div>
                        <ButtonIcon
                          id={"button-create-plan"}
                          title={"Criar meu plano"}
                          titleTootip={"Novo Plano de Estudo"}
                          icon={"add"}
                          onClick={() => setOpen(true)}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <ListPlans onCallbackCopy={handleCallbackCopy} />
                </Grid>
              </Grid>
            </Content>
          </Grid>
        </Grid>
      </Container>
      <PlanoFormCreateDialog open={open} setOpen={setOpen} />
    </Wrapper>
  );
};

export default FullScreen;
