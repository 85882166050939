import React, { FC } from "react";
import { ChartOptions } from "chart.js";
import { secToTime } from "../../utils/Times";
import Chart from "./BarChart";
import { ChartType } from "../../types/activities";

interface TimeChartProps {
  data: ChartType;
}

const options: ChartOptions<"bar"> = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: function (context: any) {
          let label = context.parsed.y || 0;

          return secToTime(label);
        },
      },
    },
  },
  scales: {
    y: {
      grid: {
        display: false,
      },
      stacked: false,
      min: 0,
      beginAtZero: true,
      ticks: {
        stepSize: 1800,
        callback: (tickValue, index, ticks) => {
          if (!(index % Math.floor(ticks.length / 12))) {
            return secToTime(tickValue as number);
          }
          return tickValue;
        },
      },
    },
    x: {
      stacked: false,
      grid: {
        color: "transparent",
      },
    },
  },
};

const TimeChart: FC<TimeChartProps> = ({ data }) => {
  return <Chart data={data.value} labels={data.label} options={options} />;
};

export default TimeChart;
