import * as React from "react";
import { Grid } from "@material-ui/core";
import CardStatus from "../../../../components/CardStatus";
import ProgressChart from "../../../../components/Charts/ProgressChart";
import { FC, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../../redux/reducers";
import { useSnackbar } from "notistack";
import activitiesHttp from "../../../../utils/http/activities-http";
import { secToTime } from "../../../../utils/Times";
import Configure from "./configure";

interface MetasType {
  open: boolean;
  setOpen: (value: boolean) => void;
}

export const Metas: FC<MetasType> = ({ open, setOpen }) => {
  const snackbar = useSnackbar();
  const [data, setData] = useState({
    label: [],
    duration: [],
    questions: [],
    durationRemaining: -1,
    questionsRemaining: -1,
    durationMeta: 0,
    questionsMeta: 0,
  });
  const plan = useSelector((state: AppStateType) => state.planReducer);

  const getMeta = useCallback(async () => {
    if (plan.active.id) {
      try {
        const { data } = await activitiesHttp.getResource(
          plan.active.id,
          "meta"
        );

        setData(data.data);
      } catch (error) {
        console.error(error);
        snackbar.enqueueSnackbar("Não foi possível carregar as informações!", {
          variant: "error",
        });
      }
    }
  }, [plan.active.id, snackbar]);

  useEffect(() => {
    getMeta();
  }, [snackbar, plan.active, getMeta]);
  return (
    <>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={6}>
          <CardStatus
            title={"Tempo"}
            content={
              data.durationRemaining === -1
                ? "Concluído!"
                : `${secToTime(data.durationRemaining, 4)} restante(s)`
            }
            footerContent={`Média de ${secToTime(
              data.durationMeta / 7,
              4
            )} por dia`}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CardStatus
            title={"Questões"}
            content={
              data.questionsRemaining === -1
                ? "Concluído!"
                : `Faltam ${data.questionsRemaining}`
            }
            footerContent={`Média de ${Math.round(
              data.questionsMeta / 7
            )} questões por dia`}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ProgressChart
            title={"Tempo"}
            subheader={`Meta Semanal - ${secToTime(data.durationMeta, 4)}`}
            data={data.duration}
            labels={data.label}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ProgressChart
            title={"Questões"}
            subheader={`Meta Semanal - ${data.questionsMeta} Questões`}
            data={data.questions}
            labels={data.label}
          />
        </Grid>
      </Grid>
      <Configure open={open} setOpen={setOpen} onGetMeta={getMeta} />
    </>
  );
};
