import React, { useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";

import {
  Button,
  Paper,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { SignInType } from "../../types/auth";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import forgotPasswordHttp from "../../utils/http/password-http";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;
const SchemaValidation = Yup.object().shape({
  email: Yup.string()
    .email("Email inválido")
    .max(255)
    .required("Email é obrigatório"),
});

function ResetPassword() {
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorResponse, setErrorResponse] = useState<string[]>([]);
  const [message, setMessage] = useState<string>("");
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<SignInType>({
    resolver: yupResolver(SchemaValidation),
    defaultValues: {
      email: "",
    },
  });
  const onSubmit: SubmitHandler<SignInType> = async (formData) => {
    setLoading(true);
    try {
      const data = await forgotPasswordHttp.create(formData);
      setMessage(data.data.message);
    } catch (error) {
      if (error.response.status === 422) {
        setErrorResponse(error.response.data.errors.email);
      } else {
        snackbar.enqueueSnackbar("Não é possível realizar o login", {
          variant: "error",
        });
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <Wrapper>
      <Helmet title="Reset Password" />

      <Typography component="h1" variant="h4" align="center" gutterBottom>
        Resetar Senha
      </Typography>
      <Typography component="h2" variant="body1" align="center">
        Digite seu e-mail para resetar sua senha
      </Typography>

      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        {errorResponse.length > 0 && (
          <Alert mt={2} mb={1} severity="warning">
            {errorResponse.map((row) => row)}
          </Alert>
        )}
        {message && (
          <Alert mt={2} mb={1} severity="success">
            {message}
          </Alert>
        )}
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              type="text"
              label="Email"
              error={errors.email !== undefined}
              helperText={errors.email?.message}
              fullWidth
              my={3}
            />
          )}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={loading}
        >
          Resetar senha
        </Button>
      </form>
    </Wrapper>
  );
}

export default ResetPassword;
