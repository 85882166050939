import React, { useCallback, useEffect, useState } from "react";
import styled, { withTheme, ThemeProps } from "styled-components/macro";

import { Helmet } from "react-helmet-async";

import {
  Grid,
  Typography as MuiTypography,
  Theme,
  Button,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import Actions from "./Components/Actions";

import BarChart from "./Components/Charts/BarChart";
import { ResponseType } from "../../types/response";

import activitiesHttp from "../../utils/http/activities-http";
import { ActivityCardType } from "../../types/activities";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { AppStateType } from "../../redux/reducers";
import CardStatus from "../../components/CardStatus";
import Loader from "../../components/Loader";
import TypeOfStudy from "./Components/Charts/TypeOfStudy";
import { SubjectListStaticsType } from "../../types/subjects";
import subjectsHttp from "../../utils/http/subjects-http";
import { useParams } from "react-router";
import plansHttp from "../../utils/http/plans-http";
import { TopicListStaticsType } from "../../types/topics";
import {
  a11yProps,
  AntTab,
  AntTabs,
  TabPanel,
} from "../../components/Material/CustomizedTabs";
import TopicsTable from "./Components/Table/TopicsTable";
import MateriasTable from "./Components/Table/MateriasTable";
import Carderno from "./Components/Caderno";
import Notes from "./Components/Notes";
import * as yup from "../../vendor/yup";
import { useHistory } from "react-router-dom";
import { format, parseISO } from "date-fns";

const Typography = styled(MuiTypography)(spacing);
const expreDate = /^([-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:)[0-5]\d)?|2400)([,]\d+(?!))?)?(\17[0-5]\d([,]\d+)?)?([zZ]|([-])([01]\d|2[0-3]):([0-5]\d)?)?)?)?$/;
const validation = yup.object().shape({
  startDate: yup
    .mixed()
    .nullable()
    .transform(function (value: string, originalValue: string) {
      if (expreDate.test(originalValue)) {
        const date = parseISO(originalValue);
        return format(date, "y-MM-dd");
      }
      return null;
    }),
  endDate: yup
    .mixed()
    .nullable()
    .transform(function (value: string, originalValue: string) {
      if (expreDate.test(originalValue)) {
        const date = parseISO(originalValue);
        return format(date, "y-MM-dd");
      }
      return null;
    }),
});
function Materia({ theme }: ThemeProps<Theme>) {
  const history = useHistory();
  const params = useParams<{ id?: string }>();
  const snackbar = useSnackbar();
  const [cards, setCards] = useState<ActivityCardType>({
    title: "Todas",
    top: [],
    progress: 0,
    typeOfStudy: {
      label: [],
      value: [],
    },
  });
  const [list, setList] = useState<
    SubjectListStaticsType[] | TopicListStaticsType[]
  >([]);
  const [value, setValue] = useState(0);

  const plan = useSelector((state: AppStateType) => state.planReducer);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const reset = () => {
    history.push(history.location.pathname);
  };

  const getStateFromURL = useCallback(() => {
    const queryParams = new URLSearchParams(history.location.search.substr(1));
    return validation.cast({
      startDate: queryParams.get("startDate"),
      endDate: queryParams.get("endDate"),
    });
  }, [history]);

  const getCards = useCallback(async () => {
    if (plan.active.id) {
      try {
        const { data } = await activitiesHttp.listRelationshipResource<
          ResponseType<ActivityCardType>
        >(plan?.active?.id, "cards", {
          queryParam: {
            ...(params.hasOwnProperty("id") ? { subject_id: params.id } : {}),
            ...(getStateFromURL().startDate && {
              start: getStateFromURL().startDate,
            }),
            ...(getStateFromURL().endDate && {
              end: getStateFromURL().endDate,
            }),
          },
        });
        setCards(data.data);
      } catch (error) {
        console.error(error);
        snackbar.enqueueSnackbar("Não foi possível carregar as informações", {
          variant: "error",
        });
      }
    }
  }, [plan.active, params, snackbar, getStateFromURL]);

  const getList = useCallback(async () => {
    if (plan.active.id) {
      const url = params.id
        ? subjectsHttp.listRelationshipResource<
            ResponseType<TopicListStaticsType[]>
          >(params.id, "topics-list", {
            queryParam: {
              ...(getStateFromURL().startDate && {
                start: getStateFromURL().startDate,
              }),
              ...(getStateFromURL().endDate && {
                end: getStateFromURL().endDate,
              }),
            },
          })
        : plansHttp.listRelationshipResource<
            ResponseType<SubjectListStaticsType[]>
          >(plan?.active?.id, "subjects-list", {
            queryParam: {
              ...(getStateFromURL().startDate && {
                start: getStateFromURL().startDate,
              }),
              ...(getStateFromURL().endDate && {
                end: getStateFromURL().endDate,
              }),
            },
          });
      try {
        const { data } = await url;
        setList(data.data);
      } catch (error) {
        console.error(error);
        snackbar.enqueueSnackbar("Não foi possível carregar as informações", {
          variant: "error",
        });
      }
    }
  }, [plan.active, params.id, snackbar, getStateFromURL]);

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getCards();
      getList();
    }
    return () => {
      isSubscribed = false;
    };
  }, [params.id, getCards, getList]);

  return (
    <React.Fragment>
      <Helmet title={cards.title} />

      <Grid justify="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            {cards.title}
          </Typography>
          <Typography variant="subtitle1">
            {getStateFromURL().startDate && getStateFromURL().endDate ? (
              <Grid container>
                <Grid item xs={12}>
                  Atividades de{" "}
                  {format(
                    new Date(`${getStateFromURL().startDate} 00:00:00`),
                    "dd/MM/y"
                  )}{" "}
                  a{" "}
                  {format(
                    new Date(`${getStateFromURL().endDate} 00:00:00`),
                    "dd/MM/y"
                  )}{" "}
                </Grid>
                <Grid item xs={12}>
                  <Button size={"small"} onClick={reset}>
                    Limpar
                  </Button>
                </Grid>
              </Grid>
            ) : (
              "Visão geral de todas atividades realizadas."
            )}
          </Typography>
        </Grid>
        <Grid item>
          <Actions />
        </Grid>
        <Grid item xs={12}>
          <AntTabs
            value={value}
            onChange={handleChange}
            aria-label="ant example"
          >
            <AntTab label="GRÁFICOS" {...a11yProps(0)} />
            <AntTab
              label={params.id ? "ASSUNTOS" : "MATÉRIAS"}
              {...a11yProps(1)}
            />
            {params.id && <AntTab label={"CADERNO"} {...a11yProps(2)} />}
            {params.id && <AntTab label={"NOTAS"} {...a11yProps(3)} />}
          </AntTabs>
        </Grid>
      </Grid>
      <TabPanel value={value} index={0}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Grid container spacing={6}>
              {cards ? (
                cards.top.map((item, index) => (
                  <Grid key={item.label} item xs={12} sm={12} md={6} lg={3} xl>
                    <CardStatus
                      title={item.label}
                      content={item.value}
                      footerContent={item.textFooter}
                    />
                  </Grid>
                ))
              ) : (
                <Grid item xs={12}>
                  <Loader />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item xs={12} lg={8}>
            <BarChart date={getStateFromURL()} />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TypeOfStudy data={cards.typeOfStudy} />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {params.id ? (
              <TopicsTable list={list} />
            ) : (
              <MateriasTable list={list} />
            )}
          </Grid>
        </Grid>
      </TabPanel>
      {params.id && (
        <TabPanel value={value} index={2}>
          <Carderno subjectId={params.id} />
        </TabPanel>
      )}
      {params.id && (
        <TabPanel value={value} index={3}>
          <Notes subjectId={params.id} />
        </TabPanel>
      )}

      {/*<Grid item xs={12} lg={12} xl={12}>*/}
      {/*  <TextField*/}
      {/*    id="standard-search"*/}
      {/*    label="Pesquisar"*/}
      {/*    type="search"*/}
      {/*    variant="outlined"*/}
      {/*    fullWidth={true}*/}
      {/*  />*/}
      {/*</Grid>*/}
      {/*{list.map((row: any) => {*/}
      {/*  let progress;*/}
      {/*  if (!params.id) {*/}
      {/*    let completeds = 0;*/}
      {/*    const topics = row.topics;*/}
      {/*    topics.forEach(function (value: any) {*/}
      {/*      if (value.completed) {*/}
      {/*        completeds++;*/}
      {/*      }*/}
      {/*    });*/}
      {/*    progress = Math.floor((completeds / topics.length) * 100);*/}
      {/*  }*/}

      {/*  return (*/}
      {/*    <Grid item xs={12} sm={4} md={4} lg={3} key={row.id}>*/}
      {/*      <CardMenuList*/}
      {/*        title={row.title}*/}
      {/*        progresso={!params.id ? progress : row.completed * 100}*/}
      {/*        menu={[*/}
      {/*          {*/}
      {/*            func: handleAction,*/}
      {/*            label: "Detalhes",*/}
      {/*          },*/}
      {/*        ]}*/}
      {/*        content={*/}
      {/*          <Grid container>*/}
      {/*            {!params.id && (*/}
      {/*              <Grid item xs={4}>*/}
      {/*                <Typography variant={"subtitle2"}>Assuntos</Typography>*/}
      {/*                <Typography variant={"body1"}>*/}
      {/*                  {row.topics.length}*/}
      {/*                </Typography>*/}
      {/*              </Grid>*/}
      {/*            )}*/}
      {/*            <Grid item xs={4}>*/}
      {/*              <Typography variant={"subtitle2"}>Tempo</Typography>*/}
      {/*              <Typography variant={"body1"}>*/}
      {/*                {secToTime(row.duration)}*/}
      {/*              </Typography>*/}
      {/*            </Grid>*/}
      {/*            <Grid item xs={4}>*/}
      {/*              <Typography variant={"subtitle2"}>Desempenho</Typography>*/}
      {/*              <Typography>*/}
      {/*                {row.desempenho ? parseInt(row.desempenho) : 0}%*/}
      {/*              </Typography>*/}
      {/*            </Grid>*/}
      {/*          </Grid>*/}
      {/*        }*/}
      {/*      />*/}
      {/*    </Grid>*/}
      {/*  );*/}
      {/*})}*/}
    </React.Fragment>
  );
}

export default withTheme(Materia);
