import React, { FC } from "react";
import { ChartOptions } from "chart.js";
import Chart from "./BarChart";
import { ChartType } from "../../types/activities";

interface TimeChartProps {
  data: ChartType;
}

const options: ChartOptions<"bar"> = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: function (context: any) {
          let label = context.parsed.y || 0;
          return label + "%";
        },
      },
    },
  },
  scales: {
    y: {
      grid: {
        display: false,
      },
      stacked: false,
      min: 0,
      max: 100,
      beginAtZero: true,
      ticks: {
        stepSize: 10,
      },
    },
    x: {
      stacked: false,
      grid: {
        color: "transparent",
      },
    },
  },
};

const QuestionsChart: FC<TimeChartProps> = ({ data }) => {
  return <Chart data={data.value} labels={data.label} options={options} />;
};

export default QuestionsChart;
