import HttpResource from "./http-resource";
import httpAxios from "./index";
import { AxiosResponse } from "axios";

class PlansHttpResource extends HttpResource {
  default<T = any>(): Promise<AxiosResponse<T>> {
    return this.http.get<T>(`${this.resource}/default`);
  }

  setDefault<T = any>(id: string): Promise<AxiosResponse<T>> {
    return this.http.put<T>(`${this.resource}/set-default`, {
      plan_id: id,
    });
  }
}

const plansHttp = new PlansHttpResource(httpAxios, "api/plans");

export default plansHttp;
