import React from "react";

import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
} from "@material-ui/pickers";

import { FormControl as MuiFormControl } from "@material-ui/core";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

const FormControlSpacing = styled(MuiFormControl)(spacing);

const FormControl = styled(FormControlSpacing)<{ m?: number }>``;

interface DateFieldProps extends KeyboardDatePickerProps {
  title: string;
}

const DateField = ({ title, ...others }: DateFieldProps) => {
  // const [selectedDate, setSelectedDate] = useState<MaterialUiPickersDate>(
  //   new Date()
  // );

  return (
    <FormControl variant="outlined" m={2} fullWidth>
      <KeyboardDatePicker
        autoOk
        {...others}
        variant="inline"
        inputVariant="outlined"
        label={title}
        format="dd/MM/yyyy"
        //value={selectedDate}
        InputAdornmentProps={{ position: "start" }}
        // onChange={(date) => {
        //   onChange(date);
        //   setSelectedDate(date);
        // }}
      />
    </FormControl>
  );
};

export default DateField;
