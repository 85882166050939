import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";

import {
  Card as MuiCard,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip as MuiChip,
  Grid,
  Typography as MuiTypography,
  Button as MuiButton,
} from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";

import { spacing, SpacingProps } from "@material-ui/system";
import { Helmet } from "react-helmet-async";
import planningsHttp from "../../../../utils/http/plannings-http";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../../redux/reducers";
import { secToTime } from "../../../../utils/Times";
import { weekDays } from "../../../../config/constantes";
import Loader from "../../../../components/Loader";
import {
  resetTime,
  setIsOpen,
  setSubjectId,
  setTopicId,
  setTypeStudy,
} from "../../../../redux/actions/timeStudyActions";
import { Type } from "../../../../types/types";
import { addDays, format, subDays } from "date-fns";
import WarningPage from "../../../../components/WarningPage";

const Typography = styled(MuiTypography)(spacing);

const Card = styled(MuiCard)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Button = styled(MuiButton)(spacing);

const CustomTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#243043",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 10,
  },
}))(TableCell);

const CustomTable = withStyles((theme) => ({
  root: {
    tableLayout: "fixed",
    "@media (max-height:700px)": { tableLayout: "auto" },
    width: "100%",
  },
}))(Table);

const ChipStyle = withStyles((theme) => ({
  sizeSmall: {
    fontSize: 9,
    padding: 0,
    height: 18,
    marginTop: 5,
  },
  labelSmall: {
    paddingLeft: 3,
    paddingRight: 3,
  },
}))(MuiChip);

interface MuiChipSpacingType extends SpacingProps {
  cor?: string;
}
const Chip = styled(ChipStyle)<MuiChipSpacingType>`
  margin-right: 3px;
  background-color: ${(props) => props.cor && props.cor};
  color: ${(props) => props.cor && props.theme.palette.common.white};
`;

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.025);
  }
`;

const useStyles = makeStyles({
  td: {
    fontSize: 12,
    border: "1px solid #e0e0e0",
    padding: "12px 7px",
    whiteSpace: "normal",
    verticalAlign: "top",
  },
  through: {
    textDecoration: "line-through",
  },
});

const Calendar = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [daysWeek, setDaysWeek] = useState<Date[]>([]);
  const [plannings, setPlannings] = useState<any>({
    max: 0,
    weeks: {
      Seg: [],
      Ter: [],
      Qua: [],
      Qui: [],
      Sex: [],
      Sab: [],
      Dom: [],
    },
  });
  const plan = useSelector((state: AppStateType) => state.planReducer);

  // Pegando os dias da semana
  useEffect(() => {
    const now = new Date();
    let days = [];
    days[now.getDay()] = now;

    function countDay() {
      return 7 - now.getDay();
    }

    for (let i = 1; i <= countDay(); i++) {
      let day = addDays(new Date(), i);
      days[day.getDay()] = day;
    }

    for (let i = now.getDay() - 1; i >= 1; i--) {
      let day = subDays(new Date(), i);
      days[day.getDay()] = day;
    }
    setDaysWeek(days);
  }, []);

  useEffect(() => {
    const getPlanning = async () => {
      setLoading(true);
      if (!plan.active.id) {
        return;
      }
      try {
        const { data } = await planningsHttp.getResource(
          plan.active.id,
          "calendar"
        );
        if (data.data) {
          setPlannings(data.data);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };

    getPlanning();
  }, [plan.active.id]);

  const handleStudy = (
    subjectId: string,
    topicId: string | null,
    types: Type[]
  ) => {
    dispatch(resetTime());
    dispatch(setSubjectId(subjectId));
    dispatch(setTopicId(topicId));
    dispatch(setTypeStudy(types.map((row) => row.id)));
    dispatch(setIsOpen(true));
  };

  const weeks = () => {
    let rows = [];

    for (let i = 0; i < plannings.max; i++) {
      rows.push(
        <CustomTableRow>
          {weekDays.map((row, index) =>
            plannings.weeks[row.codLabel][i] ? (
              <CustomTableCell
                className={`${classes.td} ${
                  plannings.weeks[row.codLabel][i]["concluded"] &&
                  classes.through
                }`}
              >
                {" "}
                <Typography variant="subtitle2" gutterBottom>
                  {plannings.weeks[row.codLabel][i]["subject"]}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {plannings.weeks[row.codLabel][i]["topic"]}
                </Typography>
                {plannings.weeks[row.codLabel][i]["duration"] > 0 && (
                  <Typography variant="body1">
                    {secToTime(plannings.weeks[row.codLabel][i]["duration"])}
                  </Typography>
                )}
                <br />
                {plannings.weeks[row.codLabel][i]["types"].map(
                  (row: any, index: number) => (
                    <Chip
                      label={row.title}
                      size={"small"}
                      cor={row.color}
                      m={1}
                      key={index}
                    />
                  )
                )}
                {!plannings.weeks[row.codLabel][i]["concluded"] && (
                  <Grid container justify={"flex-end"}>
                    <Grid item>
                      <Button
                        mr={2}
                        color="primary"
                        onClick={() =>
                          handleStudy(
                            plannings.weeks[row.codLabel][i]["subject_id"],
                            plannings.weeks[row.codLabel][i]["topic_id"],
                            plannings.weeks[row.codLabel][i]["types"]
                          )
                        }
                      >
                        Estudar
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </CustomTableCell>
            ) : (
              <CustomTableCell className={classes.td}> </CustomTableCell>
            )
          )}
        </CustomTableRow>
      );
    }
    return rows;
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet title={"Cronograma Semanal"} />

      <Grid justify="space-between" container spacing={4}>
        <Grid item xs={12}>
          <Card mb={6}>
            {plannings.max > 0 ? (
              <TableContainer component={Paper} style={{ overflowX: "hidden" }}>
                <CustomTable size="small">
                  <TableHead>
                    {daysWeek.length > 0 && (
                      <TableRow>
                        <CustomTableCell>
                          Segunda
                          <br />
                          {format(daysWeek[1], "dd/MM/Y")}
                        </CustomTableCell>
                        <CustomTableCell>
                          Terça
                          <br />
                          {format(daysWeek[2], "dd/MM/Y")}
                        </CustomTableCell>
                        <CustomTableCell>
                          Quarta
                          <br />
                          {format(daysWeek[3], "dd/MM/Y")}
                        </CustomTableCell>
                        <CustomTableCell>
                          Quinta
                          <br />
                          {format(daysWeek[4], "dd/MM/Y")}
                        </CustomTableCell>
                        <CustomTableCell>
                          Sexta
                          <br />
                          {format(daysWeek[5], "dd/MM/Y")}
                        </CustomTableCell>
                        <CustomTableCell>
                          Sábado
                          <br />
                          {format(daysWeek[6], "dd/MM/Y")}
                        </CustomTableCell>
                        <CustomTableCell>
                          Domingo
                          <br />
                          {format(daysWeek[0], "dd/MM/Y")}
                        </CustomTableCell>
                      </TableRow>
                    )}
                  </TableHead>
                  <TableBody>{weeks()}</TableBody>
                </CustomTable>
              </TableContainer>
            ) : (
              <WarningPage
                title={"Nenhuma meta adicionada!"}
                subtitle={
                  "Clique em CONFIGURAR METAS para configurar seu cronograma semanal."
                }
                img="/static/img/simulados.svg"
              />
            )}
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default Calendar;
