import React, { FC, useCallback, useEffect, useState } from "react";

import Editor from "@draft-js-plugins/editor";

import createToolbarPlugin from "@draft-js-plugins/static-toolbar";

import "./editorStyles.css";
import "@draft-js-plugins/static-toolbar/lib/plugin.css";
import { convertFromRaw, convertToRaw } from "draft-js";
import { EditorState, Modifier } from "draft-js";

const toolbarPlugin = createToolbarPlugin();
const { Toolbar } = toolbarPlugin;

interface EditorFieldProps {
  maxLength?: number;
  data: any;
  setEditor: (value: any) => void;
  placeholder: string;
}

const EditorField: FC<EditorFieldProps> = ({
  maxLength,
  data,
  setEditor,
  placeholder,
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const MAX_LENGTH = maxLength;

  useEffect(() => {
    if (!data) {
      return;
    } else {
      const text = JSON.parse(data);
      if (text) {
        setEditorState(EditorState.createWithContent(convertFromRaw(text)));
      }
    }
  }, [data]);

  const onChange = useCallback(
    (editorStateNew: any) => {
      setEditorState(editorStateNew);
      const stateContent = editorStateNew.getCurrentContent();
      if (stateContent.hasText()) {
        setEditor(JSON.stringify(convertToRaw(stateContent)));
      } else if (data) {
        setEditor(data);
      } else {
        setEditor(null);
      }
    },
    [setEditor, data]
  );

  const _getLengthOfSelectedText = () => {
    const currentSelection = editorState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();

    let length = 0;

    if (!isCollapsed) {
      const currentContent = editorState.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength =
        startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);
      if (isStartAndEndBlockAreTheSame) {
        length +=
          currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }

          currentKey = currentContent.getKeyAfter(currentKey);
        }
      }
    }

    return length;
  };

  const _removeSelection = () => {
    const selection = editorState.getSelection();
    const startKey = selection.getStartKey();
    const startOffset = selection.getStartOffset();
    const endKey = selection.getEndKey();
    const endOffset = selection.getEndOffset();
    if (startKey !== endKey || startOffset !== endOffset) {
      const newContent = Modifier.removeRange(
        editorState.getCurrentContent(),
        selection,
        "forward"
      );
      const tempEditorState = EditorState.push(
        editorState,
        newContent,
        "remove-range"
      );
      setEditorState(tempEditorState);
      return tempEditorState;
    }
    return editorState;
  };

  const _addPastedContent = (input: string, editorState: EditorState) => {
    if (MAX_LENGTH) {
      const inputLength = editorState.getCurrentContent().getPlainText().length;
      let remainingLength = MAX_LENGTH - inputLength;

      const newContent = Modifier.insertText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        input.slice(0, remainingLength)
      );
      setEditorState(
        EditorState.push(editorState, newContent, "insert-characters")
      );
    }
  };

  const __handleBeforeInput = () => {
    if (MAX_LENGTH) {
      const currentContent = editorState.getCurrentContent();
      const currentContentLength = currentContent.getPlainText("").length;
      const selectedTextLength = _getLengthOfSelectedText();

      if (currentContentLength - selectedTextLength > MAX_LENGTH - 1) {
        console.log("you can type max ten characters");

        return "handled";
      }
      return "not-handled";
    }
    return "not-handled";
  };
  const __handlePastedText = (pastedText: string) => {
    if (MAX_LENGTH) {
      const currentContent = editorState.getCurrentContent();
      const currentContentLength = currentContent.getPlainText("").length;
      const selectedTextLength = _getLengthOfSelectedText();

      if (
        currentContentLength + pastedText.length - selectedTextLength >
          MAX_LENGTH &&
        MAX_LENGTH
      ) {
        const selection = editorState.getSelection();
        const isCollapsed = selection.isCollapsed();
        const tempEditorState = !isCollapsed ? _removeSelection() : editorState;
        _addPastedContent(pastedText, tempEditorState);

        return "handled";
      }

      return "not-handled";
    }
    return "not-handled";
  };
  return (
    <div className="editor">
      <Toolbar />
      <Editor
        plugins={[toolbarPlugin]}
        editorState={editorState}
        onChange={onChange}
        handleBeforeInput={__handleBeforeInput}
        handlePastedText={__handlePastedText}
        placeholder={placeholder}
      />
    </div>
  );
};

export default EditorField;
