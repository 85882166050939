import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";

import {
  Box,
  Chip as MuiChip,
  Drawer,
  Fab as MuiFab,
  FormControl as MuiFormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  Tooltip,
  Typography,
} from "@material-ui/core";

import { Alert } from "@material-ui/lab";

import { Timer, Check } from "@material-ui/icons";
import { spacing, SpacingProps } from "@material-ui/system";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../redux/reducers";
import { TopicType } from "../../types/topics";
import StopWatch from "./StopWatch";

import {
  resetTime,
  setIsOpen,
  setMode,
  setSubjectId,
  setTopicId,
} from "../../redux/actions/timeStudyActions";
import LinksList from "./LinksList";
import { Time } from "../../vendor/react-timer-hook/utils";
import TimerWatch from "./TimerWatch";
import topicsHttp from "../../utils/http/topics-http";

const Chip = styled(MuiChip)(spacing);

const Fab = styled(MuiFab)`
  position: fixed;
  width: 65px;
  height: 65px;
  right: ${(props) => props.theme.spacing(8)}px;
  bottom: ${(props) => props.theme.spacing(8)}px;
  z-index: 1;
`;

const Wrapper = styled.div`
  width: 350px;
  overflow-x: hidden;
`;

const Heading = styled(Box)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding: 20px;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }
`;
const FormControlSpacing = styled(MuiFormControl)(spacing);

const FormControl = styled(FormControlSpacing)<{ m?: number }>`
  min-width: 148px;
`;

interface SelectMuiType extends SpacingProps {
  mt?: number;
}

const Select = styled(MuiSelect)<SelectMuiType>(spacing);

function TimeStudy() {
  const dispacth = useDispatch();
  const [topics, setTopics] = useState<TopicType[]>([]);
  const [subjectName, setSubjectName] = useState<string | null>(null);
  const [topicName, setTopicName] = useState<string | null>(null);
  const [loadingTopics, setLoadingTopics] = useState<boolean>(false);
  const plan = useSelector((state: AppStateType) => state.planReducer);
  const timeStudy = useSelector((state: any) => state.timeStudyReducer);

  const active =
    timeStudy.time.seconds > 0 || timeStudy.timer.secondsPassed > 0;

  const { hours, minutes, seconds } = Time.getTimeFromSeconds(
    timeStudy.time.seconds > 0
      ? timeStudy.time.seconds
      : timeStudy.timer.seconds
  );

  useEffect(() => {
    async function handleSetOptionsTopics() {
      // const subject: SubjectType | undefined = plan.active?.subjects?.find(
      //   (item) => item.id === timeStudy.subjectId
      // );
      setLoadingTopics(true);
      try {
        const { data } = await topicsHttp.listRelationshipResource(
          timeStudy.subjectId,
          "subject"
        );
        setTopics(data.data || []);
      } catch (e) {
        console.log(e);
      } finally {
        setLoadingTopics(false);
      }
      //setTopics(subject?.topics || []);
    }

    handleSetOptionsTopics();
  }, [timeStudy.subjectId, plan.active?.subjects]);

  useEffect(() => {
    if (!timeStudy.subjectId || !timeStudy.topicId) {
      return;
    }
    const subject = plan.active?.subjects?.find(
      (item) => item.id === timeStudy.subjectId
    );
    if (subject !== undefined) {
      setSubjectName(subject.title);
      const topic = subject.topics.find(
        (item) => item.id === timeStudy.topicId
      );
      if (topic !== undefined) {
        setTopicName(topic.title);
      }
    }
  }, [timeStudy.subjectId, timeStudy.topicId, plan.active?.subjects]);

  const toggleDrawer = (open: boolean) => () => {
    dispacth(setIsOpen(open));
    if (!open) {
      resetIsReview();
    }
  };

  const handleMode = (value: string) => {
    dispacth(setMode(value));
  };

  const resetIsReview = () => {
    if (timeStudy.typeStudy.length > 0 && timeStudy.time.seconds === 0) {
      dispacth(resetTime());
    }
  };

  return (
    <React.Fragment>
      <Tooltip title={"Iniciar Estudo"}>
        <Fab
          color="primary"
          aria-label="Edit"
          onClick={toggleDrawer(true)}
          id={"activity-time"}
        >
          {!active ? <Timer /> : `${hours}:${minutes}:${seconds}`}
        </Fab>
      </Tooltip>
      <Drawer
        anchor="right"
        open={timeStudy.isOpen}
        onClose={toggleDrawer(false)}
      >
        <Wrapper>
          {active ? (
            <Box px={4} my={3}>
              <Typography variant="h3">{subjectName}</Typography>
              <Typography variant="h4">{topicName}</Typography>
            </Box>
          ) : (
            <>
              <Heading>Atividade de Estudo</Heading>
              <Box px={4} my={3}>
                <Chip
                  label="Cronômetro"
                  clickable
                  color={timeStudy.mode === "watch" ? "primary" : "default"}
                  size={"small"}
                  m={1}
                  onClick={() => handleMode("watch")}
                />
                <Chip
                  label="Timer"
                  clickable
                  color={timeStudy.mode === "time" ? "primary" : "default"}
                  size={"small"}
                  onClick={() => handleMode("time")}
                  m={1}
                />
              </Box>

              {!timeStudy.topicId && (
                <Box px={4} my={3}>
                  <Alert icon={false} severity="info">
                    <strong>Olá!</strong> Selecione a matéria e o assunto para
                    iniciar seu estudo!
                  </Alert>
                </Box>
              )}
              <Box px={4} my={3}>
                <Grid container spacing={3}>
                  <FormControl variant="outlined" m={2} fullWidth>
                    <InputLabel id="materia-label-id">Matéria</InputLabel>
                    <Select
                      labelId="materia-label-id"
                      id="materia-id"
                      value={timeStudy.subjectId || ""}
                      onChange={(e) => {
                        dispacth(setSubjectId(e.target.value));
                        dispacth(setTopicId(null));
                      }}
                      label="Matéria"
                    >
                      <MenuItem value="">
                        <em>Selecione</em>
                      </MenuItem>
                      {plan?.active?.subjects?.map((item) => (
                        <MenuItem value={item.id} key={item.id}>
                          {item.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Box>
              <Box px={4} my={3}>
                <Grid container spacing={3}>
                  <FormControl variant="outlined" m={2} fullWidth>
                    <InputLabel id="assunto-label-id">Assunto</InputLabel>
                    <Select
                      labelId="assunto-label-id"
                      id="assunto-id"
                      value={timeStudy.topicId || ""}
                      onChange={(e) => dispacth(setTopicId(e.target.value))}
                      label="Assunto"
                      disabled={loadingTopics}
                    >
                      <MenuItem value="">
                        <em>Selecione</em>
                      </MenuItem>
                      {topics.map((item) => {
                        const icone = item.completed ? (
                          <Check fontSize="small" color={"primary"} />
                        ) : (
                          ""
                        );
                        return (
                          <MenuItem value={item.id} key={item.id}>
                            {icone}
                            {item.title}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Box>
            </>
          )}
          {timeStudy.mode === "watch" ? <StopWatch /> : <TimerWatch />}

          {active && (
            <LinksList
              topicId={timeStudy.topicId}
              subjectId={timeStudy.subjectId}
            />
          )}
        </Wrapper>
      </Drawer>
    </React.Fragment>
  );
}

export default TimeStudy;
