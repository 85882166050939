import React, { FC, useContext, useEffect } from "react";
import styled from "styled-components/macro";
import {
  Card as MuiCard,
  CardContent,
  FormControl as MuiFormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  Switch,
  TextField as MuiTextField,
  Typography as MuiTypography,
} from "@material-ui/core";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { spacing, SpacingProps } from "@material-ui/system";
import DateField from "../../../../../components/example/DateField";
import ButtonIcon from "../../../../../components/ButtonIcon";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "../../../../../vendor/yup";
import { useParams } from "react-router";
import plansHttp from "../../../../../utils/http/plans-http";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import SubmitAction from "../../../../../components/SubmitAction";
import LoadingContext from "../../../../../components/Loading/LoadingContext";
import { AreaType, PlanFormType, PlanType } from "../../../../../types/plans";
import { useDispatch } from "react-redux";
import {
  list as planList,
  setPlanActive,
} from "../../../../../redux/actions/planActions";

interface TypographyPropsType extends SpacingProps {
  component?: string;
}

interface SelectMuiType extends SpacingProps {
  mt?: number;
}

const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);

const TextFieldSpacing = styled(MuiTextField)(spacing);

const TextField = styled(TextFieldSpacing)<{ m?: number }>``;
const Typography = styled(MuiTypography)<TypographyPropsType>(spacing);

const Centered = styled.div`
  text-align: center;
`;

const FormControlSpacing = styled(MuiFormControl)(spacing);

const FormControl = styled(FormControlSpacing)<{ m?: number }>``;

const Select = styled(MuiSelect)<SelectMuiType>(spacing);

const SchemaValidation = yup.object({
  plan: yup.string().label("Plano").required().max(50),
  area_id: yup.number().label("Área").required().min(1, "Requirido"),
  cespe: yup.boolean(),
});

interface FormProps {
  plan?: PlanType | null;
  areas: AreaType[];
}

const Form: FC<FormProps> = ({ plan, areas }) => {
  const dispatch = useDispatch();
  const snackbar = useSnackbar();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const loading = useContext(LoadingContext);

  //const [isDate, setIsDate] = useState<boolean>(false);
  const {
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useForm<PlanFormType>({
    resolver: yupResolver(SchemaValidation),
    defaultValues: {
      plan: "",
      area_id: "",
      cespe: false,
      date_exam: new Date(),
    },
  });

  useEffect(() => {
    if (!plan) {
      return;
    }
    reset({ ...plan, cespe: !!plan.cespe, area_id: plan.area?.id });
  }, [plan, reset]);

  const onSubmit: SubmitHandler<PlanType> = async (formData, event) => {
    try {
      const http = !plan
        ? plansHttp.create(formData)
        : plansHttp.update(plan.id, formData);
      const { data } = await http;
      dispatch(setPlanActive(data.data));
      await dispatch(planList());

      snackbar.enqueueSnackbar("Atualizado com Sucesso", {
        variant: "success",
      });
      setTimeout(() => {
        event
          ? id
            ? history.replace(`/plano/${data.data.id}/edit`)
            : history.push(`/plano/${data.data.id}/edit`)
          : history.push("/planos/meus-planos");
      });
    } catch (error) {
      console.log(error);
      snackbar.enqueueSnackbar("Não é possível salvar o plano", {
        variant: "error",
      });
    }
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Plano
        </Typography>

        <Spacer mb={2} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="plan"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                id="plano"
                label="Plano"
                placeholder="Digite seu plano de estudo"
                variant="outlined"
                m={2}
                fullWidth
                error={errors.plan !== undefined}
                helperText={errors.plan?.message}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />

          <FormControl
            variant="outlined"
            m={2}
            fullWidth
            error={errors.area_id !== undefined}
          >
            <InputLabel id="type-label">Área</InputLabel>
            <Controller
              name="area_id"
              control={control}
              render={({ field }) => (
                <>
                  <Select
                    {...field}
                    labelId="type-select-outlined-label"
                    id="type-label"
                    label="type-label"
                    error={errors.area_id !== undefined}
                    value={watch("area_id")}
                  >
                    {areas.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.area}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    <>{errors.area_id?.message}</>
                  </FormHelperText>
                </>
              )}
            />
          </FormControl>

          <Grid container>
            <Grid item xs={12}>
              <DateField
                title={"Data da prova"}
                value={watch("date_exam")}
                onChange={(value) => {
                  value !== null &&
                    setValue("date_exam", value as any, {
                      shouldValidate: true,
                    });
                }}
              />
            </Grid>
          </Grid>
          {/*<FormControl variant="outlined" m={2} fullWidth>*/}
          {/*  <FormControlLabel*/}
          {/*    control={*/}
          {/*      <Checkbox*/}
          {/*        onChange={(e) => setIsDate(e.target.checked)}*/}
          {/*        name="isData"*/}
          {/*      />*/}
          {/*    }*/}
          {/*    label="Não tem data da prova definida."*/}
          {/*  />*/}
          {/*</FormControl>*/}

          <FormControl variant="outlined" m={2} fullWidth>
            <Controller
              name="cespe"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Switch {...field} name="cespe" checked={watch("cespe")} />
                  }
                  label="Padrão Cespe"
                />
              )}
            />
          </FormControl>
          <FormHelperText style={{ paddingBottom: "15px" }}>
            Padrão Cespe - O desempenho calcula que uma errada elimina uma
            certa.
          </FormHelperText>

          <Centered>
            {plan ? (
              <SubmitAction
                disabledButtons={loading}
                handleSalve={() => {
                  trigger().then((valid) => {
                    valid && onSubmit(getValues(), undefined);
                  });
                }}
              />
            ) : (
              <ButtonIcon
                title={"Salvar"}
                titleTootip={"Salvar Plano"}
                icon={"save"}
                type={"submit"}
              />
            )}
          </Centered>
        </form>
      </CardContent>
    </Card>
  );
};

export default Form;
