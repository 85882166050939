import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import BookIcon from "@material-ui/icons/Book";
import AddIcon from "@material-ui/icons/Add";
import { blue } from "@material-ui/core/colors";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../redux/reducers";
import { useHistory } from "react-router-dom";
import { setPlanActive } from "../../../redux/actions/planActions";
import plansHttp from "../../../utils/http/plans-http";

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

export interface ListPlanProps {
  open: boolean;
  onClose: () => void;
}

function ListPlan(props: ListPlanProps) {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { onClose, open } = props;
  const plan = useSelector((state: AppStateType) => state.planReducer);

  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = async (id: string) => {
    if (plan.list.length > 0) {
      const index = plan.list.findIndex((row) => row.id === id);
      dispatch(setPlanActive(plan.list[index]));
      await plansHttp.setDefault(id);
    }

    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">Planos de Estudo</DialogTitle>
      {
        <List>
          {plan.list?.map((plan) => (
            <ListItem
              button
              onClick={() => handleListItemClick(plan.id as string)}
              key={plan.id}
            >
              <ListItemAvatar>
                <Avatar className={classes.avatar}>
                  <BookIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={plan.plan} />
            </ListItem>
          ))}
          <ListItem
            autoFocus
            button
            onClick={() => {
              handleClose();
              history.push("/planos/meus-planos");
            }}
          >
            <ListItemAvatar>
              <Avatar>
                <AddIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Plano de Estudo" />
          </ListItem>
        </List>
      }
    </Dialog>
  );
}

export default function ChangePlan() {
  const [open, setOpen] = React.useState(false);
  const plan = useSelector((state: AppStateType) => state.planReducer);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
        id={"button-change-plan"}
      >
        {plan.active.plan}
      </Button>
      <ListPlan open={open} onClose={handleClose} />
    </div>
  );
}
