import * as types from "../../constants";
import { AppDispatchType } from "../store";
import plansHttp from "../../utils/http/plans-http";
import { ResponseType } from "../../types/response";
import { PlanType } from "../../types/plans";

export function planActive() {
  return async (dispatch: AppDispatchType) => {
    dispatch({ type: types.PLAN_ACTIVE_IN_REQUEST });
    return await plansHttp
      .default<ResponseType<PlanType | null>>()
      .then(({ data }) => {
        if (data.data !== null) {
          dispatch(setPlanActive(data.data));
        } else {
          dispatch({
            type: types.PLAN_ACTIVE_IS_NULL,
          });
        }
      })
      .catch((e) => {
        dispatch({ type: types.AUTH_RESET_PASSWORD_FAILURE });
        throw e;
      });
  };
}

export function list() {
  return async (dispatch: AppDispatchType) => {
    dispatch({ type: types.PLAN_LIST });
    return await plansHttp
      .list<ResponseType<PlanType[] | null>>()
      .then(({ data }) => {
        if (data.data !== null) {
          dispatch(setPlanList(data.data));
        }
      })
      .catch((e) => {
        dispatch({ type: types.AUTH_RESET_PASSWORD_FAILURE });
        throw e;
      });
  };
}

export function setPlanList(plan: PlanType[]) {
  return {
    type: types.PLAN_LIST,
    payload: plan,
  };
}

export function setPlanActive(plan: PlanType) {
  return {
    type: types.PLAN_ACTIVE_IN_SUCCESS,
    id: plan?.id,
    plan: plan?.plan,
    cespe: plan?.cespe,
    subjects: plan?.subjects,
    area: plan?.area,
    date_exam: plan.date_exam,
  };
}
