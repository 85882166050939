import * as types from "../../constants";
import { PlanType } from "../../types/plans";

export interface PlanReducerType {
  active: PlanType;
  list: PlanType[];
}

const INITIAL: PlanReducerType = {
  active: {
    id: "",
    plan: "",
    area: { id: "", area: "", color: "" },
    cespe: false,
    subjects: [],
  },
  list: [],
};
export default function reducer(
  state = INITIAL,
  actions: PlanType & { type: string } & { payload: PlanType[] }
): PlanReducerType {
  switch (actions.type) {
    case types.PLAN_ACTIVE_IN_SUCCESS:
      return {
        ...state,
        active: {
          id: actions.id,
          plan: actions.plan,
          area: actions.area,
          cespe: actions.cespe,
          date_exam: actions.date_exam,
          order: actions.order,
          subjects: actions.subjects,
        },
      };
    case types.PLAN_LIST:
      return {
        ...state,
        list: actions.payload,
      };
    case types.PLAN_ACTIVE_IS_NULL:
      return INITIAL;
    case types.AUTH_SIGN_OUT:
      return INITIAL;
    default:
      return state;
  }
}
