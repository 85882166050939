import React, { FC, useState } from "react";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import MuiCardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components/macro";
import Loader from "../../../components/Loader";

interface CardPlanType {
  title: string;
  content: React.ReactNode;
  price: string;
  discount?: string;
}

const Price = styled.div`
  margin-top: ${(props) => props.theme.spacing(3)}px;
`;

const CardContent = styled(MuiCardContent)`
  min-height: 169px;
`;

const CardPlan: FC<CardPlanType> = ({ title, content, price, discount }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleCheckout = async () => {
    setLoading(true);
    window.location.href = process.env.REACT_APP_LINK_PAYMENT as string;
  };

  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {title}
        </Typography>
        {content}
        <Price>
          <Typography
            variant="subtitle2"
            color="textPrimary"
            align="center"
            display="inline"
          >
            12x
          </Typography>
          <Typography
            component="h2"
            variant="h1"
            color="textPrimary"
            align="center"
            display="inline"
          >
            {price}
          </Typography>
        </Price>
        {discount && (
          <Typography variant="caption" color="textSecondary">
            Equivalente a {discount}
          </Typography>
        )}
      </CardContent>
      <CardActions>
        <Button
          size="small"
          color="primary"
          onClick={handleCheckout}
          disabled={loading}
        >
          {loading ? <Loader size={20} /> : "Assinar"}
        </Button>
      </CardActions>
    </Card>
  );
};

export default CardPlan;
