// Theme
export const THEME_SET = "THEME_SET";
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};

// Auth
export const AUTH_SIGN_IN_REQUEST = "AUTH_SIGN_IN_REQUEST";
export const AUTH_SIGN_IN_SUCCESS = "AUTH_SIGN_IN_SUCCESS";
export const AUTH_SIGN_IN_FAILURE = "AUTH_SIGN_IN_FAILURE";
export const AUTH_SIGN_OUT = "AUTH_SIGN_OUT";
export const AUTH_CHANGE_ACTIVE = "AUTH_CHANGE_ACTIVE";
export const AUTH_SIGN_UP_REQUEST = "AUTH_SIGN_UP_REQUEST";
export const AUTH_SIGN_UP_SUCCESS = "AUTH_SIGN_UP_SUCCESS";
export const AUTH_SIGN_UP_FAILURE = "AUTH_SIGN_UP_FAILURE";
export const AUTH_RESET_PASSWORD_REQUEST = "AUTH_RESET_PASSWORD_REQUEST";
export const AUTH_RESET_PASSWORD_SUCCESS = "AUTH_RESET_PASSWORD_SUCCESS";
export const AUTH_RESET_PASSWORD_FAILURE = "AUTH_RESET_PASSWORD_FAILURE";

//PlanActive
export const PLAN_ACTIVE_IN_REQUEST = "PLAN_ACTIVE_IN_REQUEST";
export const PLAN_ACTIVE_IN_SUCCESS = "PLAN_ACTIVE_IN_SUCCESS";
export const PLAN_ACTIVE_IN_FAILURE = "PLAN_ACTIVE_IN_FAILURE";
export const PLAN_LIST = "PLAN_LIST";
export const PLAN_ACTIVE_IS_NULL = "PLAN_ACTIVE_IS_NULL";

//TimeStudy
export const TIME_STUDY_IS_OPEN = "TIME_STUDY_IS_OPEN";
export const TIME_STUDY_MODE = "TIME_STUDY_MODE";
export const TIME_STUDY_SUBJECT_ID = "TIME_STUDY_SUBJECT_ID";
export const TIME_STUDY_TOPIC_ID = "TIME_STUDY_TOPIC_ID";
export const TIME_STUDY_IS_RUNNING = "TIME_STUDY_IS_RUNNING";
export const TIME_STUDY_TIME = "TIME_STUDY_TIME";
export const TIME_STUDY_TIMER = "TIME_STUDY_TIMER";
export const TIME_STUDY_INTERVAL_ID = "TIME_STUDY_INTERVAL_ID";
export const TIME_STUDY_TYPE_STUDY = "TIME_STUDY_TYPE_STUDY";
export const TIME_STUDY_RESET = "TIME_STUDY_RESET";
export const TIME_STUDY_SECONDS_INITIAL = "TIME_STUDY_SECONDS_INITIAL";

//TimeAssessment
export const TIME_ASSESSMENT_IS_OPEN = "TIME_ASSESSMENT_IS_OPEN";
export const TIME_ASSESSMENT_IS_RUNNING = "TIME_ASSESSMENT_IS_RUNNING";
export const TIME_ASSESSMENT_TIME = "TIME_ASSESSMENT_TIME";
export const TIME_ASSESSMENT_INTERVAL_ID = "TIME_ASSESSMENT_INTERVAL_ID";
export const TIME_ASSESSMENT_RESET = "TIME_ASSESSMENT_RESET";

//Area
export const AREA_LIST = "AREA_LIST";

//Settings
export const SETTINGS_TIMER = "SETTINGS_TIMER";
