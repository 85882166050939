import { combineReducers } from "redux";

import themeReducer from "./themeReducer";
import authReducer from "./authReducer";
import planReducer from "./planReducer";
import timeStudyReducer from "./timeStudyReducer";
import timeAssessmentReducer from "./timeAssessmentReducer";
import settingsReducer from "./settingsReducer";

export const rootReducer = combineReducers({
  themeReducer,
  authReducer,
  planReducer,
  timeStudyReducer,
  timeAssessmentReducer,
  settingsReducer,
});

type RootReducerType = typeof rootReducer;
export type AppStateType = ReturnType<RootReducerType>;
