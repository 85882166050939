import React, { useCallback, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl as MuiFormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@material-ui/core";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { useSelector } from "react-redux";

import cyclesHttp from "../../../utils/http/cycles-http";
import { useSnackbar } from "notistack";
import Loader from "../../../components/Loader";
import { AppStateType } from "../../../redux/reducers";

const FormControlSpacing = styled(MuiFormControl)(spacing);

const FormControl = styled(FormControlSpacing)<{ m?: number }>`
  width: 100%;
`;

interface SuggestCycleType {
  open: boolean;
  setOpen: (value: boolean) => void;
  setItems: (value: any) => void;
}

const SuggestCycle = ({ open, setOpen, setItems }: SuggestCycleType) => {
  const snackbar = useSnackbar();
  const [type, setType] = useState<string>("progress");
  const [qtdMateria, setQtdMateria] = useState<string | unknown>("1");
  const [loading, setLoading] = useState<boolean>(false);

  const plan = useSelector((state: AppStateType) => state.planReducer);

  const getCycle = useCallback(async () => {
    setLoading(true);
    if (plan.active.id) {
      try {
        const data = await cyclesHttp.listRelationshipResource(
          plan?.active?.id,
          "suggest",
          {
            queryParam: {
              type,
              qtd_materia: qtdMateria,
            },
          }
        );
        if (data.status === 200) {
          setItems(data.data);
          setOpen(false);
        }
      } catch (error) {
        console.error(error);
        snackbar.enqueueSnackbar("Não foi possível carregar as informações", {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    }
  }, [plan.active, snackbar, type, qtdMateria, setItems, setOpen]);

  const qtdMaterias = () => {
    let rows: any = [];
    const qtd = plan.active.subjects?.length || 1;
    for (let i = 1; i <= qtd; i++) {
      rows.push(
        <MenuItem value={i} key={i}>
          {i}
        </MenuItem>
      );
    }
    return rows;
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Sugerir ciclo</DialogTitle>
      <DialogContent>
        {loading ? (
          <Loader />
        ) : (
          <>
            <DialogContentText>
              Com base nas suas atividades cadastradas, o sistema irá sugerir um
              ciclo de estudo para você estudar, por isso é importante cadastrar
              todas suas atividades de estudo corretamente.
            </DialogContentText>
            <FormControl>
              <FormLabel
                component="legend"
                style={{ marginTop: "10px", marginBottom: "10px" }}
              >
                Montar o ciclo com base em :
              </FormLabel>

              <RadioGroup
                aria-label="position"
                name="position"
                value={type}
                row
                onChange={(e) => setType(e.target.value)}
              >
                <FormControlLabel
                  value="progress"
                  control={<Radio color="primary" />}
                  label="Progresso"
                  labelPlacement="start"
                />
                <FormControlLabel
                  value="questions"
                  control={<Radio color="primary" />}
                  label="Questões"
                  labelPlacement="start"
                />
                <FormControlLabel
                  value="duration"
                  control={<Radio color="primary" />}
                  label="Tempo de Estudo"
                  labelPlacement="start"
                />
              </RadioGroup>
            </FormControl>
            <FormControl variant="outlined" fullWidth>
              <FormLabel
                component="legend"
                style={{ marginTop: "10px", marginBottom: "10px" }}
              >
                Quantidade de matérias :
              </FormLabel>
              <Select
                id="materia-id"
                inputProps={{ "aria-label": "Without label" }}
                onChange={(e) => setQtdMateria(e.target.value)}
                value={qtdMateria}
              >
                {qtdMaterias()}
              </Select>
            </FormControl>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          Cancelar
        </Button>
        <Button onClick={() => getCycle()} color="primary">
          Gerar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SuggestCycle;
