import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Dialog,
  DialogContent,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import PlanoList from "./PlanoList";
import ButtonIcon from "../../../components/ButtonIcon";
import { NavLink } from "react-router-dom";
import PlanoFormCreateDialog from "./PlanoFormCreateDialog";
import ListPlans from "../prontos/Components/ListPlans";
import plansHttp from "../../../utils/http/plans-http";
import { ResponseType } from "../../../types/response";
import { PlanType } from "../../../types/plans";
import { setPlanList } from "../../../redux/actions/planActions";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function Meus() {
  const dispatch = useDispatch();
  const snackbar = useSnackbar();
  const [plans, setPlans] = useState<PlanType[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [openPlanPronto, setPlanPronto] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleClosePlanPronto = () => {
    setPlanPronto(false);
  };

  const getPlans = useCallback(async () => {
    try {
      const { data } = await plansHttp.list<ResponseType<PlanType[]>>();
      setPlans(data.data);
      dispatch(setPlanList(data.data));
    } catch (error) {
      console.error(error);
      snackbar.enqueueSnackbar("Não foi possível carregar as informações", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [dispatch, snackbar]);

  useEffect(() => {
    setLoading(true);
    let isSubscribed = true;
    if (isSubscribed) {
      getPlans();
    }
    return () => {
      isSubscribed = false;
    };
  }, [getPlans]);

  const handleCallbackCopy = () => {
    getPlans();
    setPlanPronto(false);
  };

  return (
    <React.Fragment>
      <Helmet title="Meus Planos" />

      <Grid justify="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Meus Planos
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/">
              Plano de Estudo
            </Link>
            <Typography>Meus Planos</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <ButtonIcon
              title={"Planos Prontos"}
              titleTootip={"Lista de Editais Prontos"}
              onClick={() => setPlanPronto(true)}
            />
            <ButtonIcon
              title={"Criar meu plano"}
              titleTootip={"Novo Plano de Estudo"}
              icon={"add"}
              onClick={() => setOpen(true)}
            />
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <PlanoList getPlans={getPlans} plans={plans} loading={loading} />
        </Grid>
      </Grid>
      <PlanoFormCreateDialog open={open} setOpen={setOpen} />
      <Dialog
        open={openPlanPronto}
        fullWidth={true}
        maxWidth={"sm"}
        onClose={handleClosePlanPronto}
      >
        <DialogContent>
          <ListPlans onCallbackCopy={handleCallbackCopy} />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default Meus;
