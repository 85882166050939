export default class Time {
  static getTimeFromSeconds(secs: number) {
    const totalSeconds = Math.ceil(secs);

    const hours = Math.floor((totalSeconds % (60 * 60 * 24)) / (60 * 60));
    const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
    const seconds = Math.floor(totalSeconds % 60);
    return {
      seconds: seconds.toString().padStart(2, "0"),
      minutes: minutes.toString().padStart(2, "0"),
      hours: hours.toString().padStart(2, "0"),
    };
  }

  static getSecondsFromExpiry(expiry: any, shouldRound: boolean) {
    const now = new Date().getTime();

    const milliSecondsDistance = expiry - now;
    if (milliSecondsDistance > 0) {
      const val = milliSecondsDistance / 1000;
      return shouldRound ? Math.round(val) : val;
    }
    return 0;
  }

  static getSecondsFromPrevTime(prevTime: number, shouldRound: boolean) {
    const now = Date.now();
    const milliSecondsDistance = now - prevTime;
    if (milliSecondsDistance > 0) {
      const val = milliSecondsDistance / 1000;
      return shouldRound ? Math.round(val) : val;
    }
    return 0;
  }

  static getSecondsFromTimeNow() {
    const now = new Date();
    const currentTimestamp = now.getTime();
    const offset = now.getTimezoneOffset() * 60;
    return currentTimestamp / 1000 - offset;
  }
}
