import React, { FC } from "react";
import styled from "styled-components/macro";
import "react-confirm-alert/src/react-confirm-alert.css";
import { NavLink } from "react-router-dom";
import {
  CardContent,
  Card as MuiCard,
  TableContainer,
  Table as MuiTable,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { useFilters, useGlobalFilter, useSortBy, useTable } from "react-table";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
} from "@material-ui/icons";

import { format } from "date-fns";
import { secToTime } from "../../../../utils/Times";
import { AssessmentType } from "../../../../types/assessments";

const Card = styled(MuiCard)(spacing);

interface TableProps {
  assessments: AssessmentType[];
  onDelete: (id: string) => void;
}

const Table: FC<TableProps> = (props) => {
  const { assessments, onDelete } = props;
  const data = React.useMemo(() => assessments, [assessments]);

  const columns: any = React.useMemo(
    () => [
      {
        Header: "Data",
        accessor: "start",
        Cell: ({ value }: any) => {
          return format(new Date(value), "dd/MM/yy");
        },
      },
      {
        Header: "Simulado",
        accessor: "title",
      },
      {
        Header: "Tempo",
        Cell: ({ row }: any) => {
          return secToTime(row.original.duration);
        },
      },
      {
        Header: "Anotação",
        accessor: "notes",
      },

      {
        Header: "Questões",
        accessor: "total",
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      manualSortBy: true,
      autoResetSortBy: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );

  const { headerGroups, rows, prepareRow } = tableInstance;

  return (
    <Card mb={6}>
      <CardContent>
        <TableContainer>
          <MuiTable
            aria-labelledby="historicos"
            size={"medium"}
            aria-label="historico"
          >
            <TableHead>
              {headerGroups.map((headerGroup, i) => (
                <TableRow {...headerGroup.getHeaderGroupProps()} key={i}>
                  {headerGroup.headers.map((column: any) => (
                    <TableCell {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </TableCell>
                  ))}
                  <TableCell colSpan={3} align={"center"}>
                    Ação
                  </TableCell>
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {rows.map((row, index) => {
                prepareRow(row);

                return (
                  <TableRow {...row.getRowProps()} hover key={index}>
                    {row.cells.map((cell: any, iCell) => (
                      <TableCell {...cell.getCellProps()} key={iCell}>
                        {cell.render("Cell")}
                      </TableCell>
                    ))}
                    <TableCell>
                      <IconButton
                        aria-label="Exibir"
                        component={NavLink}
                        to={`/assessment/${row.original.id}/show`}
                        size={"small"}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="edit"
                        component={NavLink}
                        to={`/assessment/${row.original.id}/edit`}
                        size={"small"}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="delete"
                        onClick={() => onDelete(row.original.id)}
                        size={"small"}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </MuiTable>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default Table;
