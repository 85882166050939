import React, { useState, FC, useEffect, useCallback } from "react";
import styled from "styled-components/macro";

import {
  List,
  Card as MuiCard,
  CardContent as MuiCardContent,
  ListItem as MuiListItem,
  ListItemText as MuiListItemText,
  IconButton,
  ListItemSecondaryAction,
  Button as MuiButton,
  Tooltip,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { spacing } from "@material-ui/system";
import LinkCreate from "./LinkCreate";
import linksHttp from "../../utils/http/links-http";
import { useSnackbar } from "notistack";
import { ListItemProps as MuiListItemProps } from "@material-ui/core/ListItem/ListItem";
import Caderno from "./Caderno";

const Button = styled(MuiButton)(spacing);

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const ListItemText = styled(MuiListItemText)(spacing);

interface LinksListProps {
  topicId: string;
  subjectId: string;
}
interface ListItemProps extends MuiListItemProps {
  component?: string;
  href?: string;
  button: boolean | undefined;
  target?: string;
}
const ListItem = styled(MuiListItem)<ListItemProps>``;

const LinksList: FC<LinksListProps> = ({ topicId, subjectId }) => {
  const snackbar = useSnackbar();
  const [open, setOpen] = useState<boolean>(false);
  const [cadernoOpen, setCadernoOpen] = useState<boolean>(false);
  const [links, setLinks] = useState<any[]>([]);

  const getLinks = useCallback(async () => {
    if (!topicId) {
      return;
    }
    try {
      const { data } = await linksHttp.listRelationship(topicId);
      setLinks(data.data);
    } catch (error) {
      console.error(error);
      snackbar.enqueueSnackbar("Não foi possível carregar as informações!", {
        variant: "error",
      });
    }
  }, [snackbar, topicId]);

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getLinks();
    }
    return () => {
      isSubscribed = false;
    };
  }, [topicId, getLinks]);

  const handleDelete = (id: any) => {
    linksHttp
      .delete(id)
      .then(() => {
        getLinks();
        snackbar.enqueueSnackbar("Excluído com sucesso", {
          variant: "success",
        });
      })
      .catch((e) => {
        console.log(e);
        snackbar.enqueueSnackbar("Não foi possível carregar as informações!", {
          variant: "error",
        });
      });
  };

  return (
    <>
      <Caderno
        subjectId={subjectId}
        open={cadernoOpen}
        setOpen={setCadernoOpen}
      />
      <LinkCreate
        open={open}
        setOpen={setOpen}
        topicId={topicId}
        onGetLink={getLinks}
      />

      <Card mb={6}>
        <CardContent>
          <Button mr={2} color={"primary"} onClick={() => setOpen(true)}>
            Link de Estudo
          </Button>
          <Button mr={2} onClick={() => setCadernoOpen(true)}>
            Caderno
          </Button>
        </CardContent>

        <List component="nav">
          {links.map((row) => (
            <Tooltip title={row.url}>
              <ListItem
                button
                key={row.id}
                component={"a"}
                href={row.url}
                target={"_blank"}
              >
                <ListItemText primary={row.name} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleDelete(row.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </Tooltip>
          ))}
        </List>
      </Card>
    </>
  );
};

export default LinksList;
