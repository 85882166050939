import React from "react";
import { withTheme, ThemeProps } from "styled-components/macro";

import { Theme } from "@material-ui/core";
import {
  Chart as ChartJS,
  BarElement,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(BarElement, Tooltip, Legend);
interface BarChartProps extends ThemeProps<Theme> {
  labels: string[];
  data: any[];
  options?: ChartOptions<"bar">;
}

const BarChart = ({ labels, data: dataset, theme, options }: BarChartProps) => {
  const data = {
    labels: labels,
    datasets: [
      {
        backgroundColor: theme.palette.secondary.main,
        borderColor: theme.palette.secondary.main,
        hoverBackgroundColor: theme.palette.secondary.main,
        hoverBorderColor: theme.palette.secondary.main,
        data: dataset,
        barPercentage: 0.75,
        categoryPercentage: 0.5,
      },
    ],
  };
  const optionsChart = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    ...options,
  };

  return <Bar data={data} options={optionsChart} />;
};

export default withTheme(BarChart);
