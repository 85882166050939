import React, { FC } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@material-ui/core";

interface AlertTimeExpirationType {
  open: boolean;
  onSave: () => void;
}

const AlertTimeExpiration: FC<AlertTimeExpirationType> = ({ open, onSave }) => {
  return (
    <Dialog open={open}>
      <DialogTitle>Cronômetro</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Parabéns! Mais um estudo concluído. Agora salve sua atividade de
          estudo!
        </DialogContentText>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}></Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onSave}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertTimeExpiration;
