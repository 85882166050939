import React, { FC } from "react";
import styled from "styled-components/macro";
import {
  CardContent,
  Card as MuiCard,
  TableContainer,
  Table as MuiTable,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CardHeader,
  TableSortLabel,
} from "@material-ui/core";
import { NavLink } from "react-router-dom";

import { spacing } from "@material-ui/system";

import { useFilters, useGlobalFilter, useSortBy, useTable } from "react-table";

import { SubjectListStaticsType } from "../../../../types/subjects";
import { TopicListStaticsType } from "../../../../types/topics";
import { secToTime } from "../../../../utils/Times";
import LinearProgressWithLabel from "../../../../components/Material/LinearWithLabel";

const Card = styled(MuiCard)(spacing);

interface TableProps {
  list: SubjectListStaticsType[] | TopicListStaticsType[];
}

const MateriasTable: FC<TableProps> = (props) => {
  const { list } = props;

  const data = React.useMemo(() => list, [list]);

  const columns: any = React.useMemo(
    () => [
      {
        Header: "Matéria",
        accessor: "title",
        Cell: ({ row }: any) => {
          return (
            <NavLink to={`/materia/${row.original.id}`}>
              {row.original.title}{" "}
            </NavLink>
          );
        },
      },
      {
        Header: "Tópicos",
        accessor: "topics",
        disableSortBy: true,
        Cell: ({ value }: any) => {
          return value.length;
        },
      },
      {
        Header: "Tempo",
        accessor: "duration",
        Cell: ({ row }: any) => {
          return `${secToTime(row.original.duration)}`;
        },
      },
      {
        Header: "Acertos",
        accessor: "hits",
      },
      {
        Header: "Erros",
        accessor: "erros",

        Cell: ({ row }: any) => {
          return `${row.original.total - row.original.hits}`;
        },
      },
      {
        Header: "Total",
        accessor: "total",
      },

      {
        Header: "Desempenho",
        accessor: "desempenho",

        Cell: ({ row }: any) => {
          return `${
            row.original.desempenho ? parseInt(row.original.desempenho) : 0
          }%`;
        },
      },
      {
        Header: "Progresso",
        accessor: "progress",
        disableSortBy: true,

        Cell: ({ row }: any) => {
          let progress = 0;
          let completeds = 0;
          const topics = row.original.topics;
          if (topics.length > 0) {
            topics.forEach(function (value: any) {
              if (value.completed) {
                completeds++;
              }
            });
            progress = Math.floor((completeds / topics.length) * 100);
          }

          return <LinearProgressWithLabel value={progress} />;
        },
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      autoResetSortBy: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );

  const { headerGroups, rows, prepareRow } = tableInstance;

  return (
    <Card mb={6}>
      <CardHeader title="Atividades" />
      <CardContent>
        <TableContainer>
          <MuiTable
            aria-labelledby="historicos"
            size={"medium"}
            aria-label="historico"
          >
            <TableHead>
              {headerGroups.map((headerGroup, index) => (
                <TableRow {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column: any, i) => (
                    <TableCell
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      key={i}
                    >
                      <TableSortLabel
                        direction={
                          column.isSorted
                            ? column.isSortedDesc
                              ? "desc"
                              : "asc"
                            : undefined
                        }
                      >
                        {column.render("Header")}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <TableRow {...row.getRowProps()} hover key={i}>
                    {row.cells.map((cell: any, index) => (
                      <TableCell {...cell.getCellProps()} key={index}>
                        {cell.render("Cell")}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
              {list.length === 0 && (
                <TableRow>
                  <TableCell colSpan={7}>
                    Nenhuma atividade cadastrada.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </MuiTable>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default MateriasTable;
