import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components/macro";
import "react-dragula/dist/dragula.css";
import "./style.css";
import { v4 as uuidv4 } from "uuid";
import { Helmet } from "react-helmet-async";
import update from "immutability-helper";
import ButtonIcon from "../../components/ButtonIcon";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  IconButton as MuiIconButton,
  InputLabel,
  Link,
  List,
  ListItem as MuiListItem,
  ListItemText as MuiListItemText,
  ListItemTypeMap,
  MenuItem,
  Select,
  TextField as MuiTextField,
  Typography as MuiTypography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  TouchSensor,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Delete as DeleteIcon } from "@material-ui/icons";
import SortableItem from "../../components/DndKit/SortableItem";
import InputMask from "react-input-mask";
import { useSelector } from "react-redux";
import { AppStateType } from "../../redux/reducers";
import { secToTime, timeToSec } from "../../utils/Times";
import * as yup from "../../vendor/yup";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import Lane from "../../components/Lane";
import { NavLink, useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import cyclesHttp from "../../utils/http/cycles-http";
import SuggestCycle from "./Components/SuggestCycle";

const ListItemNotButton: OverridableComponent<
  ListItemTypeMap<{ button?: false }, "li">
> = styled(MuiListItem)(spacing);

const ListItemText = styled(MuiListItemText)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);

const IconButton = styled(MuiIconButton)(spacing);

const FormControl = styled(FormControlSpacing)<{ m?: number }>`
  min-width: 148px;
`;

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)<{ pb?: number }>`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;

const Divider = styled(MuiDivider)(spacing);

const TextFieldSpacing = styled(MuiTextField)(spacing);

const TextField = styled(TextFieldSpacing)<{ m?: number }>`
  width: 65px;
`;

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const TaskWrapper = styled(Card)`
  border: 1px solid ${(props) => props.theme.palette.grey[300]};
  margin-bottom: ${(props) => props.theme.spacing(4)}px;
  cursor: grab;

  &:hover {
    background: ${(props) => props.theme.palette.background.default};
  }
`;

const TaskWrapperContent = styled(CardContent)`
  position: relative;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;

const TaskNotifications = styled.div`
  display: flex;
  position: absolute;
  bottom: ${(props) => props.theme.spacing(1)}px;
  right: ${(props) => props.theme.spacing(4)}px;
`;
const Typography = styled(MuiTypography)(spacing);

const TaskTitle = styled(Typography)`
  font-weight: 600;
  font-size: 15px;
  margin-right: ${(props) => props.theme.spacing(10)}px;
`;

type ItemsType = {
  id: string;
  subject_id: string;
  subject_label?: string;
  seconds: number;
};

type countHoursType = {
  subject: string;
  total: number;
};

type ItemPropsType = {
  item: ItemsType;
  onDelete: (id: string) => void;
  onChangeHour: (id: string, hour: string) => void;
};
const Item: React.FC<ItemPropsType> = ({ item, onDelete, onChangeHour }) => {
  return (
    <TaskWrapper mb={4}>
      <TaskWrapperContent>
        <TaskTitle variant="body1" gutterBottom>
          {item.subject_label}
        </TaskTitle>
        <TaskNotifications>
          <InputMask
            mask={"99:99"}
            defaultValue={secToTime(item.seconds, 5)}
            onChange={(e) => onChangeHour(item.id, e.target.value)}
          >
            {(inputProps: any) => (
              <TextField
                {...inputProps}
                variant={"outlined"}
                id={"horas"}
                margin={"dense"}
              />
            )}
          </InputMask>
          <IconButton
            mx={2}
            aria-label="Delete"
            onClick={() => onDelete(item.id)}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </TaskNotifications>
      </TaskWrapperContent>
    </TaskWrapper>
  );
};

const ValidationTime = yup.object({
  time: yup.string().transform((value, originalValue) => {
    let hour = parseInt(originalValue.substr("0", "2"), 10);
    let minute = parseInt(originalValue.substr("3", "2"), 10);

    hour = Number.isNaN(hour) ? 1 : hour;
    minute = Number.isNaN(minute) ? 0 : minute;

    if (minute > 59) {
      minute = 0;
      hour += 1;
    }

    return (
      hour.toString().padStart(2, "0") +
      ":" +
      minute.toString().padStart(2, "0")
    );
  }),
});

function Configure() {
  const history = useHistory();
  const snackbar = useSnackbar();
  const [items, setItems] = useState<ItemsType[]>([]);
  const [countHours, setCountHours] = useState<countHoursType[]>([]);
  const [materiaSelected, setMateriaSelected] = useState<string | unknown>("");
  const [open, setOpen] = useState<boolean>(false);

  const plan = useSelector((state: AppStateType) => state.planReducer);

  const getCycle = useCallback(async () => {
    if (plan.active.id) {
      try {
        const data = await cyclesHttp.listRelationship(plan?.active?.id);
        if (data.status === 200) {
          setItems(data.data.data.subjects);
        }
      } catch (error) {
        console.error(error);
        snackbar.enqueueSnackbar("Não foi possível carregar as informações", {
          variant: "error",
        });
      }
    }
  }, [plan.active, snackbar]);

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getCycle();
    }
    return () => {
      isSubscribed = false;
    };
  }, [getCycle]);

  useEffect(() => {
    let total: countHoursType[] = [];
    items.forEach((value) => {
      const index = total.findIndex(
        (item) => item.subject === value.subject_label
      );
      if (index === -1) {
        total.push({
          subject: value.subject_label as any,
          total: value.seconds,
        });
      } else {
        total[index].total += value.seconds;
      }
    });
    setCountHours(total as any);
  }, [items]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragEnd(event: any) {
    const { active, over } = event;
    if (items.length > 0) {
      if (active.id !== over.id) {
        setItems((items) => {
          const oldIndex = items.findIndex((item) => item.id === active.id);
          const newIndex = items.findIndex((item) => item.id === over.id);
          return arrayMove(items, oldIndex, newIndex);
        });
      }
    }
  }
  function addItemArray() {
    if (materiaSelected) {
      const subject = plan.active.subjects?.find(
        (row) => row.id === materiaSelected
      );
      const object = {
        id: uuidv4(),
        subject_id: materiaSelected as string,
        subject_label: subject?.title,
        seconds: 3600,
      };
      setItems((item) => [...item, object]);
    }
  }

  const handleChangeHours = (id: string, time: string) => {
    const timeValidate = ValidationTime.cast({
      time: time,
    });
    const hour = timeValidate?.time?.substr(0, 2) || "00";
    const minute = timeValidate?.time?.substr(3, 2) || "00";
    const itemIndex = items.findIndex((item) => item.id === id);
    const updatedItem = update(items[itemIndex], {
      seconds: { $set: timeToSec(hour, minute, "00") },
    });
    const newItems = update(items, {
      $splice: [[itemIndex, 1, updatedItem]],
    });
    setItems(newItems);
  };

  function handleDelete(id: string) {
    setItems((items) =>
      items.filter(function (row) {
        return row.id !== id;
      })
    );
  }
  async function onSubmit() {
    if (!plan.active.id) {
      return;
    }
    if (items.length === 0) {
      snackbar.enqueueSnackbar("Adicione matéria ao ciclo.", {
        variant: "warning",
      });
      return;
    }
    try {
      await cyclesHttp.createRelationship(plan.active.id, {
        subjects: items,
      });
      snackbar.enqueueSnackbar("Salvo com sucesso", {
        variant: "success",
      });

      history.goBack();
    } catch (error) {
      console.log(error);
      snackbar.enqueueSnackbar("Não é possível salvar a atividade", {
        variant: "error",
      });
    }
  }
  return (
    <React.Fragment>
      <Helmet title="Ciclo de Estudo" />
      <SuggestCycle open={open} setOpen={setOpen} setItems={setItems} />
      <Grid justify="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Ciclo de Estudo
          </Typography>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/cycle-study">
              Ciclo de Estudo
            </Link>
            <Typography>Editar</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <ButtonIcon
            title={"Voltar"}
            titleTootip={"Voltar"}
            icon={"reply"}
            onClick={() => history.goBack()}
          />
          <ButtonIcon
            id={"button-save-cycle"}
            title={"Salvar Ciclo"}
            titleTootip={"Salvar"}
            icon={"save"}
            onClick={onSubmit}
          />
        </Grid>
      </Grid>
      <Divider my={6} />
      <Grid container spacing={6} className={"noSelect"}>
        <Grid item xs={12} lg={6} xl={6} sm={7}>
          <Lane
            title="Matérias"
            description={
              items.length > 0
                ? "Selecione e arraste para alterar a ordem."
                : "Adicione uma matéria ao ciclo de Estudo."
            }
          >
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={items.map((i) => i.id)}
                strategy={verticalListSortingStrategy}
              >
                {items.map((item, index) => {
                  return (
                    <SortableItem key={item.id} id={item.id}>
                      <Item
                        item={item}
                        onDelete={handleDelete}
                        onChangeHour={handleChangeHours}
                      />
                    </SortableItem>
                  );
                })}
              </SortableContext>
            </DndContext>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="materia-label-id">Matéria</InputLabel>
                  <Select
                    id="select-materia"
                    labelId="materia-label-id"
                    label="Matéria"
                    onChange={(e) => setMateriaSelected(e.target.value)}
                    value={materiaSelected}
                  >
                    {plan?.active?.subjects?.map((item) => (
                      <MenuItem value={item.id} key={item.id}>
                        {item.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <ButtonIcon
                  color="primary"
                  variant="contained"
                  onClick={addItemArray}
                  fullWidth
                  icon={"add"}
                  title={"Matéria"}
                  titleTootip={"Adicionar disciplina no ciclo"}
                  id={"button-add-materia-cycle"}
                />
              </Grid>
              <Grid item xs={6}>
                <ButtonIcon
                  id={"button-suggest-cycle"}
                  title={"Sugerir Ciclo"}
                  titleTootip={"Montar ciclo autmático"}
                  icon={"replay"}
                  fullWidth
                  onClick={() => setOpen(true)}
                />
              </Grid>
            </Grid>
          </Lane>
        </Grid>
        <Grid item xs={12} sm={5} lg={6} xl={6}>
          <Card mb={6}>
            <CardContent pb={0}>
              <Typography variant="h6" gutterBottom>
                Total por matéria
              </Typography>
              {countHours.length === 0 && (
                <Typography variant="body2" gutterBottom>
                  Você ainda não possui nenhuma matéria adicionada.
                </Typography>
              )}
            </CardContent>

            <List component="nav" dense disablePadding>
              {countHours.map((value) => (
                <ListItemNotButton button={false} key={value.subject}>
                  <ListItemText
                    primary={`${value.subject}: ${secToTime(value.total)}`}
                  />
                </ListItemNotButton>
              ))}
            </List>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Configure;
