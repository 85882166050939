import React from "react";
import { withTheme, ThemeProps } from "styled-components/macro";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
} from "chart.js";
import { Theme } from "@material-ui/core";

import { Line } from "react-chartjs-2";

interface LineChartProps extends ThemeProps<Theme> {
  labels: string[];
  data: any[];
}
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function LineChart({ labels, data: dataset, theme }: LineChartProps) {
  const data: ChartData<"line"> = {
    labels: labels,
    datasets: [
      {
        label: "Desempenho",
        fill: true,
        backgroundColor: "transparent",
        borderColor: theme.palette.secondary.main,
        data: dataset,
      },
    ],
  };
  const options: ChartOptions<"line"> = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        intersect: false,
      },
      filler: {
        propagate: true,
      },
    },
    scales: {
      x: {
        grid: {
          color: "rgba(0,0,0,0.05)",
        },
      },
      y: {
        max: 100,
        display: true,
        beginAtZero: true,
        grid: {
          color: "rgba(0,0,0,0)",
        },
        ticks: {
          stepSize: 10,
        },
      },
    },
  };

  return <Line data={data} options={options} />;
}

export default withTheme(LineChart);
