import React, { useState } from "react";
import styled, { withTheme, ThemeProps } from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { Grid, Typography as MuiTypography, Theme } from "@material-ui/core";
import { spacing } from "@material-ui/system";

import {
  a11yProps,
  AntTab,
  AntTabs,
  TabPanel,
} from "../../../components/Material/CustomizedTabs";
import Calendar from "./Components/Calendar";
import ConfigurationSchedule from "./Components/ConfigurationSchedule";
import DailyMetas from "./Components/DailyMetas";

const Typography = styled(MuiTypography)(spacing);

function Schedule({ theme }: ThemeProps<Theme>) {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <Helmet title="Resumo Dashboard" />
      <Grid justify="space-between" container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h3" gutterBottom>
            Cronograma Semanal
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <AntTabs
            value={value}
            onChange={handleChange}
            aria-label="ant example"
          >
            <AntTab label="METAS DIÁRIA" {...a11yProps("diarias")} />
            <AntTab label="METAS SEMANAL" {...a11yProps("semana")} />
            <AntTab label="CONFIGURAR METAS" {...a11yProps("configure")} />
          </AntTabs>
        </Grid>
        <Grid item xs={12}>
          <TabPanel value={value} index={0}>
            <DailyMetas />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Calendar />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ConfigurationSchedule />
          </TabPanel>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default withTheme(Schedule);
