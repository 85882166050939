import React from "react";
import styled from "styled-components/macro";
import {
  Button,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Typography as MuiTypography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)<{ pb?: number }>`
  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;

const Wrapper = styled(Card)`
  border: 1px solid ${(props) => props.theme.palette.grey[300]};
  margin-bottom: ${(props) => props.theme.spacing(2)}px;
  cursor: grab;

  &:hover {
    background: ${(props) => props.theme.palette.background.default};
  }
`;

const WrapperContent = styled(CardContent)`
  position: relative;
  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(2)}px;
  }
`;

const Actions = styled.div`
  margin-left: 0px;
`;

const BoxRight = styled.div`
  display: flex;
  position: absolute;
  bottom: ${(props) => props.theme.spacing(4)}px;
  right: ${(props) => props.theme.spacing(4)}px;
`;

const Order = styled.div`
  color: ${(props) => props.theme.palette.grey[500]};
  font-weight: 600;
  margin-right: ${(props) => props.theme.spacing(1)}px;
  line-height: 1.75;
`;

const Typography = styled(MuiTypography)(spacing);

const Title = styled(Typography)`
  font-weight: 600;
  font-size: 15px;
  margin-left: ${(props) => props.theme.spacing(2)}px;
  margin-right: ${(props) => props.theme.spacing(10)}px;
`;

const Subtitle = styled(Typography)`
  font-size: 10px;
  color: rgba(0, 0, 0, 0.54);
  margin-left: ${(props) => props.theme.spacing(2)}px;
  margin-right: ${(props) => props.theme.spacing(10)}px;
`;
interface ContentProps {
  id: any;
  label: string;
  subheader?: string;
  order: number;
  onDrag: (ev: any) => void;
  onDrop: (ev: any) => void;
  onEdit: () => void;
  onDelete: () => void;
}
const Content: React.FC<ContentProps> = ({
  id,
  label,
  order,
  onDrag,
  subheader,
  onDrop,
  onEdit,
  onDelete,
}) => {
  return (
    <Wrapper
      mb={4}
      id={id}
      draggable={true}
      onDragOver={(ev) => ev.preventDefault()}
      onDragStart={onDrag}
      onDrop={onDrop}
    >
      <WrapperContent>
        <Title variant="body1">{label}</Title>
        {subheader && (
          <Subtitle variant="subtitle2" gutterBottom>
            {subheader}
          </Subtitle>
        )}

        <Actions>
          <Button color="primary" onClick={onEdit}>
            Editar
          </Button>
          <Button color="secondary" onClick={onDelete}>
            Excluir
          </Button>
        </Actions>

        <BoxRight>
          <Order>{order}</Order>
        </BoxRight>
      </WrapperContent>
    </Wrapper>
  );
};

export default Content;
