import * as types from "../../constants";
import { TimeType } from "../reducers/timeStudyReducer";

export type SetIsOpenType = {
  type: typeof types.TIME_ASSESSMENT_IS_OPEN;
  isOpen: boolean;
};

export type SetIsRunningType = {
  type: typeof types.TIME_ASSESSMENT_IS_RUNNING;
  isRunning: boolean;
};
export type SetTimeType = {
  type: typeof types.TIME_ASSESSMENT_TIME;
  time: TimeType;
};

export type SetIntervalType = {
  type: typeof types.TIME_ASSESSMENT_INTERVAL_ID;
  interval: NodeJS.Timer | null;
};

export type ResetType = {
  type: typeof types.TIME_ASSESSMENT_RESET;
};

export function setIsAssessmentOpen(value: boolean): SetIsOpenType {
  return {
    type: types.TIME_ASSESSMENT_IS_OPEN,
    isOpen: value,
  };
}

export function setIsAssessmentRunning(value: boolean): SetIsRunningType {
  return {
    type: types.TIME_ASSESSMENT_IS_RUNNING,
    isRunning: value,
  };
}

export function setAssessmentTime(value: TimeType): SetTimeType {
  return {
    type: types.TIME_ASSESSMENT_TIME,
    time: value,
  };
}

export function setAssessmentIntervalId(
  value: NodeJS.Timer | null
): SetIntervalType {
  return {
    type: types.TIME_ASSESSMENT_INTERVAL_ID,
    interval: value,
  };
}

export function resetAssessmentTime(): ResetType {
  return {
    type: types.TIME_ASSESSMENT_RESET,
  };
}
