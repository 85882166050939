import React, { FC, useContext } from "react";
import styled from "styled-components/macro";

import { Fade, LinearProgress as MuiLinearProgress } from "@material-ui/core";

import { spacing } from "@material-ui/system";
import LoadingContext from "./Loading/LoadingContext";

const LinearProgress = styled(MuiLinearProgress)(spacing);

interface LinearIndeterminateProps {
  my?: number;
}

const LinearIndeterminate: FC<LinearIndeterminateProps> = ({ my = 0 }) => {
  const loading = useContext(LoadingContext);
  return (
    <Fade in={loading}>
      <LinearProgress my={my} />
    </Fade>
  );
};

export default LinearIndeterminate;
