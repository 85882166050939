import React, { FC, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Type } from "../../../types/types";

interface TypesFieldProps {
  options: Type[];
  types: any[];
  setTypes: (types: any) => void;
  error: any;
}

const TypesField: FC<TypesFieldProps> = ({
  setTypes,
  types,
  error,
  options,
}) => {
  const [open, setOpen] = useState(false);
  const loading = open && options.length === 0;

  return (
    <Autocomplete
      id="asynchronous-demo"
      fullWidth
      multiple
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) => option.title === value.title}
      getOptionLabel={(option) => option.title}
      value={types}
      options={options}
      disabled={loading}
      onChange={(event, value) => {
        setTypes(value);
      }}
      loading={loading}
      renderInput={(params) => (
        <TextField
          margin={"normal"}
          {...params}
          label="Tipos de Estudo"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          error={error !== undefined}
          helperText={error?.message}
        />
      )}
    />
  );
};

export default TypesField;
