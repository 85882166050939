import * as types from "../../constants";
import { Type } from "../../types/types";

export type TimeType = {
  passedSeconds?: number;
  prevTime?: number;
  seconds?: number;
};

export type TimerType = {
  expiryTime: any;
  secondsInitial: number;
  seconds: number;
  secondsPassed: number;
  didStart: boolean;
  delay: any;
};

export type TimeStudyType = {
  time?: TimeType | undefined;
  timer: TimerType;
  subjectId: any;
  topicId: string | null;
  date: Date | null;
  isRunning: boolean;
  mode: string;
  interval: number | undefined;
  isOpen: boolean;
  typeStudy: Type[];
};

const INITIAL: TimeStudyType = {
  subjectId: null,
  topicId: null,
  date: null,
  isRunning: false,
  mode: "watch",
  time: {
    passedSeconds: 0,
    prevTime: 0,
    seconds: 0,
  },
  timer: {
    delay: 1000,
    didStart: true,
    secondsInitial: 7200,
    secondsPassed: 0,
    seconds: 0,
    expiryTime: null,
  },
  interval: undefined,
  isOpen: false,
  typeStudy: [],
};

export default function reducer(
  state = INITIAL,
  actions: TimeStudyType & { type: string }
): TimeStudyType {
  switch (actions.type) {
    case types.TIME_STUDY_IS_OPEN:
      return {
        ...state,
        isOpen: actions.isOpen,
      };
    case types.TIME_STUDY_MODE:
      return {
        ...state,
        mode: actions.mode,
      };
    case types.TIME_STUDY_SUBJECT_ID:
      return {
        ...state,
        subjectId: actions.subjectId,
      };
    case types.TIME_STUDY_TOPIC_ID:
      return {
        ...state,
        topicId: actions.topicId,
      };
    case types.TIME_STUDY_IS_RUNNING:
      const date = state.date ?? new Date();
      return {
        ...state,
        date: date,
        isRunning: actions.isRunning,
      };
    case types.TIME_STUDY_TIME:
      return {
        ...state,
        time: {
          ...state.time,
          ...actions.time,
        },
      };
    case types.TIME_STUDY_TIMER:
      return {
        ...state,
        timer: {
          ...actions.timer,
        },
      };
    case types.TIME_STUDY_SECONDS_INITIAL:
      return {
        ...state,
        timer: {
          ...state.timer,
          secondsInitial: actions.timer.secondsInitial,
        },
      };
    case types.TIME_STUDY_INTERVAL_ID:
      return {
        ...state,
        interval: actions.interval,
      };
    case types.TIME_STUDY_TYPE_STUDY:
      return {
        ...state,
        typeStudy: actions.typeStudy,
      };
    case types.TIME_STUDY_RESET: {
      if (state.interval) {
        clearInterval(state.interval);
      }
      return INITIAL;
    }
    case types.AUTH_SIGN_OUT: {
      if (state.interval) {
        clearInterval(state.interval);
      }
      return INITIAL;
    }
    default:
      return state;
  }
}
