import React, { FC } from "react";
import styled from "styled-components/macro";
import {
  CardContent,
  Card as MuiCard,
  TableContainer,
  Table as MuiTable,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CardHeader,
  Grid,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { useFilters, useGlobalFilter, useSortBy, useTable } from "react-table";

import { format } from "date-fns";
import { ReviewType } from "../../../../types/reviews";

const Card = styled(MuiCard)(spacing);

interface FutureType {
  title: string;
  reviews: ReviewType[];
  onDelete: (id: string) => void;
}

const Future: FC<FutureType> = ({ title, reviews, onDelete }) => {
  const data = React.useMemo(() => reviews, [reviews]) || [];
  const columns: any = React.useMemo(
    () => [
      {
        Header: "Matéria",
        accessor: "subject",
      },
      {
        Header: "Assunto",
        accessor: "topic",
      },
      {
        Header: "Intervalo",
        accessor: "interval",
        Cell: ({ value }: any) => {
          return value + " dia(s)";
        },
      },
      {
        Header: "Revisão",
        accessor: "date",
        Cell: ({ value }: any) => {
          if (value) {
            return format(new Date(value), "dd/MM/yy");
          } else {
            return "Em espera";
          }
        },
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      manualSortBy: true,
      autoResetSortBy: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );

  const { headerGroups, rows, prepareRow } = tableInstance;

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Card mb={6}>
          <CardHeader title={title} />
          <CardContent>
            <TableContainer>
              <MuiTable
                aria-labelledby="reviews"
                size={"medium"}
                aria-label="reviews"
              >
                <TableHead>
                  {headerGroups.map((headerGroup, index) => (
                    <TableRow
                      {...headerGroup.getHeaderGroupProps()}
                      key={index}
                    >
                      {headerGroup.headers.map((column: any, i) => (
                        <TableCell {...column.getHeaderProps()} key={i}>
                          {column.render("Header")}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableHead>
                <TableBody>
                  {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <TableRow {...row.getRowProps()} hover key={i}>
                        {row.cells.map((cell: any, index) => (
                          <TableCell {...cell.getCellProps()} key={index}>
                            {cell.render("Cell")}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })}
                  {reviews?.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={4}>Nenhuma revisão futura.</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </MuiTable>
            </TableContainer>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Future;
