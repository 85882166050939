import React, { FC } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { SubmitHandler, useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "../../vendor/yup";
import linksHttp from "../../utils/http/links-http";
import { useSnackbar } from "notistack";

interface LinkCreateProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  onGetLink: () => void;
  topicId: string;
}

interface LinkCreateForm {
  name: string;
  url: string;
}

const SchemaValidation = yup.object({
  name: yup.string().nullable().label("Nome").required("Obrigatório"),
  url: yup.string().nullable().url().label("URL").required("Obrigatório"),
});

const LinkCreate: FC<LinkCreateProps> = ({
  open,
  setOpen,
  topicId,
  onGetLink,
}) => {
  const snackbar = useSnackbar();
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<LinkCreateForm>({
    resolver: yupResolver(SchemaValidation),
  });

  const onSubmit: SubmitHandler<LinkCreateForm> = async (formData) => {
    try {
      await linksHttp.createRelationship(topicId, formData);
      snackbar.enqueueSnackbar("Salvo com sucesso", {
        variant: "success",
      });
      onGetLink();
      setOpen(false);
      reset();
    } catch (error) {
      console.log(error);
      snackbar.enqueueSnackbar("Não é possível salvar link", {
        variant: "error",
      });
    }
  };
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title">Adicionar Link</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <DialogContentText>
            Adicione link de atalho para seu estudo render mais!
          </DialogContentText>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                autoFocus
                margin="dense"
                id="link "
                label="Nome"
                type="text"
                fullWidth
                error={errors.name !== undefined}
                helperText={errors.name?.message}
              />
            )}
          />
          <Controller
            name="url"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                autoFocus
                margin="dense"
                id="link "
                label="Url"
                type="text"
                fullWidth
                error={errors.url !== undefined}
                helperText={errors.url?.message}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancelar
          </Button>
          <Button type={"submit"} color="primary">
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default LinkCreate;
