import React, { useCallback, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import Progresso from "../../../../components/Progresso";
import CardStatus from "../../../../components/CardStatus";
import ProgressChart from "../../../../components/Charts/ProgressChart";
import ListMaterias from "./components/ListMaterias";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../../redux/reducers";

import subjectsHttp from "../../../../utils/http/subjects-http";
import { useSnackbar } from "notistack";
import { format, formatDistanceToNow, parseISO } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";

const Progress = () => {
  const snackbar = useSnackbar();
  const [progressChart, setProgressChart] = useState({
    label: [],
    value: [],
  });
  const [progressTotal, setProgressTotal] = useState(0);
  const plan = useSelector((state: AppStateType) => state.planReducer);

  const getProgressChart = useCallback(async () => {
    if (plan.active.id) {
      try {
        const { data } = await subjectsHttp.getResource(
          plan.active.id,
          "progress-chart"
        );
        setProgressChart(data.data);
      } catch (error) {
        console.error(error);
        snackbar.enqueueSnackbar("Não foi possível carregar as informações!", {
          variant: "error",
        });
      }
    }
  }, [plan.active, snackbar]);

  useEffect(() => {
    let soma = 0;
    const length = progressChart.value.length;
    for (let i = 0; i < length; i++) {
      soma += progressChart.value[i];
    }
    if (soma > 0) {
      setProgressTotal(Math.round(soma / length));
    }
  }, [progressChart]);

  useEffect(() => {
    getProgressChart();
  }, [getProgressChart]);

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} sm={8}>
        <CardStatus
          title={"Progresso"}
          content={<Progresso value={progressTotal} padding={15} />}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <CardStatus
          title={"Tempo de Estudo até a Prova"}
          content={
            plan.active.date_exam
              ? formatDistanceToNow(parseISO(plan.active.date_exam as any), {
                  locale: ptBR,
                })
              : "-"
          }
          footerContent={
            plan.active.date_exam
              ? format(parseISO(plan.active.date_exam as any), "dd/MM/Y")
              : "Sem data definida"
          }
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={6}>
        <ProgressChart
          title={"Progresso"}
          subheader={"Matéria"}
          data={progressChart.value}
          labels={progressChart.label}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <ListMaterias getProgressChart={getProgressChart} />
      </Grid>
    </Grid>
  );
};

export default Progress;
