import * as React from "react";
import { ProfileAlert } from "./ProfileAlert";

export const Alerts = () => {
  return (
    <>
      <ProfileAlert />
    </>
  );
};
