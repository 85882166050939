import React, { FC } from "react";

import { ChartType } from "../../../../types/activities";
import LineChart from "../../../../components/Charts/LineChart";

interface TimeChartProps {
  data: ChartType;
}

const EvolutionChart: FC<TimeChartProps> = ({ data }) => {
  return <LineChart labels={data.label} data={data.value} />;
};

export default EvolutionChart;
