import * as React from "react";
import styled from "styled-components/macro";
import { Power } from "react-feather";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Tooltip, IconButton as MuiIconButton } from "@material-ui/core";

import { signOut } from "../../../redux/actions/authActions";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function Logout() {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleSignOut = async () => {
    await dispatch(signOut());
    history.push("/auth/sign-in");
  };

  return (
    <React.Fragment>
      <Tooltip title="Sair">
        <IconButton
          aria-haspopup="true"
          onClick={handleSignOut}
          color="inherit"
        >
          <Power />
        </IconButton>
      </Tooltip>
    </React.Fragment>
  );
}

export default Logout;
