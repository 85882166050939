import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useHistory } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
  Link,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import { useParams } from "react-router";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "../../../vendor/yup";
import AssuntoField from "./components/Materia/AssuntoField";
import ButtonIcon from "../../../components/ButtonIcon";
import { useSnackbar } from "notistack";
import subjectsHttp from "../../../utils/http/subjects-http";
import { SubjectType } from "../../../types/subjects";
import { useDispatch } from "react-redux";
import { planActive } from "../../../redux/actions/planActions";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const SchemaValidation = yup.object({
  title: yup.string().label("Matéria").required().max(255),
  topics: yup.array().min(1, "Assunto é Requerido").label("Assunto").required(),
});

function MateriaFormEdit() {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [subject, setSubject] = useState<SubjectType | null>(null);
  const snackbar = useSnackbar();
  const {
    handleSubmit,
    setValue,
    watch,
    control,
    reset,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(SchemaValidation),
    defaultValues: {
      title: "",
      topics: [],
    },
  });

  useEffect(() => {
    async function getMateria() {
      try {
        const { data } = await subjectsHttp.get(id);
        reset(data.data);
        setSubject(data.data);
      } catch (error) {
        console.error(error);
        snackbar.enqueueSnackbar("Não foi possível carregar as informações", {
          variant: "error",
        });
      }
    }
    getMateria();
  }, [id, reset, snackbar]);

  const onSubmit: SubmitHandler<SubjectType> = async (formData) => {
    try {
      await subjectsHttp.update(formData.id, formData);
      dispatch(planActive());
      snackbar.enqueueSnackbar("Atualizado com Sucesso", {
        variant: "success",
      });
      history.push(`/plano/${subject?.plan.id}/edit`);
    } catch (error) {
      console.log(error);
      snackbar.enqueueSnackbar("Não é possível salvar", {
        variant: "error",
      });
    }
  };
  return (
    <React.Fragment>
      <Helmet title="Editar Matéria" />

      <Typography variant="h3" gutterBottom display="inline">
        Editar Matéria
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to={`/plano/${subject?.plan.id}/edit`}>
          {subject?.plan.name}
        </Link>

        <Typography>{subject?.title}</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card mb={6}>
              <CardContent>
                <Grid container spacing={6}>
                  <Grid item md={12}>
                    <Controller
                      name="title"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="materia"
                          label="Matéria"
                          variant="outlined"
                          fullWidth
                          my={2}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card mb={6}>
              <CardContent>
                <AssuntoField
                  topics={watch("topics")}
                  setTopics={(value) =>
                    setValue("topics", value, { shouldValidate: true })
                  }
                  textFieldProps={{
                    error: errors.topics !== undefined,
                    helperText: errors.topics?.message,
                  }}
                />
                <ButtonIcon
                  title={"Salvar"}
                  titleTootip={"Salvar"}
                  icon={"save"}
                  type={"submit"}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
}

export default MateriaFormEdit;
