import React, { FC, useEffect, useState } from "react";

import FullScreenDialog from "../../../components/Material/FullScreenDialog";
import {
  Box,
  Button,
  Collapse,
  Container,
  Grid,
  IconButton,
} from "@material-ui/core";
import { useSelector } from "react-redux";

import useStopwatchAssessment from "../../../vendor/react-timer-hook/useStopwatchAssessment";
import SimpleAlert from "../../../components/Material/SimpleAlert";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router-dom";

interface TimerType {
  open: boolean;
  onHandleClose: () => void;
}

const Timer: FC<TimerType> = ({ open, onHandleClose }) => {
  const history = useHistory();
  const {
    seconds,
    minutes,
    hours,
    start,
    pause,
    reset,
    isRunning,
  } = useStopwatchAssessment();
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const timeStudy = useSelector((state: any) => state.timeAssessmentReducer);
  const active = timeStudy.time.seconds > 0;

  useEffect(() => {
    setOpenAlert(false);
  }, [isRunning]);

  function startStudy() {
    start();
  }

  function save() {
    history.push(`/assessments/create`);
  }

  function handleClose() {
    if (isRunning || active) {
      setOpenAlert(true);
      return;
    }
    onHandleClose();
  }

  return (
    <FullScreenDialog
      open={open}
      title={"Cronômetro - Simulado"}
      onHandleClose={handleClose}
    >
      <Container maxWidth="sm">
        <div style={{ textAlign: "center", marginTop: "100px" }}>
          <Collapse in={openAlert}>
            <SimpleAlert
              message={"Salve ou cancele o cronômetro."}
              severity={"warning"}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpenAlert(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            />
          </Collapse>

          <div style={{ fontSize: "60px" }}>
            <span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
          </div>

          <Collapse in={!isRunning}>
            {!active ? (
              <Box my={3} mx={5}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth={true}
                  onClick={startStudy}
                >
                  Iniciar Simulado
                </Button>
              </Box>
            ) : (
              <Grid container>
                <Grid item lg={6} xs={12}>
                  <Box my={3} mx={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="medium"
                      fullWidth={true}
                      onClick={save}
                    >
                      Salvar
                    </Button>
                  </Box>
                </Grid>
                <Grid item lg={6} xs={12}>
                  <Box my={3} mx={2}>
                    <Button
                      variant="contained"
                      color="default"
                      size="medium"
                      fullWidth={true}
                      onClick={startStudy}
                    >
                      Continuar
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Collapse>
          <Collapse in={isRunning}>
            <Grid container>
              <Grid item lg={6} xs={12}>
                <Box my={3} mx={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="medium"
                    fullWidth={true}
                    onClick={pause}
                  >
                    Pausar
                  </Button>
                </Box>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Box my={3} mx={2}>
                  <Button
                    variant="contained"
                    color="default"
                    size="medium"
                    fullWidth={true}
                    onClick={reset}
                  >
                    Cancelar
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Collapse>
        </div>
      </Container>
    </FullScreenDialog>
  );
};

export default Timer;
