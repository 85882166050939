import React, { useCallback, useEffect, useState } from "react";
import styled, { withTheme, ThemeProps } from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import {
  Grid,
  Typography as MuiTypography,
  Theme,
  CardContent,
  CardHeader,
  CircularProgress,
  Card as MuiCard,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Link } from "react-router-dom";
import CardStatus from "../../components/CardStatus";
import { useSnackbar } from "notistack";
import Loader from "../../components/Loader";
import activitiesHttp from "../../utils/http/activities-http";
import { ResponsePaginateType, ResponseType } from "../../types/response";
import {
  ActivityCardType,
  ActivityType,
  ChartType,
} from "../../types/activities";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../redux/reducers";
import Table from "../Historico/Components/Table";
import AssessmentTable from "../Simulados/Components/Table";
import useFilter from "../Historico/Hooks/useFilter";
import { format, startOfWeek, endOfWeek } from "date-fns";
import TimeChart from "../../components/Charts/TimeChart";
import QuestionsChart from "../../components/Charts/QuestionsChart";
import { confirmAlert } from "react-confirm-alert";
import WarningPage from "../../components/WarningPage";
import {
  a11yProps,
  AntTab,
  AntTabs,
  TabPanel,
} from "../../components/Material/CustomizedTabs";
import { AssessmentType } from "../../types/assessments";
import assessmentsHttp from "../../utils/http/assessments-http";
import Progress from "./components/Progress";
import { Metas } from "./components/Metas";
import ButtonIcon from "../../components/ButtonIcon";
import { setIsOpen } from "../../redux/actions/timeStudyActions";

const Typography = styled(MuiTypography)(spacing);

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
  height: 40vh;
  width: 100%;
`;

const now = new Date();
const MENU = ["Estudo Semanal", "Metas Semanal", "Progesso"];
const iniWeek = format(startOfWeek(now, { weekStartsOn: 1 }), "Y-MM-d");
const endWeek = format(endOfWeek(now, { weekStartsOn: 1 }), "Y-MM-dd");

function Resumo({ theme }: ThemeProps<Theme>) {
  const dispatchRedux = useDispatch();
  const snackbar = useSnackbar();
  const { filter, dispatch } = useFilter();
  const [loading, setLoading] = useState(false);
  const [assessments, setAssessments] = useState<AssessmentType[]>([]);
  const [openMetas, setOpenMetas] = useState(false);
  const plan = useSelector((state: AppStateType) => state.planReducer);
  const [value, setValue] = React.useState(0);
  const [abaTop, setAbaTop] = React.useState(0);
  const [timeChart, setTimeChart] = useState<ChartType>({
    title: "",
    subtitle: "",
    label: [],
    value: [],
  });
  const [questionsChart, setQuestionsChart] = useState<ChartType>({
    title: "",
    subtitle: "",
    label: [],
    value: [],
  });
  const [cards, setCards] = useState<ActivityCardType>({
    title: "Todas",
    top: [],
    progress: 0,
    typeOfStudy: {
      label: [],
      value: [],
    },
  });
  const [activities, setActivities] = useState<
    ResponsePaginateType<ActivityType>
  >({
    data: [],
    links: {
      first: "",
      last: "",
      prev: "",
      next: "",
    },
    meta: {
      current_page: 0,
      from: 0,
      last_page: 0,
      path: "",
      per_page: 0,
      to: 0,
      total: 0,
    },
  });

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeAba = (event: React.ChangeEvent<{}>, newValue: number) => {
    setAbaTop(newValue);
  };

  const getAssessments = useCallback(async () => {
    if (plan.active.id) {
      try {
        const { data } = await assessmentsHttp.listRelationship<
          ResponseType<AssessmentType[]>
        >(plan.active.id, {
          queryParam: {
            start: iniWeek,
            end: endWeek,
          },
        });
        setAssessments(data.data);
      } catch (error) {
        console.error(error);
        snackbar.enqueueSnackbar("Não foi possível carregar as informações!", {
          variant: "error",
        });
      }
    }
  }, [plan.active.id, snackbar]);

  const getActivities = useCallback(async () => {
    setLoading(true);
    if (plan.active.id) {
      try {
        const { data } = await activitiesHttp.listRelationship<
          ResponsePaginateType<ActivityType>
        >(plan.active.id, {
          queryParam: {
            start: iniWeek,
            end: endWeek,
          },
        });
        setActivities(data);
      } catch (error) {
        console.error(error);
        snackbar.enqueueSnackbar("Não foi possível carregar as informações!", {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    }
  }, [plan.active, snackbar]);

  useEffect(() => {
    const getCards = async () => {
      if (plan.active.id) {
        try {
          const { data } = await activitiesHttp.listRelationshipResource<
            ResponseType<ActivityCardType>
          >(plan?.active?.id, "cards", {
            queryParam: {
              assessments: true,
              start: iniWeek,
              end: endWeek,
            },
          });
          setCards(data.data);
        } catch (error) {
          console.error(error);
          snackbar.enqueueSnackbar("Não foi possível carregar as informações", {
            variant: "error",
          });
        }
      }
    };
    let isSubscribed = true;
    if (isSubscribed) {
      getCards();
    }
    return () => {
      isSubscribed = false;
    };
  }, [plan.active, snackbar]);

  useEffect(() => {
    const getTimeChart = async () => {
      if (plan.active.id) {
        try {
          const { data } = await activitiesHttp.listRelationshipResource<
            ResponseType<ChartType>
          >(plan?.active?.id, "chart", {
            queryParam: {
              type: "timeWeek",
              start: iniWeek,
              end: endWeek,
            },
          });
          setTimeChart(data.data);
        } catch (error) {
          console.error(error);
          snackbar.enqueueSnackbar("Não foi possível carregar as informações", {
            variant: "error",
          });
        }
      }
    };
    let isSubscribed = true;
    if (isSubscribed) {
      getTimeChart();
    }
    return () => {
      isSubscribed = false;
    };
  }, [plan.active, snackbar]);

  useEffect(() => {
    const getQuestionsChart = async () => {
      if (plan.active.id) {
        try {
          const { data } = await activitiesHttp.listRelationshipResource<
            ResponseType<ChartType>
          >(plan?.active?.id, "chart", {
            queryParam: {
              type: "questions",
              assessments: true,
              start: iniWeek,
              end: endWeek,
            },
          });
          setQuestionsChart(data.data);
        } catch (error) {
          console.error(error);
          snackbar.enqueueSnackbar("Não foi possível carregar as informações", {
            variant: "error",
          });
        }
      }
    };
    let isSubscribed = true;
    if (isSubscribed) {
      getQuestionsChart();
    }
    return () => {
      isSubscribed = false;
    };
  }, [plan.active, snackbar]);

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getActivities();
      getAssessments();
    }
    return () => {
      isSubscribed = false;
    };
  }, [getActivities, getAssessments]);

  const handleDelete = (id: any) => {
    confirmAlert({
      title: "Deseja realmente excluir?",
      message: "Sua atividade de estudo será excluída permanente",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            activitiesHttp.delete(id).then(() => {
              getActivities();
            });
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  };

  const handleAssessmentDelete = (id: any) => {
    confirmAlert({
      title: "Deseja realmente excluir?",
      message: "Sua atividade de estudo será excluída permanente",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            assessmentsHttp.delete(id).then(() => {
              getAssessments();
            });
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <React.Fragment>
      <Helmet title="Resumo Dashboard" />
      <Grid justify="space-between" container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h3" gutterBottom>
            {MENU[abaTop]}
          </Typography>
        </Grid>
        {abaTop !== 2 && (
          <>
            <Grid item>
              <Typography variant="subtitle1" gutterBottom>
                Aqui está o resumo geral do seus estudos da semana.
              </Typography>
              <Typography variant="caption">
                Para ter acesso completo, clique no menu{" "}
                <Link to={"/materia"}>Relatórios.</Link>
              </Typography>
            </Grid>
          </>
        )}
        {abaTop === 1 && (
          <Grid item>
            <ButtonIcon
              title={"Configurar Meta"}
              titleTootip={"Configurar Meta"}
              onClick={() => setOpenMetas(true)}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <AntTabs
            value={abaTop}
            onChange={handleChangeAba}
            aria-label="ant example"
          >
            <AntTab label="SEMANAL" {...a11yProps("semanal")} />
            <AntTab label="METAS" {...a11yProps("metas")} />
            <AntTab label="PROGRESSO" {...a11yProps("progresso")} />
          </AntTabs>
        </Grid>
      </Grid>
      <TabPanel value={abaTop} index={0}>
        {activities.data.length === 0 && assessments.length === 0 ? (
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <WarningPage
                title={"Nenhum estudo realizado na semana!"}
                img="/static/img/study-01.svg"
                onClick={() => dispatchRedux(setIsOpen(true))}
                titleButton={"Comece a estudar agora mesmo!"}
              />
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Grid container spacing={6}>
                  {cards.top.map((item, index) => (
                    <Grid item xs={12} sm={12} md={6} lg={3} xl key={index}>
                      <CardStatus
                        title={item.label}
                        content={item.value}
                        chip={cards.title}
                        footerContent={item.textFooter}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={6}>
              <Grid item xs={12} lg={6}>
                <Card mb={1}>
                  <CardContent>
                    <CardHeader
                      action={
                        loading && (
                          <CircularProgress color="inherit" size={20} />
                        )
                      }
                      title={timeChart.title}
                      subheader={timeChart.subtitle}
                    />
                    <ChartWrapper>
                      <TimeChart data={timeChart} />
                    </ChartWrapper>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Card mb={1}>
                  <CardContent>
                    <CardHeader
                      action={
                        loading && (
                          <CircularProgress color="inherit" size={20} />
                        )
                      }
                      title={questionsChart.title}
                      subheader={questionsChart.subtitle}
                    />

                    <ChartWrapper>
                      <QuestionsChart data={questionsChart} />
                    </ChartWrapper>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <AntTabs
                  value={value}
                  onChange={handleChange}
                  aria-label="ant example"
                >
                  <AntTab label="Atividades" {...a11yProps(0)} />
                  <AntTab label="Simulados" {...a11yProps(1)} />
                </AntTabs>
              </Grid>
              <Grid item xs={12} lg={12} xl={12}>
                <TabPanel value={value} index={0}>
                  <Table
                    activities={activities}
                    getActivities={getActivities}
                    onDelete={handleDelete}
                    filter={filter}
                    dispatch={dispatch}
                  />
                </TabPanel>
                <TabPanel index={1} value={value}>
                  <AssessmentTable
                    assessments={assessments}
                    onDelete={handleAssessmentDelete}
                  />
                </TabPanel>
              </Grid>
            </Grid>
          </>
        )}
      </TabPanel>
      <TabPanel value={abaTop} index={1}>
        <Metas open={openMetas} setOpen={setOpenMetas} />
      </TabPanel>
      <TabPanel value={abaTop} index={2}>
        <Progress />
      </TabPanel>
    </React.Fragment>
  );
}

export default withTheme(Resumo);
