import React, { FC, useEffect, useState } from "react";
import styled from "styled-components/macro";

import {
  CardContent,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card as MuiCard,
  Typography,
  CardHeader,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";

import { spacing } from "@material-ui/system";
import { PlanType } from "../../../../../types/plans";
import topicsHttp from "../../../../../utils/http/topics-http";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../../../redux/reducers";
import plansHttp from "../../../../../utils/http/plans-http";
import Loader from "../../../../../components/Loader";

const Card = styled(MuiCard)(spacing);

interface ListMateriasType {
  getProgressChart: () => void;
}

const ListMaterias: FC<ListMateriasType> = ({ getProgressChart }) => {
  const snackbar = useSnackbar();
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [planState, setPlanState] = useState<PlanType>({});
  const plan = useSelector((state: AppStateType) => state.planReducer);

  useEffect(() => {
    const getPlan = async () => {
      setLoadingPage(true);
      try {
        const { data } = await plansHttp.get(plan.active.id);
        setPlanState(data.data);
        getProgressChart();
      } catch (error) {
        console.error(error);
        snackbar.enqueueSnackbar("Não foi possível atualizar as informações", {
          variant: "error",
        });
      } finally {
        setLoadingPage(false);
      }
    };
    getPlan();
  }, [plan, snackbar, getProgressChart]);

  const handleChangeTopics = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const id = event.target.value;
    const checked = event.target.checked;
    setLoading(true);
    try {
      const { data } = await topicsHttp.getResource(
        plan.active.id,
        "completed",
        {
          queryParam: {
            topic_id: id,
            completed: checked === true ? 1 : 0,
          },
        }
      );
      setPlanState(data.data);
      getProgressChart();
    } catch (error) {
      console.error(error);
      snackbar.enqueueSnackbar("Não foi possível atualizar as informações", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false);
  };
  if (loadingPage) {
    return <Loader />;
  }

  return (
    <Card mb={6}>
      <CardHeader
        title={"Matérias"}
        subheader="Marque as matérias concluídas"
      />
      <CardContent>
        <div>
          {planState.subjects?.map((row) => (
            <Accordion
              expanded={expanded === row.id}
              onChange={handleChange(row.id)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{row.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormGroup>
                  {row.topics.map((row) => {
                    return (
                      <FormControlLabel
                        key={row.id}
                        control={
                          <Checkbox
                            checked={!!row.completed}
                            onChange={handleChangeTopics}
                            name={row.title}
                            value={row.id}
                            disabled={loading}
                          />
                        }
                        label={row.title}
                      />
                    );
                  })}
                </FormGroup>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

export default ListMaterias;
