import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components/macro";
import {
  Avatar,
  Card as MuiCard,
  CardContent,
  FormControl as MuiFormControl,
  Grid,
  TextField as MuiTextField,
  Typography,
  Box,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
} from "@material-ui/core";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import * as yup from "../../../../vendor/yup";
import { spacing, SpacingProps } from "@material-ui/system";
import ButtonIcon from "../../../../components/ButtonIcon";
import userHttp from "../../../../utils/http/user-http";
import InputMask from "react-input-mask";
import { useSnackbar } from "notistack";
import LoadingContext from "../../../../components/Loading/LoadingContext";
import httpAxios from "../../../../utils/http";
import { isDate, parse } from "date-fns";
import { convertIsoDate } from "../../../../utils/Dates";
import { User } from "../../../../types/user";
import { ResponseType } from "../../../../types/response";
import { useDispatch } from "react-redux";
import Upload from "./Upload";
import { setUser } from "../../../../redux/actions/authActions";

import Loader from "../../../../components/Loader";

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface SelectMuiType extends SpacingProps {
  mt?: number;
}
const FormControl = styled(MuiFormControl)<{ my?: number }>(spacing);

const Select = styled(MuiSelect)<SelectMuiType>(spacing);

const CenteredContent = styled.div`
  text-align: center;
`;

const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 0 auto ${(props) => props.theme.spacing(2)}px;
`;

interface FormProps {
  edit?: boolean;
}

const SchemaValidation = yup.object({
  nickname: yup.string().nullable().label("Nickname").required().max(255),
  name: yup.string().label("Nome Completo").required().max(255),
  uf: yup.string().nullable().label("Estado").required().max(255),
  genre: yup.string().nullable().label("Sexo").required(),
  date_birth: yup
    .date()
    .nullable()
    .required("Obrigatório")
    .max(new Date(), "Você é um viajante do tempo ?!")
    .transform(function (value: string, originalValue: string) {
      if (this.isType(value)) return value;
      const date = parse(originalValue, "dd/MM/yyyy", new Date());
      return isDate(date) ? date : new Date("");
    })
    .typeError("Data inválida"),
});

const Form: FC<FormProps> = ({ edit }) => {
  const [avatar, setAvatar] = useState<string>("");
  const snackbar = useSnackbar();
  const loading = useContext(LoadingContext);
  const dispatch = useDispatch();
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<User>({
    resolver: yupResolver(SchemaValidation),
    defaultValues: {
      name: "",
      nickname: "",
      email: "",
      uf: "",
      date_birth: "",
      genre: "M",
    },
  });

  const formatingDateBirth = useCallback(
    (user: User) => {
      const newDateOfBirth = convertIsoDate(user.date_birth as string);
      const values = { ...user, date_birth: newDateOfBirth };
      reset(values);
    },
    [reset]
  );

  useEffect(() => {
    if (!edit) {
      return;
    }
    let isSubscribed = true;
    (async () => {
      try {
        const { data } = await userHttp.list<ResponseType<User>>();
        if (isSubscribed) {
          if (data.data.avatar) {
            setAvatar(data.data.avatar);
          }
          formatingDateBirth(data.data);
        }
      } catch (error) {
        console.error(error);
        snackbar.enqueueSnackbar("Não foi possível carregar as informações", {
          variant: "error",
        });
      }
    })();
    return () => {
      isSubscribed = false;
    };
  }, [edit, formatingDateBirth, snackbar]);
  const onSubmit: SubmitHandler<User> = async (formData) => {
    try {
      const { data } = await httpAxios.put<ResponseType<User>>(
        "api/user",
        formData
      );
      formatingDateBirth(data.data);
      dispatch(setUser(data.data));

      snackbar.enqueueSnackbar("Atualizado com Sucesso", {
        variant: "success",
      });
    } catch (error) {
      console.log(error);
      snackbar.enqueueSnackbar("Não é possível salvar a categoria", {
        variant: "error",
      });
    }
  };

  return (
    <Card mb={6}>
      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={6}>
            <Grid item md={8}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="email"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    my={2}
                    InputLabelProps={{ shrink: true }}
                    disabled={true}
                  />
                )}
              />

              <Controller
                name="nickname"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="nickname"
                    label="Nickname"
                    variant="outlined"
                    fullWidth
                    my={2}
                    error={errors.nickname !== undefined}
                    helperText={errors.nickname?.message}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />

              <Grid container spacing={4}>
                <Grid item md={12}>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="name"
                        label="Nome Completo"
                        variant="outlined"
                        fullWidth
                        my={2}
                        error={errors.name !== undefined}
                        helperText={errors.name?.message}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6}>
                  <Controller
                    name="date_birth"
                    control={control}
                    render={({ field }) => (
                      <InputMask {...field} mask={"99/99/9999"}>
                        {(inputProps: any) => (
                          <TextField
                            {...inputProps}
                            id="date_birth"
                            label="Data de Nascimento"
                            variant="outlined"
                            fullWidth
                            inputProps={{ inputMode: "numeric" }}
                            my={2}
                            error={errors.date_birth !== undefined}
                            helperText={errors.date_birth?.message}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      </InputMask>
                    )}
                  />
                </Grid>
                <Grid item md={6}>
                  <Controller
                    name="uf"
                    control={control}
                    render={({ field }) => (
                      <FormControl
                        variant="outlined"
                        fullWidth
                        my={2}
                        error={errors.uf !== undefined}
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          Estado
                        </InputLabel>
                        <Select
                          labelId="uf"
                          id="uf-id"
                          label="Estado"
                          {...field}
                        >
                          <MenuItem value="AC">Acre</MenuItem>
                          <MenuItem value="AL">Alagoas</MenuItem>
                          <MenuItem value="AP">Amapá</MenuItem>
                          <MenuItem value="AM">Amazonas</MenuItem>
                          <MenuItem value="BA">Bahia</MenuItem>
                          <MenuItem value="CE">Ceará</MenuItem>
                          <MenuItem value="ES">Espírito Santo</MenuItem>
                          <MenuItem value="GO">Goiás</MenuItem>
                          <MenuItem value="MA">Maranhão</MenuItem>
                          <MenuItem value="MT">Mato Grosso</MenuItem>
                          <MenuItem value="MS">Mato Grosso do Sul</MenuItem>
                          <MenuItem value="MG">Minas Gerais</MenuItem>
                          <MenuItem value="PA">Pará</MenuItem>
                          <MenuItem value="PB">Paraíba</MenuItem>
                          <MenuItem value="PR">Paraná</MenuItem>
                          <MenuItem value="PE">Pernambuco</MenuItem>
                          <MenuItem value="PI">Piauí</MenuItem>
                          <MenuItem value="RJ">Rio de Janeiro</MenuItem>
                          <MenuItem value="RN">Rio Grande do Norte</MenuItem>
                          <MenuItem value="RS">Rio Grande do Sul</MenuItem>
                          <MenuItem value="RO">Rondônia</MenuItem>
                          <MenuItem value="RR">Roraima</MenuItem>
                          <MenuItem value="SC">Santa Catarina</MenuItem>
                          <MenuItem value="SP">São Paulo</MenuItem>
                          <MenuItem value="SE">Sergipe</MenuItem>
                          <MenuItem value="TO">Tocantins</MenuItem>
                          <MenuItem value="DF">Distrito Federal</MenuItem>
                        </Select>
                        <FormHelperText>{errors.uf?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>
              <FormControl
                error={errors.genre !== undefined}
                variant="outlined"
                fullWidth={true}
                my={4}
              >
                <FormLabel>Sexo</FormLabel>
                <Controller
                  name="genre"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup aria-label="genre" {...field}>
                      <FormControlLabel
                        value="F"
                        control={<Radio />}
                        label="Feminino"
                      />
                      <FormControlLabel
                        value="M"
                        control={<Radio />}
                        label="Masculino"
                      />
                    </RadioGroup>
                  )}
                />

                <FormHelperText>{errors.genre?.message}</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item md={4}>
              <CenteredContent>
                {avatar && !loading ? (
                  <BigAvatar alt="Gestor Concursos" src={avatar} />
                ) : (
                  <Loader />
                )}
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="raised-button-file"
                  multiple
                  type="file"
                />
                <label htmlFor="raised-button-file">
                  <Upload setAvatar={setAvatar} />

                  <Typography variant="caption" display="block" gutterBottom>
                    Para melhores resultados, use uma imagem de pelo menos 128px
                    por 128px
                  </Typography>
                </label>
              </CenteredContent>
            </Grid>
          </Grid>
          <Box style={{ marginTop: "10px" }}>
            <ButtonIcon
              title={"Salvar"}
              titleTootip={"Salvar informações"}
              icon={"save"}
              type={"submit"}
              disabled={loading}
            />
          </Box>
        </form>
      </CardContent>
    </Card>
  );
};

export default Form;
