import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import DateFnsUtils from "@date-io/date-fns";

import { ThemeProvider } from "styled-components/macro";
import { create } from "jss";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
  jssPreset,
} from "@material-ui/core/styles";

import createTheme from "./theme";
import Routes from "./routes/Routes";
import { AppStateType } from "./redux/reducers";
import SnackbarProvider from "./components/SnackbarProvider";
import LoadingProvider from "./components/Loading/LoadingProvider";

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point")!,
});

function App() {
  const theme = useSelector((state: AppStateType) => state.themeReducer);
  const user = useSelector((state: AppStateType) => state.authReducer);

  useEffect(() => {
    if (user.user) {
      // @ts-ignore
      window.usetifulTags = {
        userId: user.user.id,
      };
    }
  }, [user]);

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet
          titleTemplate="%s | Gestor Concursos"
          defaultTitle="Gestor Concursos - Turbine seus estudos!"
        />
        <StylesProvider jss={jss}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <MuiThemeProvider theme={createTheme(theme.currentTheme)}>
              <ThemeProvider theme={createTheme(theme.currentTheme)}>
                <LoadingProvider>
                  <SnackbarProvider>
                    <Routes />
                  </SnackbarProvider>
                </LoadingProvider>
              </ThemeProvider>
            </MuiThemeProvider>
          </MuiPickersUtilsProvider>
        </StylesProvider>
      </HelmetProvider>
    </React.Fragment>
  );
}

export default App;
