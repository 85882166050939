import React, { FC } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import {
  CardContent,
  Card as MuiCard,
  TableContainer,
  Table as MuiTable,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  CardHeader,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { useFilters, useGlobalFilter, useSortBy, useTable } from "react-table";
import Paginacao from "./Paginacao";
import { Edit as EditIcon, Delete as DeleteIcon } from "@material-ui/icons";
import { ResponsePaginateType } from "../../../../types/response";
import { ActivityType } from "../../../../types/activities";
import Chips from "../../../../components/Chips";
import { StateFilterProps } from "../../Hooks/useFilter";
import { format } from "date-fns";
import LinearIndeterminate from "../../../../components/LinearIndeterminate";

const Card = styled(MuiCard)(spacing);

interface TableProps {
  activities: ResponsePaginateType<ActivityType>;
  filter: StateFilterProps;
  dispatch: (value: any) => void;
  getActivities: () => void;
  onDelete: (id: string) => void;
}

const Table: FC<TableProps> = (props) => {
  const { activities, filter, dispatch, getActivities, onDelete } = props;

  const data = React.useMemo(() => activities.data, [activities]);
  const columns: any = React.useMemo(
    () => [
      {
        Header: "Data",
        accessor: "start",
        Cell: ({ value }: any) => {
          return format(new Date(value), "dd/MM/yy HH:mm:ss");
        },
      },
      {
        Header: "Matéria",
        accessor: "subject",
      },
      {
        Header: "Assunto",
        accessor: "topic",
      },
      {
        Header: "Tipo",
        accessor: "types",
        disableSortBy: true,
        Cell: ({ value }: any) => {
          return value.map((row: any, index: number) => (
            <Chips
              key={index}
              size="small"
              mr={1}
              mb={1}
              label={row.title}
              color={row.color}
            />
          ));
        },
      },
      {
        Header: "Tempo",
        accessor: "time",
      },
      {
        Header: "Questões",
        accessor: "questions",

        Cell: ({ row }: any) => {
          return `${row.original.hits}/${row.original.total}`;
        },
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      manualSortBy: true,
      autoResetSortBy: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );

  const { headerGroups, rows, prepareRow } = tableInstance;

  return (
    <Card mb={6}>
      <CardHeader title="Atividades" />
      <CardContent>
        <TableContainer>
          <MuiTable
            aria-labelledby="historicos"
            size={"medium"}
            aria-label="historico"
          >
            <TableHead>
              {headerGroups.map((headerGroup, index) => (
                <TableRow {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column: any, i) => (
                    <TableCell {...column.getHeaderProps()} key={i}>
                      {column.render("Header")}
                    </TableCell>
                  ))}
                  <TableCell colSpan={2}>Ação</TableCell>
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <TableRow {...row.getRowProps()} hover key={i}>
                    {row.cells.map((cell: any, index) => (
                      <TableCell {...cell.getCellProps()} key={index}>
                        {cell.render("Cell")}
                      </TableCell>
                    ))}
                    <TableCell>
                      <IconButton
                        aria-label="edit"
                        component={NavLink}
                        to={`/activity/${row.original.id}/edit`}
                        size={"small"}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="delete"
                        onClick={() => onDelete(row.original.id)}
                        size={"small"}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
              {activities.data.length === 0 && (
                <TableRow>
                  <TableCell colSpan={7}>
                    Nenhuma atividade cadastrada.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </MuiTable>
        </TableContainer>
      </CardContent>
      {activities.data.length > 0 && (
        <>
          <Paginacao
            getActivities={getActivities}
            filter={filter}
            dispatch={dispatch}
            total={activities.meta.total}
          />
          <LinearIndeterminate />
        </>
      )}
    </Card>
  );
};

export default Table;
