import React, { FC, useState } from "react";
import { formatISO, subDays } from "date-fns";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Chip as MuiChip,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ButtonIcon from "../../../components/ButtonIcon";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

const Chip = styled(MuiChip)(spacing);

interface DateRangeType {
  open: boolean;
  setOpen: (value: boolean) => void;
}

const DateRange: FC<DateRangeType> = ({ open, setOpen }) => {
  const history = useHistory();
  const [state, setState] = useState<{
    startDate: Date;
    endDate: Date;
  }>({
    startDate: subDays(new Date(), 30),
    endDate: new Date(),
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (label: string, value: Date | null) => {
    if (Object.prototype.toString.call(value) === "[object Date]" && value) {
      if (!isNaN(value.getTime())) {
        setState({ ...state, [label]: value });
      }
    }
  };

  const handleChipChange = (day: number) => {
    const end = new Date();
    const start = subDays(new Date(), day);
    setState({
      startDate: start,
      endDate: end,
    });
  };

  const onSubmit = () => {
    const url = history.location.pathname;
    const start = formatISO(state.startDate);
    const end = formatISO(state.endDate);
    history.push(`${url}?startDate=${start}&endDate=${end}`);
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Período Relatório</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Selecione o periodo de datas do relatório
        </DialogContentText>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Chip
              label="1 ano"
              m={1}
              onClick={() => handleChipChange(365)}
              color={"primary"}
              size={"small"}
            />
            <Chip
              label="90 dias"
              m={1}
              color={"primary"}
              onClick={() => handleChipChange(90)}
              size={"small"}
            />
            <Chip
              label="60 dias"
              m={1}
              color={"primary"}
              onClick={() => handleChipChange(60)}
              size={"small"}
            />
            <Chip
              label="30 dias"
              m={1}
              color={"primary"}
              onClick={() => handleChipChange(30)}
              size={"small"}
            />
            <Chip
              label="15 dias"
              m={1}
              color={"primary"}
              onClick={() => handleChipChange(15)}
              size={"small"}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                autoOk
                variant="inline"
                format="dd/MM/yy"
                margin="normal"
                id="date-picker-inline"
                label="Início"
                value={state.startDate}
                inputVariant={"outlined"}
                onChange={(value) => handleChange("startDate", value)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xs={12} sm={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                autoOk
                variant="inline"
                format="dd/MM/yy"
                margin="normal"
                id="date-picker-inline"
                label="Fim"
                value={state.endDate}
                inputVariant={"outlined"}
                onChange={(value) => handleChange("endDate", value)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonIcon
          title={"Salvar"}
          titleTootip={"Gerar relatório"}
          onClick={onSubmit}
        />
      </DialogActions>
    </Dialog>
  );
};

export default DateRange;
