import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";

import "react-dragula/dist/dragula.css";

import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  Grid,
  Typography as MuiTypography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";

import ButtonIcon from "../../../../components/ButtonIcon";
import Chips from "../../../../components/Chips";
import MetaForm from "./MetaForm";
import planningsHttp from "../../../../utils/http/plannings-http";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../../redux/reducers";
import { Type } from "../../../../types/types";
import { ScheduleType } from "../../../../types/plannings";
import { ResponseType } from "../../../../types/response";
import Loader from "../../../../components/Loader";
import { secToTime } from "../../../../utils/Times";
import { weekDays } from "../../../../config/constantes";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)<{ pb?: number }>`
  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)};
  }
`;

const TaskWrapper = styled(Card)`
  border: 1px solid ${(props) => props.theme.palette.grey[300]};
  margin-bottom: ${(props) => props.theme.spacing(4)}px;
  cursor: grab;

  &:hover {
    background: ${(props) => props.theme.palette.background.default};
  }
`;

const TaskWrapperContent = styled(CardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(2)}px;
  }
`;

const Typography = styled(MuiTypography)(spacing);

const TaskTitle = styled(Typography)`
  font-weight: 600;
  font-size: 16px;
  margin-right: ${(props) => props.theme.spacing(10)};
`;

const TaskSubtitle = styled(Typography)`
  font-size: 12px;
  margin-right: ${(props) => props.theme.spacing(10)};
`;

type LaneProps = {
  title: string;
  description: string;
  onContainerLoaded: (container: Element) => void;
};
const Lane: React.FC<LaneProps> = ({
  title,
  description,
  onContainerLoaded,
  children,
}) => {
  const handleContainerLoaded = (container: HTMLDivElement | null) => {
    if (container) {
      onContainerLoaded(container);
    }
  };

  return (
    <Card mb={6}>
      <CardContent pb={0}>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body2" mb={4}>
          {description}
        </Typography>
        <div ref={handleContainerLoaded}>{children}</div>
      </CardContent>
    </Card>
  );
};

type TaskProps = {
  id: string;
  title: string;
  subtitle?: string;
  duration: number;
  types: Type[];
  onDelete: (id: string) => void;
};
const Task: React.FC<TaskProps> = ({
  id,
  title,
  subtitle,
  duration,
  types,
  onDelete,
}) => {
  return (
    <TaskWrapper mb={4}>
      <TaskWrapperContent>
        <TaskTitle variant="body1" gutterBottom>
          {title}
        </TaskTitle>
        {subtitle && <TaskSubtitle gutterBottom>{subtitle}</TaskSubtitle>}
        {duration > 0 && (
          <TaskSubtitle gutterBottom>
            Tempo de Estudo: {secToTime(duration)}
          </TaskSubtitle>
        )}
        <Grid container justify="space-between">
          <Grid item>
            {types.map((row) => (
              <Chips
                key={row.id}
                label={row.title}
                color={row.color}
                size={"small"}
              />
            ))}
          </Grid>
          <Grid item>
            <ButtonIcon
              title={"Exclur"}
              titleTootip={"Excluir meta"}
              size={"small"}
              icon={"delete"}
              onClick={() => onDelete(id)}
            />
          </Grid>
        </Grid>
      </TaskWrapperContent>
    </TaskWrapper>
  );
};

const containers: Array<Element> = [];

function ConfigurationSchedule() {
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [planning, setPlanning] = useState<ScheduleType | null>(null);
  const plan = useSelector((state: AppStateType) => state.planReducer);

  const onContainerReady = (container: Element) => {
    containers.push(container);
  };

  const getPlanning = useCallback(async () => {
    setLoading(true);
    if (!plan.active.id) {
      return;
    }
    try {
      const { data } = await planningsHttp.get<ResponseType<ScheduleType>>(
        plan.active.id,
        {
          queryParam: { type: "schedule" },
        }
      );

      setPlanning(data.data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [plan.active.id]);

  useEffect(() => {
    getPlanning();
  }, [getPlanning]);

  const handleDelete = async (id: string) => {
    if (!plan.active.id) {
      return;
    }
    try {
      await planningsHttp.deleteResource(plan.active.id, id, {
        config: { params: { type: "schedule" } },
      });
      getPlanning();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleDestroyAll = async () => {
    if (!plan.active.id) {
      return;
    }
    try {
      await planningsHttp.deleteResource(plan.active.id, "destroy-all", {
        config: { params: { type: "schedule" } },
      });
      getPlanning();
    } catch (e) {
      console.log(e);
    }
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <React.Fragment>
      <Helmet title={"Configurar Meta"} />
      <Grid justify="space-between" container spacing={4}>
        <Grid item>
          <Typography variant="caption">
            Configure seu cronograma semanal.
          </Typography>
        </Grid>
        <Grid item>
          <ButtonIcon
            title={"Adicionar Meta"}
            titleTootip={"Adicionar Meta"}
            icon={"add"}
            onClick={() => setOpen(true)}
          />
          <ButtonIcon
            title={"Apagar Metas"}
            titleTootip={"Apagar todas metas"}
            icon={"delete"}
            onClick={handleDestroyAll}
          />
        </Grid>

        <Grid item xs={12}>
          {weekDays.map((day, index) => (
            <Lane
              key={index}
              title={day.weeky}
              description={`Atividades previstas para ${day.weeky}`}
              onContainerLoaded={onContainerReady}
            >
              {planning?.value
                ? planning?.value.map((row) => {
                    if (row.day === day.cod) {
                      return (
                        <Task
                          key={row.id}
                          id={row.id}
                          title={row.subject}
                          subtitle={row.topic}
                          duration={row.duration}
                          types={row.types}
                          onDelete={handleDelete}
                        />
                      );
                    } else {
                      return null;
                    }
                  })
                : "Nenhuma atividade cadastrada"}
            </Lane>
          ))}
        </Grid>
      </Grid>
      <MetaForm open={open} setOpen={setOpen} getPlanning={getPlanning} />
    </React.Fragment>
  );
}

export default ConfigurationSchedule;
