import React, { FC } from "react";
import draftToHtml from "draftjs-to-html";
import CardMenuList from "./CardMenuList";
import { format, parseISO } from "date-fns";
import Lane from "./Lane";
import { ReviewType } from "../types/reviews";
import { REVISAOID } from "../config/constantes";
import {
  resetTime,
  setIsOpen,
  setSubjectId,
  setTopicId,
  setTypeStudy,
} from "../redux/actions/timeStudyActions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import reviewsHttp from "../utils/http/reviews-http";

interface ReviewCardProp {
  title: string;
  description?: string;
  reviews: ReviewType[];
  color: string;
  onGetReviews: () => void;
}

const ReviewCard: FC<ReviewCardProp> = ({
  title,
  description,
  color,
  reviews,
  onGetReviews,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const hangleAction = (review: ReviewType, type: string) => {
    if (type === "manual") {
      history.push(
        `/activity/create?subject_id=${review.subject_id}&topic_id=${review.topic_id}&types=${REVISAOID}`
      );
    }
    if (type === "cronometro") {
      dispatch(resetTime());
      dispatch(setSubjectId(review.subject_id));
      dispatch(setTopicId(review.topic_id));
      dispatch(setTypeStudy([REVISAOID]));
      dispatch(setIsOpen(true));
    }
  };

  const handleDelete = async (id: string) => {
    confirmAlert({
      title: "Deseja realmente excluir essa revisão?",
      message: "Será excluido permanente.",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            reviewsHttp.delete(id).then(() => {
              onGetReviews();
            });
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <Lane title={title} description={description}>
      {reviews.map((row) => {
        const notes = draftToHtml(JSON.parse(row.notes));
        return (
          <CardMenuList
            key={row.id}
            date={format(parseISO(row.date), "dd/MM/yy")}
            color={color}
            title={row.subject}
            subtitle={row.topic}
            html={notes}
            menu={[
              {
                func: () => {
                  hangleAction(row, "cronometro");
                },
                label: "Cronômetro",
              },
              {
                func: () => {
                  hangleAction(row, "manual");
                },
                label: "Manual",
              },
              {
                func: () => {
                  handleDelete(row.id);
                },
                label: "Deletar",
              },
            ]}
          />
        );
      })}
    </Lane>
  );
};

export default ReviewCard;
