import React, { FC, useCallback, useEffect, useState } from "react";

import { useSnackbar } from "notistack";

import styled from "styled-components/macro";
import {
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CardHeader,
  Grid,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import activitiesHttp from "../../../../utils/http/activities-http";
import { ResponseType } from "../../../../types/response";
import { ChartType } from "../../../../types/activities";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../../redux/reducers";
import TimeChart from "../../../../components/Charts/TimeChart";
import QuestionsChart from "../../../../components/Charts/QuestionsChart";
import Loader from "../../../../components/Loader";
import EvolutionChart from "./EvolutionChart";
import { useParams } from "react-router";
import { isEmpty } from "../../../../utils/functions";

const Card = styled(MuiCard)(spacing);

const Button = styled(MuiButton)(spacing);

const ChartWrapper = styled.div`
  height: 40vh;
  width: 100%;
`;

interface BarChartType {
  date: {
    startDate: string;
    endDate: string;
  };
}

const BarChart: FC<BarChartType> = ({ date }) => {
  const params = useParams<{ id?: string }>();
  const snackbar = useSnackbar();
  const plan = useSelector((state: AppStateType) => state.planReducer);

  const [loading, setLoading] = useState<boolean>(false);
  const [type, setType] = useState<string>("time");
  const [data, setData] = useState<ChartType>({
    title: "",
    subtitle: "",
    label: [],
    value: [],
  });

  const getBarChart = useCallback(
    async (type: string) => {
      setLoading(true);
      if (!isEmpty(plan.active)) {
        setType(type);
        try {
          const { data } = await activitiesHttp.listRelationshipResource<
            ResponseType<ChartType>
          >(plan?.active?.id, "chart", {
            queryParam: {
              type,
              ...(params.id && { subject_id: params.id }),
              ...(date.startDate && {
                start: date.startDate,
              }),
              ...(date.endDate && {
                end: date.endDate,
              }),
            },
          });
          setData(data.data);
        } catch (error) {
          console.error(error);
          snackbar.enqueueSnackbar("Não foi possível carregar as informações", {
            variant: "error",
          });
        } finally {
          setLoading(false);
        }
      }
    },
    [params.id, plan.active, snackbar, date.startDate, date.endDate]
  );

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getBarChart("time");
    }
    return () => {
      isSubscribed = false;
    };
  }, [params, getBarChart]);

  return (
    <Card mb={1}>
      <CardContent>
        <CardHeader title={data.title} subheader={data.subtitle} />

        <ChartWrapper>
          {loading ? (
            <Loader />
          ) : type === "evolution" ? (
            <EvolutionChart data={data} />
          ) : type === "time" ? (
            <TimeChart data={data} />
          ) : (
            <QuestionsChart data={data} />
          )}
        </ChartWrapper>
        <div style={{ marginTop: 15 }}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item>
              <Button
                mr={2}
                size="small"
                color="primary"
                onClick={() => getBarChart("time")}
                disabled={loading}
              >
                Tempo
              </Button>
            </Grid>
            <Grid item>
              <Button
                mr={2}
                size="small"
                color="primary"
                onClick={() => getBarChart("questions")}
                disabled={loading}
              >
                Questões
              </Button>
            </Grid>
            <Grid item>
              <Button
                mr={2}
                size="small"
                color="primary"
                onClick={() => getBarChart("evolution")}
                disabled={loading}
              >
                Evolução
              </Button>
            </Grid>
          </Grid>
        </div>
      </CardContent>
    </Card>
  );
};

export default BarChart;
