import { Time, Validate } from "./utils";
import { useDispatch, useSelector } from "react-redux";
import {
  setIntervalId,
  setIsRunning,
  setTimer,
} from "../../redux/actions/timeStudyActions";
import { useInterval } from "./hooks";
import { setSettingsTimer } from "../../redux/actions/settingsActions";

const DEFAULT_DELAY = 1000;
function getDelayFromExpiryTimestamp(expiryTimestamp: any) {
  if (!Validate.expiryTimestamp(expiryTimestamp)) {
    return null;
  }

  const seconds = Time.getSecondsFromExpiry(expiryTimestamp, true);
  const extraMilliSeconds = Math.floor((seconds - Math.floor(seconds)) * 1000);
  return extraMilliSeconds > 0 ? extraMilliSeconds : DEFAULT_DELAY;
}

export default function useTimer({
  expiryTimestamp: expiry,
  onExpire,
}: {
  expiryTimestamp: any;
  onExpire: () => void;
}) {
  const dispatch = useDispatch();
  const timeStudy = useSelector((state: any) => state.timeStudyReducer);

  useInterval(
    () => {
      if (timeStudy.timer.delay !== DEFAULT_DELAY) {
        dispatch(
          setTimer({
            ...timeStudy.timer,
            delay: DEFAULT_DELAY,
          })
        );
      }
      const secondsValue = Time.getSecondsFromExpiry(
        timeStudy.timer.expiryTime,
        true
      );
      dispatch(
        setTimer({
          ...timeStudy.timer,
          seconds: secondsValue,
          secondsPassed: timeStudy.timer.secondsInitial - secondsValue,
        })
      );
      if (secondsValue <= 0) {
        handleExpire();
      }
    },
    timeStudy.isRunning ? timeStudy.timer.delay : null,
    timeStudy.interval
  );

  function handleExpire() {
    Validate.onExpire(onExpire) && onExpire();
    dispatch(setIsRunning(false));
    setTimer({
      ...timeStudy.timer,
      delay: null,
    });
    killInterval();
  }

  function pause() {
    dispatch(setIsRunning(false));
    killInterval();
  }

  function reset() {
    dispatch(
      setTimer({
        ...timeStudy.timer,
        delay: 1000,
        didStart: true,
        secondsPassed: 0,
        seconds: 0,
        expiryTime: null,
      })
    );
    dispatch(setIsRunning(false));
    dispatch(setSettingsTimer(false));

    killInterval();
  }

  function restart(newExpiryTimestamp: any, newAutoStart = false) {
    dispatch(
      setTimer({
        ...timeStudy.timer,
        seconds: Time.getSecondsFromExpiry(newExpiryTimestamp, false),
        expiryTime: newExpiryTimestamp,
        delay: getDelayFromExpiryTimestamp(newExpiryTimestamp),
        didStart: newAutoStart,
      })
    );

    dispatch(setIsRunning(newAutoStart));
    dispatch(setSettingsTimer(newAutoStart));
  }

  function resume() {
    const time = new Date();
    time.setMilliseconds(
      time.getMilliseconds() + timeStudy.timer?.seconds * 1000
    );
    restart(time, true);
    dispatch(setSettingsTimer(true));
  }

  function start(seconds: number) {
    const time = new Date();
    time.setMilliseconds(time.getMilliseconds() + seconds * 1000);
    restart(time, true);
    dispatch(setSettingsTimer(true));
  }

  function killInterval() {
    clearInterval(timeStudy.interval);
    dispatch(setIntervalId(null));
  }

  return {
    ...Time.getTimeFromSeconds(timeStudy.timer.seconds),
    secondsPassed: Time.getTimeFromSeconds(timeStudy.timer.secondsPassed),
    start,
    pause,
    resume,
    reset,
    isRunning: timeStudy.isRunning,
  };
}
