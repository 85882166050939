import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";

import {
  Button,
  Paper,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { ResetPasswordFormType, SignInType } from "../../types/auth";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import * as yup from "../../vendor/yup";
import { useHistory } from "react-router-dom";
import resetPasswordHttp from "../../utils/http/resetPassword-http";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;
const SchemaValidation = yup.object({
  password: yup.string().nullable().label("Senha").required().min(8).max(255),
  password_confirmation: yup
    .string()
    .label("Confirmação de senha")
    .required()
    .oneOf([yup.ref("password"), null], "A senha não é igual"),
});

function ResetPasswordForm() {
  const history = useHistory();
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorResponse, setErrorResponse] = useState<string[]>([]);
  const queryParams = useMemo(() => {
    return new URLSearchParams(history.location.search.substr(1));
  }, [history]);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<ResetPasswordFormType>({
    resolver: yupResolver(SchemaValidation),
    defaultValues: {
      email: "",
      token: "",
      password: "",
      password_confirmation: "",
    },
  });

  useEffect(() => {
    reset({
      token: queryParams.get("token"),
      email: queryParams.get("email"),
    });
  }, [reset, queryParams]);

  const onSubmit: SubmitHandler<SignInType> = async (formData) => {
    setLoading(true);
    try {
      await resetPasswordHttp.create(formData);
      snackbar.enqueueSnackbar("Senha alterada com sucesso", {
        variant: "success",
      });
      history.push("/auth/sign-in");
    } catch (error) {
      if (error.response.status === 422) {
        setErrorResponse(error.response.data.errors.email);
      } else {
        snackbar.enqueueSnackbar("Não é possível realizar o login", {
          variant: "error",
        });
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <Wrapper>
      <Helmet title="Reset Password" />

      <Typography component="h1" variant="h4" align="center" gutterBottom>
        Mudar Senha
      </Typography>
      <Typography component="h2" variant="body1" align="center">
        Digite sua nova senha
      </Typography>

      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        {errorResponse.length > 0 && (
          <Alert mt={2} mb={1} severity="warning">
            {errorResponse.map((row) => row)}
          </Alert>
        )}

        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              type={"password"}
              label="Senha"
              error={errors.password !== undefined}
              helperText={errors.password?.message}
              fullWidth
              my={3}
            />
          )}
        />
        <Controller
          name="password_confirmation"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              type="password"
              label="Confirmar Senha"
              error={errors.password_confirmation !== undefined}
              helperText={errors.password_confirmation?.message}
              fullWidth
              my={3}
            />
          )}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={loading}
        >
          Mudar senha
        </Button>
      </form>
    </Wrapper>
  );
}

export default ResetPasswordForm;
