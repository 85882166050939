import * as React from "react";
import ImageUploader from "react-images-upload";
import { useState } from "react";
import userHttp from "../../../../utils/http/user-http";
import { LinearProgress } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { setUser } from "../../../../redux/actions/authActions";

interface UploadProps {
  setAvatar: (value: string) => void;
}
const Upload: React.FC<UploadProps> = ({ setAvatar }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const onDrop = async (picture: any) => {
    setLoading(true);
    const image = picture[0];
    if (!image) {
      setLoading(false);
      return;
    }
    const fd = new FormData();
    fd.append("image", image, image.name);
    try {
      const { data } = await userHttp.createRelationship(
        "upload-image",
        fd,
        {}
      );
      dispatch(setUser(data));
      if (data.avatar) {
        setAvatar(data.avatar);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return loading ? (
    <LinearProgress variant="indeterminate" />
  ) : (
    <ImageUploader
      withIcon={true}
      buttonText="Trocar Imagem"
      onChange={onDrop}
      imgExtension={[".jpg", ".gif", ".png"]}
      maxFileSize={5242880}
      label={"Tamanho máximo 5mb, permitidos jpg|png|gif"}
      singleImage={true}
    />
  );
};

export default Upload;
