import React, { FC } from "react";
import styled from "styled-components/macro";

import { CircularProgress, CircularProgressProps } from "@material-ui/core";

const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`;

interface LoaderType extends CircularProgressProps {
  size?: number;
}

const Loader: FC<LoaderType> = ({ size = 40, ...other }) => {
  return (
    <Root>
      <CircularProgress size={size} color="secondary" {...other} />
    </Root>
  );
};

export default Loader;
