import * as types from "../../constants";

export type TimeType = {
  passedSeconds?: number;
  prevTime?: number;
  seconds?: number;
};

export type TimeStudyType = {
  time?: TimeType | undefined;
  isRunning: boolean;
  interval: NodeJS.Timer | undefined;
  isOpen: boolean;
};

const INITIAL = {
  isRunning: false,
  time: {
    passedSeconds: 0,
    prevTime: 0,
    seconds: 0,
  },
  interval: undefined,
  isOpen: false,
};

export default function reducer(
  state = INITIAL,
  actions: TimeStudyType & { type: string }
): TimeStudyType {
  switch (actions.type) {
    case types.TIME_ASSESSMENT_IS_OPEN:
      return {
        ...state,
        isOpen: actions.isOpen,
      };

    case types.TIME_ASSESSMENT_IS_RUNNING:
      return {
        ...state,
        isRunning: actions.isRunning,
      };
    case types.TIME_ASSESSMENT_TIME:
      return {
        ...state,
        time: {
          ...state.time,
          ...actions.time,
        },
      };
    case types.TIME_ASSESSMENT_INTERVAL_ID:
      return {
        ...state,
        interval: actions.interval,
      };

    case types.TIME_ASSESSMENT_RESET: {
      if (state.interval) {
        clearInterval(state.interval);
      }
      return INITIAL;
    }
    case types.AUTH_SIGN_OUT: {
      if (state.interval) {
        clearInterval(state.interval);
      }
      return INITIAL;
    }

    default:
      return state;
  }
}
