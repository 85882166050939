import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import Form from "./components/Form";
import { useParams } from "react-router";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Divider = styled(MuiDivider)(spacing);

function ActivityForm() {
  const { id } = useParams<{ id: string }>();
  return (
    <React.Fragment>
      <Helmet title="Atividade" />

      <Typography variant="h3" gutterBottom display="inline">
        Atividade de Estudo
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/">
          Atividade
        </Link>
        <Typography>{id ? "Editar" : "Criar"}</Typography>
      </Breadcrumbs>
      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Form id={id} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default ActivityForm;
