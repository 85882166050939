import * as React from "react";
import { spacing } from "@material-ui/system";
import { Button, styled } from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppStateType } from "../../redux/reducers";

const Alert = styled(MuiAlert)(spacing);

export const ProfileAlert = () => {
  const history = useHistory();
  const auth = useSelector((state: AppStateType) => state.authReducer);

  const handleClick = () => {
    history.push("/conta");
  };
  if (!auth.user?.uf && auth.user?.active) {
    return (
      <Alert
        action={
          <Button color="inherit" size="small" onClick={handleClick}>
            CLIQUE AQUI
          </Button>
        }
        mb={4}
        severity="warning"
      >
        Complete seus dados, para continuar usando a plataforma.
      </Alert>
    );
  }
  return null;
};
