import React, { FC } from "react";
import { Chip as MuiChip } from "@material-ui/core";
import "react-confirm-alert/src/react-confirm-alert.css";
import styled from "styled-components/macro";
import { spacing, SpacingProps } from "@material-ui/system";

interface MuiChipSpacingType extends SpacingProps {
  cor?: string;
}

const Chip = styled(MuiChip)<MuiChipSpacingType>`
  ${spacing};
  background: ${(props) => props.cor && props.cor};
  color: ${(props) => props.cor && props.theme.palette.common.white};
  font-weight: bold;
`;

interface ChipsProp extends SpacingProps {
  label: string;
  color: string;
  size: "small" | "medium";
}

const Chips: FC<ChipsProp> = ({ label, color }) => {
  return <Chip size="small" mr={1} mb={1} label={label} cor={color} />;
};

export default Chips;
