import React, { FC } from "react";
import Alert, { AlertProps } from "@material-ui/lab/Alert";

interface SimpleAlertType extends AlertProps {
  message: string;
}

const SimpleAlert: FC<SimpleAlertType> = ({ message, ...others }) => {
  return <Alert {...others}>{message}</Alert>;
};

export default SimpleAlert;
