import React, { FC } from "react";
import "react-dragula/dist/dragula.css";
import { Grid } from "@material-ui/core";

import { blue, red } from "@material-ui/core/colors";

import { ReviewType } from "../../../types/reviews";

import ReviewCard from "../../../components/ReviewCard";
import WarningPage from "../../../components/WarningPage";

interface EmAbertoType {
  reviewsToday: ReviewType[];
  reviewsPast: ReviewType[];
  getReviews: () => void;
}

const EmAberto: FC<EmAbertoType> = ({
  reviewsToday,
  reviewsPast,
  getReviews,
}) => {
  if (reviewsToday.length === 0 && reviewsPast.length === 0) {
    return (
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <WarningPage
            title={"Ufa!! Nenhuma revisão."}
            img="/static/img/reviews.svg"
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} lg={4} xl={4}>
        <ReviewCard
          title={"Hoje"}
          description={"Suas revisões de hoje"}
          color={blue[600]}
          reviews={reviewsToday}
          onGetReviews={getReviews}
        />
      </Grid>
      <Grid item xs={12} lg={4} xl={4}>
        <ReviewCard
          title={"Atrasadas"}
          description={"Revisões Atrasadas"}
          color={red[600]}
          reviews={reviewsPast}
          onGetReviews={getReviews}
        />
      </Grid>
      <Grid item xs={12} lg={4} xl={4}>
        {/*<Lane*/}
        {/*  title="Revisão Concluídas"*/}
        {/*  description="Suas revisões concluídas hoje"*/}
        {/*></Lane>*/}
      </Grid>
    </Grid>
  );
};

export default EmAberto;
