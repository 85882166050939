export const REVISAOID = 4;

export const weekDays = [
  {
    cod: "1",
    codLabel: "Seg",
    weeky: "Segunda",
  },
  {
    cod: "2",
    codLabel: "Ter",
    weeky: "Terça",
  },
  {
    cod: "3",
    codLabel: "Qua",
    weeky: "Quarta",
  },
  {
    cod: "4",
    codLabel: "Qui",
    weeky: "Quinta",
  },
  {
    cod: "5",
    codLabel: "Sex",
    weeky: "Sexta",
  },
  {
    cod: "6",
    codLabel: "Sab",
    weeky: "Sábado",
  },
  {
    cod: "7",
    codLabel: "Dom",
    weeky: "Domingo",
  },
];
