export const secToTime = (seg: number, type = 1): string => {
  const hora = Math.trunc(seg / 3600)
    .toString()
    .padStart(2, "0");
  const minuto = Math.floor((seg % 3600) / 60)
    .toString()
    .padStart(2, "0");
  const segundo = ((seg % 3600) % 60).toString().padStart(2, "0");

  switch (type) {
    case 1:
      return `${hora}h ${minuto}m `;
    case 2:
      return `${hora}h ${minuto}m ${segundo}s`;
    case 3:
      return `${hora}:${minuto}:${segundo}`;
    case 4:
      return `${hora}h`;
    case 5:
      return `${hora}:${minuto}`;
    case 6:
      return hora;
    default:
      return "";
  }
};

export const timeToSec = (h: string, m: string, s: string) => {
  let hora = parseInt(h, 10) * 3600;
  let minuto = parseInt(m, 10) * 60;
  if (isNaN(hora)) {
    hora = 3600;
  }
  if (isNaN(minuto)) {
    minuto = 0;
  }

  const seconds = hora + minuto + parseInt(s, 10);

  return seconds;
};
