import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppStateType } from "../redux/reducers";

interface AuthGuardType {
  children: React.ReactNode;
  path?: string;
}

// For routes that can only be accessed by authenticated users
function AuthGuard({ children, path }: AuthGuardType) {
  const auth = useSelector((state: AppStateType) => state.authReducer);
  const plan = useSelector((state: AppStateType) => state.planReducer);

  if (!auth.user) {
    return <Redirect to="/auth/sign-in" />;
  }
  if (!auth.user.migration && path !== "/migration") {
    return <Redirect to="/migration" />;
  }

  if (
    !auth.user.active &&
    path !== "/billing" &&
    path !== "/billing/success" &&
    path !== "/migration"
  ) {
    return <Redirect to={"/billing"} />;
  }

  if (auth.user.active && path === "/billing") {
    return <Redirect to={"/billing/manage"} />;
  }

  if (
    !plan.active.id &&
    path !== "/planos/selecionar" &&
    auth.user.active &&
    path !== "/migration" &&
    path !== "/register-completed"
  ) {
    return <Redirect to="/planos/selecionar" />;
  }

  return children;
}

export default AuthGuard;
