import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components/macro";

import {
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  FormControl as MuiFormControl,
  Grid,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";

import { spacing, SpacingProps } from "@material-ui/system";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { secToTime, timeToSec } from "../../../utils/Times";
import { format, isDate, parse, parseISO } from "date-fns";

import InputMask from "react-input-mask";
import { AssessmentType } from "../../../types/assessments";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../redux/reducers";
import { useSnackbar } from "notistack";
import assessmentsHttp from "../../../utils/http/assessments-http";
import * as yup from "../../../vendor/yup";
import { useHistory } from "react-router-dom";
import { ResponseType } from "../../../types/response";

import update from "immutability-helper";
import { resetAssessmentTime } from "../../../redux/actions/timeAssessmentActions";

const Card = styled(MuiCard)(spacing);

const FormControl = styled(MuiFormControl)<{ my?: number }>(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface ButtonPropstype extends SpacingProps {
  component?: string;
}
const Button = styled(MuiButton)<ButtonPropstype>(spacing);

interface FormProps {
  id?: string;
}

interface SubjectType {
  id?: string;
  subject_id: string;
  title: string;
  acertos: number;
  erros: number;
  branco: number;
}

const SchemaValidation = yup.object({
  start: yup
    .date()
    .nullable()
    .required("Obrigatório")
    .transform(function (value: string, originalValue: string) {
      const date = parse(originalValue, "dd/MM/yyyy", new Date());
      return isDate(date) ? date : new Date("");
    })
    .typeError("Data inválida"),
  title: yup.string().required("Obrigatório"),
  time: yup
    .string()
    .required("Obrigatório")
    .min(5, "Inválido")
    .transform((value, originalValue) => {
      let hour = parseInt(originalValue.substr("0", "2"), 10);
      let minute = parseInt(originalValue.substr("3", "2"), 10);
      let seg = parseInt(originalValue.substr("6", "6"), 10);

      hour = Number.isNaN(hour) ? 0 : hour;
      minute = Number.isNaN(minute) ? 0 : minute;
      seg = Number.isNaN(seg) ? 0 : seg;

      if (hour > 23) {
        return "f";
      }
      if (minute > 59) {
        return "f";
      }
      if (seg > 59) {
        return "f";
      }
      return (
        hour.toString().padStart(2, "0") +
        ":" +
        minute.toString().padStart(2, "0") +
        ":" +
        seg.toString().padStart(2, "0")
      );
    }),
  subjects: yup.array().of(
    yup.object().shape({
      acertos: yup
        .number()
        .min(0)
        .transform(function (value) {
          return Number.isNaN(value) ? 0 : value;
        }),
      erros: yup
        .number()
        .min(0)
        .transform(function (value) {
          return Number.isNaN(value) ? 0 : value;
        }),
      branco: yup
        .number()
        .min(0)
        .transform(function (value) {
          return Number.isNaN(value) ? 0 : value;
        }),
    })
  ),
});

function Form({ id }: FormProps) {
  const history = useHistory();
  const snackbar = useSnackbar();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [subjects, setSubjects] = useState<SubjectType[]>([]);
  const [data, setData] = useState<any>([]);
  const plan = useSelector((state: AppStateType) => state.planReducer);
  const timeStudy = useSelector((state: any) => state.timeAssessmentReducer);
  const {
    handleSubmit,
    control,
    register,
    reset,
    getValues,
    formState: { errors },
  } = useForm<AssessmentType>({
    resolver: yupResolver(SchemaValidation),
    defaultValues: {
      start: format(new Date(), "dd/MM/yyyy"),
      time: secToTime(timeStudy.time.seconds, 3),
      assessment_subjects: [],
    },
  });

  const subjectsList = (id
    ? getValues().assessment_subjects
    : subjects) as SubjectType[];

  useEffect(() => {
    if (plan.active.subjects !== undefined) {
      const sub = plan.active.subjects;

      const subFormat = sub.map((row) => {
        return {
          subject_id: row.id,
          title: row.title,
          acertos: 0,
          branco: 0,
          erros: 0,
        };
      });

      setSubjects(subFormat);
    }
  }, [plan.active]); //Incluindo os subjects

  useEffect(() => {
    const subjs = data.assessment_subjects;
    if (subjs !== undefined && subjs.length > 0 && id) {
      const newSubjects = update(subjects, {
        $apply: (item: SubjectType[]) => {
          return item.map((row, index) => {
            const indx = subjs.findIndex(
              (el: SubjectType) => el.subject_id === row.subject_id
            );
            if (indx === -1) {
              return row;
            }
            return subjs[indx];
          });
        },
      });

      const newData = update(data, {
        assessment_subjects: { $set: newSubjects },
      });

      reset(newData);
    }
  }, [id, data, reset, subjects]);

  useEffect(() => {
    if (!id) {
      return;
    }
    let isSubscribed = true;
    setLoading(true);
    (async () => {
      try {
        const { data } = await assessmentsHttp.getResource<ResponseType<any>>(
          id,
          "show"
        );
        if (isSubscribed) {
          const updateForm = data.data;
          const date = parseISO(updateForm["start"]);
          updateForm["start"] = format(date, "dd/MM/yyyy");
          updateForm["time"] = secToTime(updateForm["duration"], 3);
          setData(updateForm);
        }
      } catch (error) {
        console.error(error);
        snackbar.enqueueSnackbar("Não foi possível carregar as informações", {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    })();
    return () => {
      isSubscribed = false;
    };
  }, [id, snackbar]);

  const onSubmit: SubmitHandler<any> = async (formData) => {
    if (plan.active.id) {
      formData["duration"] = timeToSec(
        formData.time.substr(0, 2),
        formData.time.substr(3, 2),
        formData.time.substr(6, 2)
      );

      try {
        const http = !id
          ? assessmentsHttp.createRelationship(plan?.active?.id, formData)
          : assessmentsHttp.update(id, formData);
        const { data } = await http;
        snackbar.enqueueSnackbar("Salvo com sucesso", {
          variant: "success",
        });
        dispatch(resetAssessmentTime());

        history.push(`/assessment/${data.data.id}/show`);
      } catch (error) {
        console.log(error);
        snackbar.enqueueSnackbar("Não é possível salvar a atividade", {
          variant: "error",
        });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card mb={6}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Informações
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Controller
                name="start"
                control={control}
                render={({ field }) => (
                  <InputMask {...field} mask={"99/99/9999"}>
                    {(inputProps: any) => (
                      <TextField
                        {...inputProps}
                        id="start-id"
                        label="Data"
                        variant="outlined"
                        fullWidth
                        inputProps={{ inputMode: "numeric" }}
                        error={errors.start !== undefined}
                        helperText={errors.start?.message}
                        InputLabelProps={{ shrink: true }}
                        my={1}
                      />
                    )}
                  </InputMask>
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="time"
                control={control}
                render={({ field }) => (
                  <InputMask {...field} mask={"99:99:99"}>
                    {(inputProps: any) => (
                      <TextField
                        {...inputProps}
                        margin={"normal"}
                        id="time-id"
                        label="Tempo de Estudo"
                        variant="outlined"
                        fullWidth
                        inputProps={{ inputMode: "numeric" }}
                        error={errors.time !== undefined}
                        helperText={errors.time?.message}
                        InputLabelProps={{ shrink: true }}
                        my={1}
                      />
                    )}
                  </InputMask>
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="title"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin={"normal"}
                    id="title"
                    label="Simulado"
                    variant="outlined"
                    fullWidth
                    error={errors.title !== undefined}
                    helperText={errors.title?.message}
                    InputLabelProps={{ shrink: true }}
                    my={1}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth my={1} variant="outlined">
                <Controller
                  name="notes"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      margin={"normal"}
                      id="note"
                      label="Anotação"
                      variant="outlined"
                      fullWidth
                      multiline={true}
                      rows={3}
                      rowsMax={4}
                      error={errors.notes !== undefined}
                      helperText={errors.notes?.message}
                      InputLabelProps={{ shrink: true }}
                      my={1}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card mb={6}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Questões
          </Typography>

          <Grid container spacing={4}>
            {subjectsList.map((row, index) => (
              <Fragment key={index}>
                <input
                  {...register(
                    `assessment_subjects.${index}.subject_id` as const
                  )}
                  value={row.subject_id}
                  type={"hidden"}
                />
                <Grid item xs={3}>
                  <TextField
                    id="materia"
                    label="Matéria"
                    variant="outlined"
                    defaultValue={row.title}
                    fullWidth
                    disabled
                    margin={"dense"}
                    my={1}
                  />
                </Grid>
                <Grid item md={3}>
                  <Controller
                    name={`assessment_subjects.${index}.acertos` as const}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        margin={"dense"}
                        id="acertos"
                        label="Acertos"
                        variant="outlined"
                        fullWidth
                        inputProps={{ inputMode: "numeric" }}
                        //error={errors.title !== undefined}
                        //helperText={errors.title?.message}
                        InputLabelProps={{ shrink: true }}
                        my={1}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={3}>
                  <Controller
                    name={`assessment_subjects.${index}.erros` as const}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        margin={"dense"}
                        id="erros"
                        label="Erros"
                        variant="outlined"
                        fullWidth
                        inputProps={{ inputMode: "numeric" }}
                        //error={errors.title !== undefined}
                        //helperText={errors.title?.message}
                        InputLabelProps={{ shrink: true }}
                        my={1}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={3}>
                  <Controller
                    name={`assessment_subjects.${index}.branco` as const}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        margin={"dense"}
                        id="branco"
                        label="Branco"
                        variant="outlined"
                        fullWidth
                        inputProps={{ inputMode: "numeric" }}
                        //error={errors.title !== undefined}
                        //helperText={errors.title?.message}
                        InputLabelProps={{ shrink: true }}
                        my={1}
                      />
                    )}
                  />
                </Grid>
              </Fragment>
            ))}
          </Grid>

          <Button
            variant="contained"
            type={"submit"}
            color="primary"
            mt={3}
            disabled={loading}
          >
            Salvar Simulado
          </Button>
        </CardContent>
      </Card>
    </form>
  );
}

export default Form;
