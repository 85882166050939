import React, { FC } from "react";
import TablePagination from "@material-ui/core/TablePagination";
import { StateFilterProps } from "../../Hooks/useFilter";

interface PaginacaoProps {
  filter: StateFilterProps;
  dispatch: (value: any) => void;
  getActivities: () => void;
  total: number;
}

const Paginacao: FC<PaginacaoProps> = ({
  filter,
  dispatch,
  getActivities,
  total,
}) => {
  // const [action, setAction] = useState<boolean>(false);
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    dispatch({ type: "page", payload: newPage + 1 });
    //setAction(true);
  };

  // useEffect(() => {
  //   if (action) {
  //     getActivities();
  //     setAction(false);
  //   }
  // }, [action, filter.pagination, getActivities]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch({ type: "perPage", payload: parseInt(event.target.value, 10) });
    dispatch({ type: "page", payload: 1 });
    //setAction(true);
  };

  return (
    <TablePagination
      component="div"
      count={total}
      page={filter.pagination.page - 1}
      onChangePage={handleChangePage}
      rowsPerPage={filter.pagination.per_page}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  );
};

export default Paginacao;
