import * as React from "react";
import styled from "styled-components/macro";
import {
  Container,
  Grid as MuiGrid,
  Typography as MuiTypography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import Features from "./components/Features";

import { Helmet } from "react-helmet-async";

import CardPlanSimple from "./components/CardPlanSimple";

const Wrapper = styled.div`
  padding-top: 3.5rem;
  position: relative;
  text-align: center;
  overflow: hidden;
`;

const Typography = styled(MuiTypography)(spacing);

const Grid = styled(MuiGrid)`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Title = styled(Typography)`
  opacity: 0.9;
  line-height: 1.4;
  font-size: 1.75rem;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};

  ${(props) => props.theme.breakpoints.up("sm")} {
    font-size: 2rem;
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 2.5rem;
  }

  span {
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

const Subtitle = styled(Typography)`
  font-size: ${(props) => props.theme.typography.h6.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  font-family: ${(props) => props.theme.typography.fontFamily};
  margin: ${(props) => props.theme.spacing(2)}px 0;
`;
const Assinatura = () => {
  return (
    <Wrapper>
      <Helmet title="Assinatura" />
      <Title>
        Alcance a tão <span>sonhada</span> aprovação.
      </Title>
      <Subtitle>
        Crie, execute e escale seus estudos em uma única plataforma. Estudar
        nunca foi tão fácil!
      </Subtitle>
      <Container>
        <Grid container spacing={6} justify="center" alignItems="center">
          <Grid item xs={12} md={4}>
            <CardPlanSimple
              title={"Plano Anual"}
              content={
                <>
                  <Typography variant="body2" color="textSecondary">
                    Assinatura recorrente anual.
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    Renovação automática
                  </Typography>
                </>
              }
              price={"R$ 12,90"}
            />
          </Grid>
          {/*<Grid item xs={12} md={4}>*/}
          {/*  <CardPlan*/}
          {/*    stripe_id={process.env.REACT_APP_STRIPE_ANUAL_ID}*/}
          {/*    title={"Plano Anual"}*/}
          {/*    content={*/}
          {/*      <>*/}
          {/*        <Typography variant="body2" color="textSecondary">*/}
          {/*          Assinatura recorrente Anual.*/}
          {/*        </Typography>*/}
          {/*        <Typography variant="caption" color="textSecondary">*/}
          {/*          Renovação automática*/}
          {/*        </Typography>*/}
          {/*      </>*/}
          {/*    }*/}
          {/*    price={"R$ 119,90"}*/}
          {/*    discount={"R$ 9,99/mês"}*/}
          {/*  />*/}
          {/*</Grid>*/}
          {/*<Grid item xs={12} md={4}>*/}
          {/*  <CardPlan*/}
          {/*    stripe_id={process.env.REACT_APP_STRIPE_SEMESTRAL_ID}*/}
          {/*    title={"Plano Semestral"}*/}
          {/*    content={*/}
          {/*      <>*/}
          {/*        <Typography variant="body2" color="textSecondary">*/}
          {/*          Assinatura recorrente semestral.*/}
          {/*        </Typography>*/}
          {/*        <Typography variant="caption" color="textSecondary">*/}
          {/*          Renovação automática*/}
          {/*        </Typography>*/}
          {/*      </>*/}
          {/*    }*/}
          {/*    price={"R$ 69,90"}*/}
          {/*    discount={"R$ 11,65/mês"}*/}
          {/*  />*/}
          {/*</Grid>*/}
        </Grid>
      </Container>

      <Features />
    </Wrapper>
  );
};

export default Assinatura;
