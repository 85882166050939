import React from "react";
import styled from "styled-components/macro";

import "react-dragula/dist/dragula.css";

import {
  Box,
  Card as MuiCard,
  CardContent as MuiCardContent,
  IconButton,
  Menu,
  MenuItem,
  Typography as MuiTypography,
} from "@material-ui/core";

import MoreVertIcon from "@material-ui/icons/MoreVert";

import { spacing } from "@material-ui/system";
import Progresso from "./Progresso";
import htmlParser from "html-react-parser";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)<{ pb?: number }>`
  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;

const TaskWrapper = styled(Card)`
  border: 1px solid ${(props) => props.theme.palette.grey[300]};
  margin-bottom: ${(props) => props.theme.spacing(4)}px;
  background-color
`;

const TaskWrapperContent = styled(CardContent)`
  position: relative;
  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;

const TaskBadge = styled.div`
  background: ${(props) => props.color};
  width: 40px;
  height: 6px;
  border-radius: 6px;
  display: inline-block;
  margin-right: ${(props) => props.theme.spacing(2)}px;
`;

const TaskNotifications = styled.div`
  display: flex;
  position: absolute;
  bottom: ${(props) => props.theme.spacing(4)}px;
  right: ${(props) => props.theme.spacing(4)}px;
`;

const Typography = styled(MuiTypography)(spacing);

const TaskDate = styled(Typography)`
  font-size: 10px;
  margin-right: ${(props) => props.theme.spacing(10)}px;
`;

const TaskTitle = styled(Typography)`
  font-weight: 600;
  font-size: 15px;
  margin-right: ${(props) => props.theme.spacing(10)}px;
`;

const TaskSubtitle = styled(Typography)`
  font-size: 12px;
  margin-right: ${(props) => props.theme.spacing(10)}px;
`;

const TaskContent = styled(Box)`
  font-size: 10px;
  margin-top: ${(props) => props.theme.spacing(5)}px;
  margin-right: ${(props) => props.theme.spacing(10)}px;
`;

interface CardConteudoProps {
  date?: string;
  color?: string;
  title: string;
  subtitle?: string;
  content?: React.ReactNode;
  html?: string;
  progresso?: number;
  menu: {
    func: () => void;
    label: string;
  }[];
}

const CardMenuList: React.FC<CardConteudoProps> = ({
  date,
  color,
  title,
  subtitle,
  progresso,
  content,
  html,
  menu,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnClick = (func: any) => {
    handleClose();
    return func();
  };

  const ITEM_HEIGHT = 48;
  return (
    <div className={"dragula-container"} id={"ds"}>
      <TaskWrapper mb={4}>
        <TaskWrapperContent>
          {date && <TaskDate>{date}</TaskDate>}
          {color && <TaskBadge color={color} />}
          <TaskTitle variant="body1">{title}</TaskTitle>
          {subtitle && (
            <TaskSubtitle variant="body1" gutterBottom>
              {subtitle}
            </TaskSubtitle>
          )}
          {progresso !== undefined && (
            <Progresso value={progresso} alt={4} padding={1} />
          )}
          {content && (
            <TaskContent style={{ wordWrap: "break-word" }}>
              {content}
            </TaskContent>
          )}
          {html && (
            <TaskContent style={{ wordWrap: "break-word" }}>
              {htmlParser(html)}
            </TaskContent>
          )}
          <TaskNotifications>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: "20ch",
                },
              }}
            >
              {menu.map((item, index) => (
                <MenuItem
                  key={index}
                  onClick={() => handleOnClick(item.func)}
                  style={{
                    border: "1px solid #e0e0e0",
                  }}
                >
                  {item.label}
                </MenuItem>
              ))}
            </Menu>
          </TaskNotifications>
        </TaskWrapperContent>
      </TaskWrapper>
    </div>
  );
};

export default CardMenuList;
