import React, { FC, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";
import userHttp from "../../../../utils/http/user-http";
import { useSnackbar } from "notistack";
import { secToTime, timeToSec } from "../../../../utils/Times";

interface ConfigureType {
  open: boolean;
  setOpen: (value: boolean) => void;
  onGetMeta: () => void;
}

const Configure: FC<ConfigureType> = ({ open, setOpen, onGetMeta }) => {
  const snackbar = useSnackbar();
  const [time, setTime] = useState<number>(0);
  const [questions, setQuestions] = useState<number>(0);

  useEffect(() => {
    async function getSettings() {
      try {
        const { data } = await userHttp.listRelationship("settings");
        const options = data.data.options;
        const hours = parseInt(secToTime(options.meta_time, 6));
        setTime(hours);
        setQuestions(options.meta_questions);
      } catch (e) {
        console.error(e);
        snackbar.enqueueSnackbar("Não foi possível atualizar!", {
          variant: "error",
        });
      }
    }
    getSettings();
  }, [snackbar]);

  const onSubmit = async () => {
    if (time < 0 || questions < 0) {
      return;
    }
    try {
      await userHttp.createRelationship("settings", {
        meta_time: timeToSec(time.toString(), "0", "0"),
        meta_questions: questions,
      });
      onGetMeta();
      snackbar.enqueueSnackbar("Atualizado com sucesso", {
        variant: "success",
      });
      setOpen(false);
    } catch (error) {
      console.error(error);
      snackbar.enqueueSnackbar("Não foi possível atualizar!", {
        variant: "error",
      });
    }
  };
  return (
    <Dialog open={open}>
      <DialogTitle>Configurar Metas</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Configure sua meta semanal de tempo e questões. Coloque uma meta que
          você possa alcançar!
        </DialogContentText>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <TextField
              value={time}
              autoFocus
              variant={"outlined"}
              margin="dense"
              id="tempo"
              label="Meta tempo Semanal"
              type="number"
              fullWidth
              onChange={(e) => setTime(parseInt(e.target.value))}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              autoFocus
              value={questions}
              variant={"outlined"}
              margin="dense"
              id="questoes"
              label="Meta Questões Semanal"
              type="number"
              fullWidth
              onChange={(e) => setQuestions(parseInt(e.target.value))}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          Cancelar
        </Button>
        <Button onClick={onSubmit} color="primary">
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Configure;
