import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  CancelTokenSource,
} from "axios";
import { serialize } from "object-to-formdata";

export default class HttpResource {
  private cancelList: CancelTokenSource | null = null;

  constructor(protected http: AxiosInstance, protected resource: string) {}

  list<T = any>(options?: { queryParam?: any }): Promise<AxiosResponse<T>> {
    if (this.cancelList) {
      this.cancelList.cancel("list cancelled");
    }
    this.cancelList = axios.CancelToken.source();
    const config: AxiosRequestConfig = {
      cancelToken: this.cancelList.token,
    };

    if (options && options.queryParam) {
      config.params = options.queryParam;
    }
    return this.http.get<T>(this.resource, config);
  }

  listRelationship<T = any>(
    relationship?: string,
    options?: { queryParam?: any }
  ): Promise<AxiosResponse<T>> {
    if (this.cancelList) {
      this.cancelList.cancel("list cancelled");
    }
    this.cancelList = axios.CancelToken.source();
    const config: AxiosRequestConfig = {
      cancelToken: this.cancelList.token,
    };

    if (options && options.queryParam) {
      config.params = options.queryParam;
    }
    return this.http.get<T>(`${this.resource}/${relationship}`, config);
  }

  listRelationshipResource<T = any>(
    relationship?: string,
    resource?: string,
    options?: { queryParam?: any }
  ): Promise<AxiosResponse<T>> {
    const config: AxiosRequestConfig = {
      //cancelToken: this.cancelList.token,
    };

    if (options && options.queryParam) {
      config.params = options.queryParam;
    }
    return this.http.get<T>(
      `${this.resource}/${relationship}/${resource}`,
      config
    );
  }

  get<T = any>(
    id: any,
    options?: { queryParam?: any }
  ): Promise<AxiosResponse<T>> {
    const config: AxiosRequestConfig = {};
    if (options && options.queryParam) {
      config.params = options.queryParam;
    }
    return this.http.get<T>(`${this.resource}/${id}`, config);
  }

  getResource<T = any>(
    id: any,
    resource: string,
    options?: { queryParam?: any }
  ): Promise<AxiosResponse<T>> {
    const config: AxiosRequestConfig = {};
    if (options && options.queryParam) {
      config.params = options.queryParam;
    }
    return this.http.get<T>(`${this.resource}/${id}/${resource}`, config);
  }

  create<T = any>(data: any): Promise<AxiosResponse<T>> {
    let sendData = this.makeSendData(data);
    return this.http.post<T>(this.resource, sendData);
  }

  createRelationship<T = any>(
    relationship: any,
    data: any,
    config?: any
  ): Promise<AxiosResponse<T>> {
    let sendData = this.makeSendData(data);
    return this.http.post<T>(
      `${this.resource}/${relationship}`,
      sendData,
      config
    );
  }

  update<T = any>(
    id: any,
    data: any,
    options?: { http?: { usePost: boolean }; config?: AxiosRequestConfig }
  ): Promise<AxiosResponse<T>> {
    let sendData = data;
    if (this.containsFile(data)) {
      sendData = this.getFormData(data);
    }
    // Quando o objeto tiver a possibilidade de ser vazio
    const { http, config } = (options || {}) as any;
    return !options || !http || !http.usePost
      ? this.http.patch<T>(`${this.resource}/${id}`, sendData, config)
      : this.http.post<T>(`${this.resource}/${id}`, sendData, config);
  }

  delete<T = any>(id: string): Promise<AxiosResponse<T>> {
    return this.http.delete<T>(`${this.resource}/${id}`);
  }

  deleteResource<T = any>(
    id: string,
    resource: string,
    options?: { config?: AxiosRequestConfig }
  ): Promise<AxiosResponse<T>> {
    const { config } = (options || {}) as any;
    return this.http.delete<T>(`${this.resource}/${id}/${resource}`, config);
  }
  private getFormData(data: any) {
    // const formData = new FormData();
    // Object
    //     .keys(data)
    //     .forEach(key => {
    //         let value = data[key];
    //         if (typeof value === "undefined") {
    //             return;
    //         }
    //         if (typeof value === "boolean") {
    //             value = value ? 1 : 0;
    //         }
    //         if(value instanceof Array){
    //             value.forEach(v => formData.append(`${key}[]`, v))
    //             return;
    //         }
    //         formData.append(key, value)
    //     });
    return serialize(data, { booleansAsIntegers: true });
  }

  private makeSendData(data: any) {
    return this.containsFile(data) ? this.getFormData(data) : data;
  }

  private containsFile(data: any) {
    return Object.values(data).filter((el) => el instanceof File).length !== 0;
  }
}
