import React, { FC, useContext } from "react";
import styled from "styled-components/macro";
import {
  Card as MuiCard,
  CardContent,
  Grid,
  TextField as MuiTextField,
  Box,
} from "@material-ui/core";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import * as yup from "../../../../vendor/yup";
import { spacing } from "@material-ui/system";
import ButtonIcon from "../../../../components/ButtonIcon";
import userHttp from "../../../../utils/http/user-http";
import { useSnackbar } from "notistack";
import LoadingContext from "../../../../components/Loading/LoadingContext";
import { PasswordType } from "../../../../types/user";
const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface FormProps {
  edit?: boolean;
}

const SchemaValidation = yup.object({
  password: yup.string().nullable().label("Senha").required().min(8).max(255),
  password_current: yup.string().label("Senha atual").required().max(255),
  password_confirmation: yup
    .string()
    .label("Confirmação de senha")
    .required()
    .oneOf([yup.ref("password"), null], "A senha não é igual"),
});

const Password: FC<FormProps> = ({ edit }) => {
  const snackbar = useSnackbar();
  const loading = useContext(LoadingContext);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<PasswordType>({
    resolver: yupResolver(SchemaValidation),
    defaultValues: {
      password: "",
      password_current: "",
      password_confirmation: "",
    },
  });

  const onSubmit: SubmitHandler<PasswordType> = async (formData) => {
    try {
      await userHttp.createRelationship("change-password", formData);
      snackbar.enqueueSnackbar("Atualizado com Sucesso", {
        variant: "success",
      });
    } catch (error) {
      console.log(error);
      snackbar.enqueueSnackbar("Verifique se a senha atual está correta", {
        variant: "error",
      });
    }
  };

  return (
    <Card mb={6}>
      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Controller
                name="password_current"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="passwordCurrent"
                    label="Senha Atual"
                    variant="outlined"
                    fullWidth
                    my={2}
                    error={errors.password_current !== undefined}
                    helperText={errors.password_current?.message}
                    type={"password"}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />

              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="password"
                    label="Senha"
                    variant="outlined"
                    type={"password"}
                    fullWidth
                    my={2}
                    error={errors.password !== undefined}
                    helperText={errors.password?.message}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />

              <Grid container spacing={4}>
                <Grid item md={12}>
                  <Controller
                    name="password_confirmation"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="passwordConfirm"
                        label="Confirmar Senha"
                        type={"password"}
                        variant="outlined"
                        fullWidth
                        my={2}
                        error={errors.password_confirmation !== undefined}
                        helperText={errors.password_confirmation?.message}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box style={{ marginTop: "10px" }}>
            <ButtonIcon
              title={"Atualizar"}
              titleTootip={"Atualizar senha"}
              icon={"save"}
              type={"submit"}
              disabled={loading}
            />
          </Box>
        </form>
      </CardContent>
    </Card>
  );
};

export default Password;
