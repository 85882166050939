import { useHistory } from "react-router-dom";
import { format, parse } from "date-fns";
import { Reducer, useCallback, useReducer } from "react";
import * as yup from "../../../vendor/yup";

export interface Pagination {
  page: number;
  total?: number;
  per_page: number;
}

export interface StateFilterProps {
  subject?: string | null;
  topic?: string | null;
  types?: Array<number> | undefined;
  start?: Date | null;
  end?: Date | null;
  pagination: Pagination;
}

type ActionType =
  | { type: "subject"; payload: string }
  | { type: "topic"; payload: string }
  | { type: "clearTopic" }
  | { type: "reset" }
  | { type: "types"; payload: Array<number> | undefined }
  | { type: "start"; payload: Date | null }
  | { type: "end"; payload: Date | null }
  | { type: "page"; payload: number }
  | { type: "perPage"; payload: number };

const filterReducer = (state: StateFilterProps, action: ActionType) => {
  switch (action.type) {
    case "subject":
      return {
        ...state,
        subject: action.payload,
      };
    case "topic":
      return {
        ...state,
        topic: action.payload,
      };
    case "clearTopic":
      return {
        ...state,
        topic: null,
      };
    case "types":
      return {
        ...state,
        types: action.payload,
      };
    case "start":
      return {
        ...state,
        start: action.payload,
      };
    case "end":
      return {
        ...state,
        end: action.payload,
      };
    case "page":
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.payload,
        },
      };
    case "perPage":
      return {
        ...state,
        pagination: {
          ...state.pagination,
          per_page: action.payload,
        },
      };

    case "reset":
      return {
        subject: null,
        topic: null,
        types: [],
        start: null,
        end: null,
        pagination: {
          page: 1,
          per_page: 10,
        },
      };
  }
  return state;
};
const rowsPerPage = 10;
const rowsPerPageOptions = [10, 25, 50, 100];
const validation = yup.object().shape({
  subject: yup
    .string()
    .transform((value) => (!value ? undefined : value))
    .default(""),
  topic: yup
    .string()
    .transform((value) => (!value ? undefined : value))
    .default(""),
  types: yup.mixed().transform(function (value) {
    const newValue: number[] = [];
    value.forEach((row: any) => {
      if (!isNaN(row)) {
        newValue.push(row);
      }
    });
    return newValue;
  }),
  start: yup
    .mixed()
    .nullable()
    .transform(function (value: string, originalValue: string) {
      const expr = /^(19|20)\d\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])$/;
      if (expr.test(originalValue)) {
        return parse(originalValue, "yyyy-MM-dd", new Date());
      }
      return null;
    }),
  end: yup
    .mixed()
    .nullable()
    .transform(function (value: string, originalValue: string) {
      const expr = /^(19|20)\d\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])$/;
      if (expr.test(originalValue)) {
        return parse(originalValue, "yyyy-MM-dd", new Date());
      }
      return null;
    }),
  pagination: yup.object().shape({
    page: yup
      .number()
      .transform((value) =>
        isNaN(value) || parseInt(value) < 1 ? undefined : value
      )
      .default(1),
    per_page: yup
      .number()
      .oneOf(rowsPerPageOptions)
      .transform((value) => (isNaN(value) ? undefined : value))
      .default(rowsPerPage),
  }),
});

const useFilter = () => {
  const history = useHistory();
  const initialState = getStateFromURL();

  const [filter, dispatch] = useReducer<Reducer<StateFilterProps, ActionType>>(
    filterReducer,
    initialState
  );

  function getStateFromURL() {
    const queryParams = new URLSearchParams(history.location.search.substr(1));
    return validation.cast({
      subject: queryParams.get("subject"),
      topic: queryParams.get("topic"),
      types: queryParams.get("types")?.split(",").map(Number),
      start: queryParams.get("start"),
      end: queryParams.get("end"),
      pagination: {
        page: queryParams.get("page"),
        per_page: queryParams.get("per_page"),
      },
    });
  }

  const formatSearchParams = useCallback(() => {
    return {
      ...(filter.subject && { subject: filter.subject }),
      ...(filter.topic && { topic: filter.topic }),
      ...(filter.types &&
        filter.types.length > 0 && {
          types: filter.types.join(","),
        }),
      ...(filter.start &&
        !isNaN(filter.start.getTime()) && {
          start: format(filter.start, "yyyy-MM-dd"),
        }),
      ...(filter.end &&
        !isNaN(filter.end.getTime()) && {
          end: format(filter.end, "yyyy-MM-dd"),
        }),
      ...(filter.pagination.page !== 1 && { page: filter.pagination.page }),
      ...(filter.pagination.per_page && {
        per_page: filter.pagination.per_page,
      }),
    };
  }, [filter]);

  const pushHistory = useCallback(() => {
    const newLocation = {
      pathname: history.location.pathname,
      search: "?" + new URLSearchParams(formatSearchParams() as any),
    };
    history.push(newLocation);
  }, [formatSearchParams, history]);

  return {
    formatSearchParams,
    pushHistory,
    filter,
    dispatch,
  };
};

export default useFilter;
