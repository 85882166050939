import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import { ResponseType } from "../../types/response";
import { useSnackbar } from "notistack";
import { AppStateType } from "../../redux/reducers";
import { useSelector } from "react-redux";
import Table from "./Components/Table";
import { confirmAlert } from "react-confirm-alert";
import ButtonIcon from "../../components/ButtonIcon";
import assessmentsHttp from "../../utils/http/assessments-http";
import { AssessmentType } from "../../types/assessments";
import { useHistory } from "react-router-dom";
import Timer from "./Components/Timer";
import WarningPage from "../../components/WarningPage";
import Loader from "../../components/Loader";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function Simulados() {
  const snackbar = useSnackbar();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [assessments, setAssessments] = useState<AssessmentType[]>([]);
  const [loading, setLoaging] = useState<boolean>(false);
  const [openFullScreenDialog, setOpenFullScreenDialog] = useState<boolean>(
    false
  );
  const plan = useSelector((state: AppStateType) => state.planReducer);

  const getAssessments = useCallback(async () => {
    setLoaging(true);
    if (plan.active.id) {
      try {
        const { data } = await assessmentsHttp.listRelationship<
          ResponseType<AssessmentType[]>
        >(plan.active.id);
        setAssessments(data.data);
      } catch (error) {
        console.error(error);
        snackbar.enqueueSnackbar("Não foi possível carregar as informações!", {
          variant: "error",
        });
      } finally {
        setLoaging(false);
      }
    }
  }, [plan.active.id, snackbar]);

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getAssessments();
    }
    return () => {
      isSubscribed = false;
    };
  }, [getAssessments]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFullDialog = () => {
    setOpenFullScreenDialog(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = (id: any) => {
    confirmAlert({
      title: "Deseja realmente excluir?",
      message: "Sua atividade de estudo será excluída permanente",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            assessmentsHttp.delete(id).then(() => {
              getAssessments();
            });
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  };
  if (loading) {
    return <Loader />;
  }
  return (
    <React.Fragment>
      <Helmet title="Simulados" />
      <Timer
        open={openFullScreenDialog}
        onHandleClose={handleCloseFullDialog}
      />
      <Grid justify="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            Simulados
          </Typography>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Typography>Lista</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <ButtonIcon
            title={"Simulado"}
            titleTootip={"Adicionar simulado"}
            icon={"add"}
            variant="contained"
            color="secondary"
            aria-owns={anchorEl ? "simple-menu" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          />

          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => setOpenFullScreenDialog(true)}>
              Cronômetro
            </MenuItem>
            <MenuItem onClick={() => history.push("/assessments/create")}>
              Manual
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
      <Divider my={6} />
      {assessments.length === 0 ? (
        <WarningPage
          title={"Nenhum simulado adicionado!"}
          subtitle={
            "Comece agora mesmo a ter controle nos simulados realizados!"
          }
          img="/static/img/simulados.svg"
        />
      ) : (
        <Table assessments={assessments} onDelete={handleDelete} />
      )}
    </React.Fragment>
  );
}

export default Simulados;
