import React, { FC } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import PageCaderno from "../../pages/Materia/Components/Caderno";

interface LinkCreateProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  subjectId: string;
}

const Caderno: FC<LinkCreateProps> = ({ open, setOpen, subjectId }) => {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth={"md"}
    >
      <DialogTitle id="form-dialog-title">Resumo da Matéria</DialogTitle>

      <DialogContent>
        <PageCaderno subjectId={subjectId} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Caderno;
