import * as React from "react";

import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  CardHeader,
  Container,
  Grid,
} from "@material-ui/core";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import ButtonIcon from "../../components/ButtonIcon";
import { useEffect, useState } from "react";
import userHttp from "../../utils/http/user-http";
const Wrapper = styled.div`
  padding-top: 3.5rem;
  position: relative;
  text-align: center;
  overflow: hidden;
`;
const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)`
  text-align: center;
`;
export const Manage = () => {
  const [urlManage, setUrlManage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    (async function () {
      try {
        const { data } = await userHttp.get("link-portal-billing");
        setLoading(true);
        if (data) {
          setUrlManage(data);
          setLoading(false);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);
  return (
    <Wrapper>
      <Container maxWidth={"sm"}>
        <Grid container>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title={"Assinatura ativa"}
                subheader={"Gerencie sua assinatura, clicando no botão abaixo."}
              />
              <CardContent>
                <ButtonIcon
                  title={"Gerenciar"}
                  titleTootip={"Gerenciar assinatura"}
                  icon={"settings"}
                  disabled={loading}
                  href={urlManage}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
  );
};

export default Manage;
