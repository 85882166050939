import * as types from "../../constants";

export type SetSettingsTimerType = {
  type: typeof types.SETTINGS_TIMER;
  payload: boolean;
};

export function setSettingsTimer(value: boolean): SetSettingsTimerType {
  return {
    type: types.SETTINGS_TIMER,
    payload: value,
  };
}
