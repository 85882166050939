import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import { rootReducer } from "../reducers/index";
import storage from "redux-persist/lib/storage";

const composeEnhancers = composeWithDevTools({});

const persistConfig = {
  key: "gestor",
  storage,
  whitelist: ["authReducer", "planReducer", "themeReducer", "timeStudyReducer"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export const persistor = persistStore(store);

export type AppDispatchType = typeof store.dispatch;
