import React from "react";
import styled from "styled-components/macro";
import { Button as MuiButton, ButtonProps, Tooltip } from "@material-ui/core";

import {
  Add as AddIcon,
  Edit as EditIcon,
  Save as SaveIcon,
  Search as SearchIcon,
  Replay as ReplayIcon,
  Reply as ReplyIcon,
  Delete as DeleteIcon,
  Archive as ArchiveIcon,
  Settings as SettingsIcon,
  ShoppingCart as ShoppingCartIcon,
} from "@material-ui/icons";

import { spacing } from "@material-ui/system";

const Button = styled(MuiButton)(spacing);

interface ButtonIconProps extends ButtonProps {
  title: string;
  titleTootip: string;
  icon?:
    | "add"
    | "edit"
    | "save"
    | "search"
    | "replay"
    | "reply"
    | "delete"
    | "archive"
    | "settings"
    | "shopping";
}

const icons = {
  add: <AddIcon />,
  edit: <EditIcon />,
  save: <SaveIcon />,
  search: <SearchIcon />,
  replay: <ReplayIcon />,
  reply: <ReplyIcon />,
  delete: <DeleteIcon />,
  archive: <ArchiveIcon />,
  settings: <SettingsIcon />,
  shopping: <ShoppingCartIcon />,
};

const ButtonIcon = ({
  title,
  titleTootip,
  icon,
  ...others
}: ButtonIconProps) => {
  return (
    <Tooltip title={titleTootip}>
      <Button
        mr={2}
        variant="contained"
        color="primary"
        {...others}
        startIcon={icon && icons[icon]}
      >
        {title}
      </Button>
    </Tooltip>
  );
};

export default ButtonIcon;
