import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  Card as MuiCard,
  CardHeader,
  CardContent,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";

import QuestionsChart from "../../components/Charts/QuestionsChart";
import { ResponseType } from "../../types/response";
import { useSelector } from "react-redux";
import { AppStateType } from "../../redux/reducers";
import { useSnackbar } from "notistack";
import assessmentsHttp from "../../utils/http/assessments-http";
import { AssessmentChartType } from "../../types/assessments";
import WarningPage from "../../components/WarningPage";
import Loader from "../../components/Loader";
import { isEmpty } from "../../utils/functions";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
  height: 265px;
  width: 100%;
`;
const valueChartDefault = {
  title: "",
  subtitle: "",
  value: [],
  label: [],
};
function Relatorios() {
  const snackbar = useSnackbar();
  const [loading, setLoaging] = useState<boolean>(false);
  const [assessmentsChart, setAssessmentsChart] = useState<AssessmentChartType>(
    valueChartDefault
  );
  const [
    assessmentsSubjectsChart,
    setAssessmentsSubjectsChart,
  ] = useState<AssessmentChartType>(valueChartDefault);

  const plan = useSelector((state: AppStateType) => state.planReducer);

  useEffect(() => {
    const getAssessmentsChart = async () => {
      setLoaging(true);
      if (!isEmpty(plan.active)) {
        const promise = [
          assessmentsHttp.listRelationshipResource<
            ResponseType<AssessmentChartType>
          >(plan?.active?.id, "report"),
          assessmentsHttp.listRelationshipResource<
            ResponseType<AssessmentChartType>
          >(plan?.active?.id, "report", {
            queryParam: { type: "subject" },
          }),
        ];
        try {
          const [
            assessmentsChartResponse,
            assessmentsChartSubjectResponse,
          ] = await Promise.all(promise);
          setAssessmentsChart(assessmentsChartResponse.data.data);
          setAssessmentsSubjectsChart(
            assessmentsChartSubjectResponse.data.data
          );
        } catch (error) {
          console.error(error);
          snackbar.enqueueSnackbar("Não foi possível carregar as informações", {
            variant: "error",
          });
        } finally {
          setLoaging(false);
        }
      }
    };
    getAssessmentsChart();
  }, [plan.active, snackbar]);
  if (loading) {
    return <Loader />;
  }
  return (
    <React.Fragment>
      <Helmet title="BarChart" />
      <Typography variant="h3" gutterBottom display="inline">
        Simulados - Relatórios
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/simulados">
          Simulados
        </Link>
        <Typography>Relatórios</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      {assessmentsChart.value.length === 0 ? (
        <WarningPage
          title={"Nenhum simulado adicionado!"}
          subtitle={
            "Comece agora mesmo a ter controle nos simulados realizados!"
          }
          img="https://material-app.bootlab.io/static/img/illustrations/waiting.png"
          titleButton={"Disponível apenas para assinantes."}
          onClick={() => {}}
        />
      ) : (
        <>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <Card mb={1}>
                <CardContent>
                  <CardHeader
                    title={assessmentsChart.title}
                    subheader={assessmentsChart.subtitle}
                  />
                  <ChartWrapper>
                    <QuestionsChart
                      data={{
                        label: assessmentsChart.label,
                        value: assessmentsChart.value,
                      }}
                    />
                  </ChartWrapper>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card mb={1}>
                <CardContent>
                  <CardHeader
                    title={assessmentsSubjectsChart.title}
                    subheader={assessmentsSubjectsChart.subtitle}
                  />
                  <ChartWrapper>
                    <QuestionsChart
                      data={{
                        label: assessmentsSubjectsChart.label,
                        value: assessmentsSubjectsChart.value,
                      }}
                    />
                  </ChartWrapper>
                </CardContent>
              </Card>
            </Grid>
            {/*<Grid item xs={12}>*/}
            {/*  <Card mb={1}>*/}
            {/*    <CardContent>*/}
            {/*      <CardHeader title={"Simulados"} subheader={"Evolução"} />*/}
            {/*      <ChartWrapper>*/}
            {/*        <EvolutionChart*/}
            {/*          data={{ label: ["ds", "ds"], value: [10, 20] }}*/}
            {/*        />*/}
            {/*      </ChartWrapper>*/}
            {/*    </CardContent>*/}
            {/*  </Card>*/}
            {/*</Grid>*/}
          </Grid>
        </>
      )}
    </React.Fragment>
  );
}

export default Relatorios;
