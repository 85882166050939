import React from "react";
import { Helmet } from "react-helmet-async";
import {
  Typography,
  Grid,
  Card as MuiCard,
  CardContent,
  Box,
} from "@material-ui/core";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import ButtonIcon from "../../components/ButtonIcon";

const Card = styled(MuiCard)(spacing);

const Suporte = () => {
  return (
    <>
      <Helmet title={"Suporte"} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Suporte
              </Typography>
              <Typography variant="body2" gutterBottom>
                Qualquer dúvida entre em contato conosco.
              </Typography>
              <Typography variant="body2" gutterBottom>
                contato@gestorconcursos.com.br
              </Typography>
              <Typography variant="body2" gutterBottom>
                ou
              </Typography>
              <Box style={{ marginTop: "15px" }}>
                <ButtonIcon
                  title={"WhatsApp"}
                  titleTootip={"WhatsApp"}
                  href={"https://wa.me/5561981705707"}
                  size={"small"}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default Suporte;
