import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { setAssessmentIntervalId } from "../../../redux/actions/timeAssessmentActions";

export default function useIntervalAssessment(
  callback: any,
  delay: any,
  interval: NodeJS.Timer
) {
  const dispatch = useDispatch();
  const callbacRef = useRef();

  // update callback function with current render callback that has access to latest props and state
  useEffect(() => {
    callbacRef.current = callback;
  });

  useEffect(() => {
    if (!delay) {
      return () => {};
    }

    if (!interval) {
      const intervalId = setInterval(() => {
        // @ts-ignore
        callbacRef.current && callbacRef.current();
      }, delay);

      dispatch(setAssessmentIntervalId(intervalId));
    }
  }, [delay, dispatch, interval]);
}
