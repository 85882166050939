import React, { FC, useCallback, useEffect, useState } from "react";
import styled from "styled-components/macro";

import {
  CardContent,
  Grid,
  Card as MuiCard,
  Typography,
  FormControl as MuiFormControl,
  TextField as MuiTextField,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { spacing, SpacingProps } from "@material-ui/system";

import ButtonIcon from "../../../components/ButtonIcon";
import { PlanType } from "../../../types/plans";
import { SubjectType } from "../../../types/subjects";
import { TopicType } from "../../../types/topics";
import { ResponseType } from "../../../types/response";
import typesHttp from "../../../utils/http/types-http";
import { useSnackbar } from "notistack";
import { Type } from "../../../types/types";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { StateFilterProps } from "../Hooks/useFilter";
const Card = styled(MuiCard)(spacing);

const FormControl = styled(MuiFormControl)<{ my?: number }>(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface SelectMuiType extends SpacingProps {
  mt?: number;
}

const Select = styled(MuiSelect)<SelectMuiType>(spacing);

interface SearchProps {
  plan: PlanType | undefined;
  getActivities: () => void;
  filter: StateFilterProps;
  dispatch: (value: any) => void;
}

const Search: FC<SearchProps> = ({ plan, getActivities, filter, dispatch }) => {
  const snackbar = useSnackbar();
  const [subjects, setSubjects] = useState<SubjectType[]>([]);
  const [subjectSelected, setSubjectSelected] = useState<string>("");
  const [topics, setTopics] = useState<TopicType[]>([]);
  const [topicSelected, setTopicSelected] = useState<string>("");
  const [typesSelected, setTypesSelected] = useState<Type[]>([]);
  const [types, setTypes] = useState<Type[]>([]);
  const [actionReset, setActionReset] = useState<boolean>(false);

  const handleSetOptionsTopics = useCallback(
    (subjectId: string) => {
      const subject: SubjectType | undefined = plan?.subjects?.find(
        (item) => item.id === subjectId
      );
      setTopics(subject?.topics || []);
    },
    [plan?.subjects]
  );

  useEffect(() => {
    if (filter.subject) {
      handleSetOptionsTopics(filter.subject);
    }
  }, [filter.subject, handleSetOptionsTopics]);

  useEffect(() => {
    function isInclude(value: Type) {
      return filter.types?.includes(value.id);
    }
    if (filter.subject) {
      setSubjectSelected(filter.subject);
    }
    if (filter.topic) {
      setTopicSelected(filter.topic);
    }
    if (filter.types) {
      const t = types.filter(isInclude);
      setTypesSelected(t);
    }
  }, [filter, types]);

  useEffect(() => {
    let isSubscribed = true;

    (async () => {
      try {
        const { data } = await typesHttp.list<ResponseType<Type[]>>();
        if (isSubscribed) {
          setTypes(data.data);
        }
      } catch (error) {
        console.error(error);
        snackbar.enqueueSnackbar(
          "Não foi possível carregar as informações!!!!",
          {
            variant: "error",
          }
        );
      }
    })();
    return () => {
      isSubscribed = false;
    };
  }, [snackbar]);

  useEffect(() => {
    setSubjects(plan?.subjects || []);
  }, [plan]);

  const handleSubject = (event: React.ChangeEvent<{ value: unknown }>) => {
    const id = event.target.value as string;
    setSubjectSelected(id);
    setTopicSelected("");

    handleSetOptionsTopics(id);
  };

  const handleTopic = (event: React.ChangeEvent<{ value: unknown }>) => {
    const id = event.target.value as string;
    setTopicSelected(id);
  };

  const handleTypes = (value: any[]) => {
    setTypesSelected(value);
  };

  const handleSearch = () => {
    dispatch({ type: "subject", payload: subjectSelected });
    dispatch({ type: "topic", payload: topicSelected });

    const newValues = typesSelected.map((row) => row.id);
    dispatch({ type: "types", payload: newValues });
  };

  const handleReset = () => {
    dispatch({ type: "reset" });
    setTypesSelected([]);
    setSubjectSelected("");
    setTopicSelected("");
    setActionReset(true);
  };

  /** Gambi para acionar o reset com os novos dados do filter **/
  useEffect(() => {
    if (actionReset) {
      setActionReset(false);
    }
  }, [filter, actionReset, getActivities]);

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="subtitle2" gutterBottom display="inline">
          Filtrar por Matéria/Conteúdo
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} xl={12}>
            <FormControl variant="outlined" fullWidth={true} my={3}>
              <InputLabel id="materia-label-id">Matéria</InputLabel>
              <Select
                labelId="materia-label-id"
                id="materia-label-id"
                value={subjectSelected}
                onChange={handleSubject}
                label="Matéria"
              >
                <MenuItem value={""}>Todas</MenuItem>
                {subjects.map((item) => (
                  <MenuItem value={item.id} key={item.id}>
                    {item.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} xl={12}>
            <FormControl variant="outlined" fullWidth={true} my={3}>
              <InputLabel id="assunto-label">Assunto</InputLabel>
              <Select
                labelId="assunto-label"
                id="assunto-id"
                onChange={handleTopic}
                label="Assunto"
                value={topicSelected}
              >
                {topics.map((item) => (
                  <MenuItem value={item.id} key={item.id}>
                    {item.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" gutterBottom display="inline">
              Filtrar por tipo de estudo
            </Typography>
            <FormControl variant="outlined" fullWidth={true} my={2}>
              <Autocomplete
                multiple
                id="tags-standard"
                options={types}
                value={typesSelected}
                getOptionLabel={(option) => option.title}
                onChange={(event, value) => handleTypes(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Tipo de Estudo"
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Typography variant="subtitle2" gutterBottom display="inline">
          Filtrar por período
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                autoOk
                variant="inline"
                format="dd/MM/yy"
                margin="normal"
                id="date-picker-inline"
                label="Início"
                value={filter.start}
                inputVariant={"outlined"}
                onChange={(value) =>
                  dispatch({ type: "start", payload: value })
                }
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xs={12} sm={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                autoOk
                variant="inline"
                format="dd/MM/yy"
                margin="normal"
                id="date-picker-inline"
                label="Fim"
                value={filter.end}
                inputVariant={"outlined"}
                onChange={(value) => dispatch({ type: "end", payload: value })}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>

        <ButtonIcon
          title={"Pesquisar"}
          titleTootip={"Pesquisar atividade"}
          icon={"search"}
          onClick={handleSearch}
        />
        <ButtonIcon
          title={"Resetar"}
          titleTootip={"Zerar"}
          onClick={handleReset}
        />
      </CardContent>
    </Card>
  );
};

export default Search;
