import * as React from "react";
import DoughnutChart from "../../../../components/Charts/DoughnutChart";
import styled from "styled-components/macro";
import { ChartType } from "../../../../types/activities";
import { Card as MuiCard, CardContent, CardHeader } from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { ChartOptions } from "chart.js";
import { secToTime } from "../../../../utils/Times";

interface TypeOfStudyProps {
  data: ChartType;
}

const ChartWrapper = styled.div`
  height: 45vh;
  width: 100%;
`;

const Card = styled(MuiCard)(spacing);

const options: ChartOptions<"doughnut"> = {
  maintainAspectRatio: false,
  plugins: {
    tooltip: {
      callbacks: {
        label(tooltipItem): string | string[] {
          console.log(tooltipItem.parsed);
          return " " + secToTime(tooltipItem.parsed);
        },
      },
    },
  },
};

// const options: ChartOptions<"doughnut"> = {
//   tooltip: {
//     callbacks: {
//       label(item, data) {
//         const index = item.index;
//         if (data.datasets !== undefined && index !== undefined) {
//           if (data.datasets[0].data !== undefined) {
//             return secToTime(data.datasets[0].data[index] as number);
//           }
//         }
//         return "";
//       },
//     },
//   },
// };

const TypeOfStudy: React.FC<TypeOfStudyProps> = ({ data }) => {
  return (
    <Card mb={1}>
      <CardContent>
        <CardHeader title={data.title} subheader={data.subtitle} />
        <ChartWrapper>
          <DoughnutChart
            label={data.label}
            data={data.value}
            options={options}
          />
        </ChartWrapper>
      </CardContent>
    </Card>
  );
};

export default TypeOfStudy;
