import * as types from "../../constants";
import { AppDispatchType } from "../store";
import {
  SignInType,
  SignUpType,
  ResetPasswordType,
  SignInGoogleType,
} from "../../types/auth";
import {
  signIn as authSignIn,
  signUp as authSignUp,
  resetPassword as authResetPassword,
  logout,
  getUser,
} from "../../services/authService";
import { User, UserTypeAction } from "../../types/user";

export function signIn(
  credentials: SignInType | SignInGoogleType,
  url = "/login"
) {
  return async (dispatch: AppDispatchType) => {
    dispatch({ type: types.AUTH_SIGN_IN_REQUEST });

    return authSignIn(credentials, url)
      .then((response: any) => {
        // console.log(response);
        dispatch(setUser(response));
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_SIGN_IN_FAILURE });
        throw error;
      });
  };
}

export function signUp(credentials: SignUpType) {
  return async (dispatch: AppDispatchType) => {
    dispatch({ type: types.AUTH_SIGN_UP_REQUEST });

    return authSignUp(credentials)
      .then((response: any) => {
        dispatch(setUser(response));
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_SIGN_UP_FAILURE });
        throw error;
      });
  };
}

export function signOut() {
  return async (dispatch: AppDispatchType) => {
    return logout()
      .then(() => {
        dispatch({
          type: types.AUTH_SIGN_OUT,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function resetPassword(credentials: ResetPasswordType) {
  return async (dispatch: AppDispatchType) => {
    dispatch({ type: types.AUTH_RESET_PASSWORD_REQUEST });

    return authResetPassword(credentials)
      .then((response: any) => {
        dispatch({
          type: types.AUTH_RESET_PASSWORD_SUCCESS,
          email: response.email,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_RESET_PASSWORD_FAILURE });
        throw error;
      });
  };
}

export function setUser(user: User): UserTypeAction {
  return {
    type: types.AUTH_SIGN_IN_SUCCESS,
    id: user.id,
    email: user.email,
    avatar: user.avatar,
    migration: user.migration,
    name: user.name,
    uf: user.uf,
    active: user.active,
    end_trial: user.end_trial,
    created: user.created,
  };
}

export function setUpdateUser() {
  return async (dispatch: AppDispatchType) => {
    return getUser()
      .then((resp) => {
        dispatch(setUser(resp as User));
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
