import React, { FC } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

import MateriaField from "./components/Materia/MateriaField";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "../../../vendor/yup";
import subjectsHttp from "../../../utils/http/subjects-http";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { SubjectType } from "../../../types/subjects";
import { PlanType } from "../../../types/plans";
import { useDispatch } from "react-redux";
import { planActive } from "../../../redux/actions/planActions";

export interface MateriaFormCreateProps {
  open: boolean;
  onOpen: (value: boolean) => void;
  plan: PlanType | null;
}

const SchemaValidation = yup.object({
  title: yup.string().label("Matéria").required().max(255),
});
const MateriaFormCreate: FC<MateriaFormCreateProps> = ({
  open,
  onOpen,
  plan,
}) => {
  const snackbar = useSnackbar();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(SchemaValidation),
  });

  const onSubmit: SubmitHandler<SubjectType> = async (formData) => {
    try {
      const { data } = await subjectsHttp.createRelationship(
        plan?.id,
        formData
      );
      dispatch(planActive());
      snackbar.enqueueSnackbar("Salvo com Sucesso", {
        variant: "success",
      });
      history.push(`/materia/${data.data.id}/edit`);
    } catch (error) {
      console.log(error);
      snackbar.enqueueSnackbar("Não é possível salvar", {
        variant: "error",
      });
    }
  };
  return (
    <Dialog
      open={open}
      onClose={() => onOpen(false)}
      aria-labelledby="form-dialog-title"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">Matéria</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <DialogContentText>Adicionar matéria ao plano.</DialogContentText>

          <MateriaField
            textFieldProps={{
              margin: "normal",
              error: errors.title !== undefined,
              helperText: errors.title?.message,
            }}
            setMateria={(value) =>
              setValue("title", value, { shouldValidate: true })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onOpen(false)} color="primary">
            Cancelar
          </Button>
          <Button type={"submit"} color="primary">
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default MateriaFormCreate;
