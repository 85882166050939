import React, { useState } from "react";
import styled from "styled-components/macro";

import { Button as MuiButton } from "@material-ui/core";

import { CalendarToday } from "@material-ui/icons";

import { spacing } from "@material-ui/system";
import DateRange from "./DateRange";

const Button = styled(MuiButton)(spacing);

const SmallButton = styled(Button)`
  padding: 4px;
  min-width: 0;

  svg {
    width: 0.9em;
    height: 0.9em;
  }
`;

function Actions() {
  const [openDate, setOPenDate] = useState(false);
  return (
    <>
      <SmallButton size="small" mr={2} onClick={() => setOPenDate(true)}>
        <CalendarToday />
      </SmallButton>
      {/*<Button*/}
      {/*  variant="contained"*/}
      {/*  color="secondary"*/}
      {/*  aria-owns={anchorEl ? "simple-menu" : undefined}*/}
      {/*  aria-haspopup="true"*/}
      {/*  onClick={handleClick}*/}
      {/*>*/}
      {/*  Imprimir*/}
      {/*</Button>*/}
      {/*<Menu*/}
      {/*  id="simple-menu"*/}
      {/*  anchorEl={anchorEl}*/}
      {/*  open={Boolean(anchorEl)}*/}
      {/*  onClose={handleClose}*/}
      {/*>*/}
      {/*  <MenuItem onClick={handleClose}>Today</MenuItem>*/}
      {/*  <MenuItem onClick={handleClose}>Yesterday</MenuItem>*/}
      {/*  <MenuItem onClick={handleClose}>Last 7 days</MenuItem>*/}
      {/*  <MenuItem onClick={handleClose}>Last 30 days</MenuItem>*/}
      {/*  <MenuItem onClick={handleClose}>This month</MenuItem>*/}
      {/*  <MenuItem onClick={handleClose}>Last month</MenuItem>*/}
      {/*</Menu>*/}
      <DateRange open={openDate} setOpen={setOPenDate} />
    </>
  );
}

export default Actions;
