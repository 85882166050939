import { Time } from "./utils";
import { useDispatch, useSelector } from "react-redux";

import {
  resetAssessmentTime,
  setAssessmentIntervalId,
  setAssessmentTime,
  setIsAssessmentRunning,
} from "../../redux/actions/timeAssessmentActions";
import useIntervalAssessment from "./hooks/useIntervalAssessment";

export default function useStopwatchAssessment() {
  const dispatch = useDispatch();
  const timeStudy = useSelector((state: any) => state.timeAssessmentReducer);

  useIntervalAssessment(
    () => {
      dispatch(
        setAssessmentTime({
          seconds:
            timeStudy.time.passedSeconds +
            Time.getSecondsFromPrevTime(timeStudy.time.prevTime, true),
        })
      );
    },
    timeStudy.isRunning ? 1000 : null,
    timeStudy.interval
  );

  const start = () => {
    const newPrevTime = Date.now();
    dispatch(setIsAssessmentRunning(true));
    dispatch(
      setAssessmentTime({
        prevTime: newPrevTime,
      })
    );
  };

  function pause() {
    dispatch(
      setAssessmentTime({
        passedSeconds: timeStudy.time.seconds,
      })
    );
    dispatch(setIsAssessmentRunning(false));
    killInterval();
  }

  function reset() {
    dispatch(resetAssessmentTime());
  }

  function killInterval() {
    clearInterval(timeStudy.interval);
    dispatch(setAssessmentIntervalId(null));
  }

  return {
    ...Time.getTimeFromSeconds(timeStudy.time.seconds),
    start,
    pause,
    reset,
    isRunning: timeStudy.isRunning,
  };
}
