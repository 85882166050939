import HttpResource from "./http-resource";
import httpAxios from "./index";
import { AxiosResponse } from "axios";

class PlansExampleHttpResource extends HttpResource {
  copy<T = any>(id: number): Promise<AxiosResponse<T>> {
    return this.http.get<T>(`${this.resource}/copy/${id}`);
  }
}

const plansExampleHttp = new PlansExampleHttpResource(
  httpAxios,
  "api/plans-examples"
);

export default plansExampleHttp;
