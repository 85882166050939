import * as React from "react";
import styled from "styled-components/macro";
import { Typography as MuiTypography } from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setUpdateUser } from "../../redux/actions/authActions";

const Wrapper = styled.div`
  padding-top: 3.5rem;
  position: relative;
  text-align: center;
  overflow: hidden;
`;

const Typography = styled(MuiTypography)(spacing);

const Title = styled(Typography)`
  opacity: 0.9;
  line-height: 1.4;
  font-size: 1.75rem;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};

  ${(props) => props.theme.breakpoints.up("sm")} {
    font-size: 2rem;
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 2.5rem;
  }

  span {
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

const Subtitle = styled(Typography)`
  font-size: ${(props) => props.theme.typography.h6.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  font-family: ${(props) => props.theme.typography.fontFamily};
  margin: ${(props) => props.theme.spacing(2)}px 0;
`;
const Success = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setUpdateUser());
  }, [dispatch]);

  return (
    <Wrapper>
      <Title>
        Pagamento realizado com <span>sucesso!</span>
      </Title>
      <Subtitle>
        Agora você tem acesso completo, lembre-se que as renovações são
        automáticas. Porém você pode cancelar a qualquer momento. Qualquer
        dúvida envie um e-mail para contato@gestorconcursos.com.br
      </Subtitle>
    </Wrapper>
  );
};

export default Success;
