import React from "react";
import {
  Box,
  Button as MuiButton,
  ButtonProps,
  styled,
  Tooltip,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Save as SaveIcon, Edit as EditIcon } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
const useStyles = makeStyles((theme: Theme) => {
  return {
    submit: {
      margin: theme.spacing(1),
    },
  };
});

const Button = styled(MuiButton)(spacing);

interface SubmitActionProps {
  disabledButtons?: boolean;
  handleSalve: () => void;
}

const SubmitAction: React.FC<SubmitActionProps> = (props) => {
  const classes = useStyles();

  const buttonProps: ButtonProps = {
    className: classes.submit,
    variant: "contained",
    color: "secondary",
    disabled: props.disabledButtons,
  };

  return (
    <Box dir={"ltf"}>
      <Tooltip title={"Salvar"}>
        <Button
          color={"primary"}
          mr={2}
          variant="contained"
          {...buttonProps}
          onClick={props.handleSalve}
          startIcon={<SaveIcon />}
        >
          Salvar
        </Button>
      </Tooltip>
      <Button {...buttonProps} type={"submit"} startIcon={<EditIcon />}>
        Salvar e continuar editando
      </Button>
    </Box>
  );
};

SubmitAction.defaultProps = {
  disabledButtons: false,
};

export default SubmitAction;
