import React from "react";
import styled from "styled-components/macro";
import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  CardHeader,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)<{ pb?: number }>`
  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;

interface LaneProps {
  title: string;
  description?: string;
  children: React.ReactNode;
  action?: React.ReactNode;
}
const Lane: React.FC<LaneProps> = ({
  title,
  description,
  children,
  action,
}) => {
  return (
    <Card>
      <CardHeader title={title} subheader={description} action={action} />
      <CardContent pb={0}>
        <div>{children}</div>
      </CardContent>
    </Card>
  );
};

export default Lane;
