import React from "react";
import { withTheme, ThemeProps } from "styled-components/macro";

import { orange, red } from "@material-ui/core/colors";

import { Theme } from "@material-ui/core";

import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

interface DoughnutChartProps extends ThemeProps<Theme> {
  label: Array<string>;
  data: Array<any>;
  options?: ChartOptions<"doughnut">;
}

const DoughnutChart = ({
  label,
  data: dataset,
  theme,
  options,
}: DoughnutChartProps) => {
  const data = {
    labels: label,
    datasets: [
      {
        data: dataset,
        backgroundColor: [
          theme.palette.secondary.main,
          red[500],
          orange[500],
          theme.palette.grey[200],
        ],
        borderWidth: 5,
        borderColor: theme.palette.background.paper,
      },
    ],
  };

  const optionsChart = {
    maintainAspectRatio: false,
    legend: {
      display: true,
    },
    cutoutPercentage: 80,
    ...options,
  };

  return <Doughnut data={data} options={optionsChart} />;
};

export default withTheme(DoughnutChart);
