import React, { FC, useEffect, useState } from "react";
import Form from "./components/Plano/Form";

import { Dialog, DialogContent as MuiDialogContent } from "@material-ui/core";
import styled from "styled-components/macro";
import areasHttp from "../../../utils/http/areas-http";
import { ResponseType } from "../../../types/response";
import { AreaType } from "../../../types/plans";
import { useSnackbar } from "notistack";

const DialogContent = styled(MuiDialogContent)`
  padding: 5px;
`;

interface FormDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
}

const PlanoFormCreateDialog: FC<FormDialogProps> = ({ open, setOpen }) => {
  const snackbar = useSnackbar();
  const [areas, setAreas] = useState<AreaType[]>([]);

  useEffect(() => {
    const getAreas = async () => {
      try {
        const { data } = await areasHttp.list<ResponseType<AreaType[]>>();
        setAreas(data.data);
      } catch (error) {
        console.error(error);
        snackbar.enqueueSnackbar("Não foi possível carregar as informações", {
          variant: "error",
        });
      }
    };
    if (open) {
      getAreas();
    }
  }, [snackbar, open]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogContent>
        <Form areas={areas} />
      </DialogContent>
    </Dialog>
  );
};

export default PlanoFormCreateDialog;
