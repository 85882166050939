import React, { FC } from "react";
import styled from "styled-components/macro";

import {
  CardContent as MuiCardContent,
  Card as MuiCard,
  Typography as MuiTypography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import ButtonIcon from "./ButtonIcon";

const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)`
  text-align: center;
`;

const IllustrationImage = styled.img`
  height: 250px;
  right: ${(props) => props.theme.spacing(1)};
  margin-bottom: ${(props) => props.theme.spacing(4)};
`;

const Typography = styled(MuiTypography)`
  padding: 10px;
`;

interface WarningPageType {
  title: string;
  subtitle?: string;
  img: string;
  onClick?: () => void;
  titleButton?: string;
}

const WarningPage: FC<WarningPageType> = ({
  title,
  subtitle,
  img,
  onClick,
  titleButton,
}) => {
  return (
    <Card mb={6}>
      <CardContent>
        <IllustrationImage src={img} alt="Illustration" />
        <Typography variant="h3" gutterBottom>
          {title}
        </Typography>
        {subtitle && (
          <Typography
            variant="body1"
            gutterBottom
            style={{ marginBottom: "20px" }}
          >
            {subtitle}
          </Typography>
        )}
        {onClick && titleButton && (
          <ButtonIcon
            title={titleButton}
            titleTootip={titleButton}
            onClick={onClick}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default WarningPage;
