import * as types from "../../constants";

export type UserType = {
  id?: string;
  email?: string;
  name?: string;
  nickname?: string;
  migration?: boolean;
  uf?: string;
  avatar?: string;
  active?: boolean;
  end_trial?: boolean | number;
  created?: boolean;
};

export type AuthType = {
  user?: UserType | undefined;
};

export default function reducer(
  state: AuthType = {
    user: undefined,
  },
  actions: UserType & { type: string }
): AuthType {
  switch (actions.type) {
    case types.AUTH_SIGN_IN_SUCCESS:
      return {
        ...state,
        user: {
          id: actions.id,
          email: actions.email,
          name: actions.name,
          migration: actions.migration,
          uf: actions.uf,
          avatar: actions.avatar,
          active: actions.active,
          end_trial: actions.end_trial,
          created: actions.created,
        },
      };
    case types.AUTH_CHANGE_ACTIVE:
      return {
        user: {
          ...state.user,
          active: actions.active,
        },
      };

    case types.AUTH_SIGN_OUT:
      return {
        user: undefined,
      };

    default:
      return state;
  }
}
