import * as React from "react";
import { Card as MuiCard, CardContent, CardHeader } from "@material-ui/core";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import BarChart from "./BarChart";
import { ChartOptions } from "chart.js";

const ChartWrapper = styled.div`
  height: 40vh;
  width: 100%;
`;

const Card = styled(MuiCard)(spacing);

const options: ChartOptions<"bar"> = {
  maintainAspectRatio: false,
  indexAxis: "y" as const,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: function (context: any) {
          let label = context.parsed.x || 0;
          return label + "%";
        },
      },
    },
  },
  scales: {
    y: {
      stacked: false,
      beginAtZero: true,
      ticks: {
        stepSize: 10,
      },
    },
    x: {
      stacked: false,
      min: 0,
      max: 100,
    },
  },
};

interface ProgressChartProps {
  title: string;
  subheader: string;
  labels: string[];
  data: any[];
}

const ProgressChart: React.FC<ProgressChartProps> = ({
  title,
  subheader,
  labels,
  data,
}) => {
  return (
    <Card mb={1}>
      <CardHeader title={title} subheader={subheader} />
      <CardContent>
        <ChartWrapper>
          <BarChart data={data} labels={labels} options={options} />
        </ChartWrapper>
      </CardContent>
    </Card>
  );
};

export default ProgressChart;
