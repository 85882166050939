import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid as MuiGrid,
  Link,
  Typography as MuiTypography,
} from "@material-ui/core";

import { spacing, SpacingProps } from "@material-ui/system";

import MateriaList from "./MateriaList";

import Form from "./components/Plano/Form";
import { useParams } from "react-router";
import plansHttp from "../../../utils/http/plans-http";
import { useSnackbar } from "notistack";
import Loader from "../../../components/Loader";
import { AreaType, PlanType } from "../../../types/plans";
import { ResponseType } from "../../../types/response";
import areasHttp from "../../../utils/http/areas-http";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);

interface TypographyPropsType extends SpacingProps {
  component?: string;
}
const Typography = styled(MuiTypography)<TypographyPropsType>(spacing);

function PlanoFormEdit() {
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [plan, setPlan] = useState<PlanType | null>(null);
  const [areas, setAreas] = useState<AreaType[]>([]);

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const getAreas = async () => {
      try {
        const { data } = await areasHttp.list<ResponseType<AreaType[]>>();
        setAreas(data.data);
      } catch (error) {
        console.error(error);
        snackbar.enqueueSnackbar("Não foi possível carregar as informações", {
          variant: "error",
        });
      }
    };
    getAreas();
  }, [snackbar]);

  const getPlan = useCallback(async () => {
    try {
      const { data } = await plansHttp.get<ResponseType<PlanType>>(id);
      setPlan(data.data);
    } catch (error) {
      console.error(error);
      snackbar.enqueueSnackbar("Não foi possível carregar as informações", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [snackbar, id]);

  useEffect(() => {
    let isSubscribed = true;
    setLoading(true);
    if (isSubscribed) {
      getPlan();
    }
    return () => {
      isSubscribed = false;
    };
  }, [getPlan]);

  if (loading) {
    return <Loader />;
  }
  return (
    <React.Fragment>
      <Helmet title="Editar Plano" />
      <Typography variant="h3" gutterBottom display="inline">
        Editar Plano
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/planos/meus-planos">
          Meus Planos
        </Link>
        <Typography>Editar</Typography>
      </Breadcrumbs>
      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12} lg={4} xl={5}>
          <Form plan={plan} areas={areas} />
        </Grid>
        <Grid item xs={12} lg={8} xl={7}>
          <MateriaList plan={plan} onGetPLan={getPlan} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default PlanoFormEdit;
