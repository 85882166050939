import * as types from "../../constants";
import { SetSettingsTimerType } from "../actions/settingsActions";

export type SettingsInitialStateType = {
  timer: boolean;
};

const initialState = {
  timer: false,
};

export default function reducer(
  state = initialState,
  actions: ActionTypes
): SettingsInitialStateType {
  switch (actions.type) {
    case types.SETTINGS_TIMER:
      return {
        ...state,
        timer: actions.payload,
      };

    default:
      return state;
  }
}

type ActionTypes = SetSettingsTimerType;
