import React, { FC } from "react";
import styled from "styled-components/macro";
import {
  CardContent,
  Card as MuiCard,
  TableContainer,
  Table as MuiTable,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CardHeader,
  TableSortLabel,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { useFilters, useGlobalFilter, useSortBy, useTable } from "react-table";

import { SubjectListStaticsType } from "../../../../types/subjects";
import { TopicListStaticsType } from "../../../../types/topics";
import { secToTime } from "../../../../utils/Times";
import LinearProgressWithLabel from "../../../../components/Material/LinearWithLabel";

const Card = styled(MuiCard)(spacing);

interface TableProps {
  list: SubjectListStaticsType[] | TopicListStaticsType[];
}

const TopicsTable: FC<TableProps> = (props) => {
  const { list } = props;
  const data = React.useMemo(() => list, [list]);
  const columns: any = React.useMemo(
    () => [
      {
        Header: "Assunto",
        accessor: "title",
      },
      {
        Header: "Tempo",
        accessor: "duration",

        Cell: ({ row }: any) => {
          return `${secToTime(row.original.duration)}`;
        },
      },
      {
        Header: "Acertos",
        accessor: "hits",
        Cell: ({ row }: any) => {
          return `${row.original.hits ?? 0}`;
        },
      },
      {
        Header: "Erros",
        accessor: "erros",

        Cell: ({ row }: any) => {
          return `${row.original.total - row.original.hits}`;
        },
      },
      {
        Header: "Total",
        accessor: "total",
        Cell: ({ row }: any) => {
          return `${row.original.total ?? 0}`;
        },
      },

      {
        Header: "Desempenho",
        accessor: "desempenho",
        Cell: ({ row }: any) => {
          return `${
            row.original.desempenho ? parseInt(row.original.desempenho) : 0
          }%`;
        },
      },
      {
        Header: "Progresso",
        accessor: "progress",
        disableSortBy: true,

        Cell: ({ row }: any) => {
          return (
            <LinearProgressWithLabel value={row.original.completed * 100} />
          );
        },
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      autoResetSortBy: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );

  const { headerGroups, rows, prepareRow } = tableInstance;

  return (
    <Card mb={6}>
      <CardHeader title="Assuntos" />
      <CardContent>
        <TableContainer>
          <MuiTable
            aria-labelledby="assuntos"
            size={"medium"}
            aria-label="assuntos"
          >
            <TableHead>
              {headerGroups.map((headerGroup, index) => (
                <TableRow {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column: any, i) => (
                    <TableCell
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      key={i}
                    >
                      <TableSortLabel
                        direction={
                          column.isSorted
                            ? column.isSortedDesc
                              ? "desc"
                              : "asc"
                            : undefined
                        }
                      >
                        {column.render("Header")}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <TableRow {...row.getRowProps()} hover key={i}>
                    {row.cells.map((cell: any, index) => (
                      <TableCell {...cell.getCellProps()} key={index}>
                        {cell.render("Cell")}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
              {list.length === 0 && (
                <TableRow>
                  <TableCell colSpan={7}>
                    Nenhuma atividade cadastrada.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </MuiTable>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default TopicsTable;
