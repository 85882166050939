import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import Lottie from "react-lottie";
import animationData from "../../../lotties/study.json";
import {
  Button,
  Container,
  Grid,
  LinearProgress,
  Typography as MuiTypography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import migrationHttp from "../../../utils/http/migration-http";
import { useDispatch } from "react-redux";
import {
  list as planList,
  planActive,
} from "../../../redux/actions/planActions";
import { setUser } from "../../../redux/actions/authActions";

const Typography = styled(MuiTypography)(spacing);

const Wrapper = styled.div`
  padding-top: 3.5rem;
  position: relative;
  text-align: center;
  overflow: hidden;
`;

const Content = styled.div`
  padding: ${(props) => props.theme.spacing(6)}px 0;
  line-height: 150%;
`;

const Title = styled(Typography)`
  opacity: 0.9;
  line-height: 1.4;
  font-size: 1.75rem;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};

  ${(props) => props.theme.breakpoints.up("sm")} {
    font-size: 2rem;
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 2.5rem;
  }

  span {
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

const Subtitle = styled(Typography)`
  font-size: ${(props) => props.theme.typography.h6.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  font-family: ${(props) => props.theme.typography.fontFamily};
  margin: ${(props) => props.theme.spacing(2)}px 0;
`;

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Introduction = () => {
  const snackbar = useSnackbar();
  const history = useHistory();
  const dispatch = useDispatch();
  const [migrated, setMigrated] = useState<boolean>(false);

  useEffect(() => {
    const migration = async () => {
      try {
        const { data } = await migrationHttp.list();
        await dispatch(planActive());
        await dispatch(planList());
        dispatch(setUser(data.data));
        setMigrated(true);
        //history.push("/");
      } catch (error) {
        console.error(error);
        snackbar.enqueueSnackbar("Não foi possível carregar as informações!", {
          variant: "error",
        });
      }
    };
    migration();
  }, [dispatch, history, snackbar]);

  return (
    <Wrapper>
      <Container>
        <Grid container alignItems="center" justify="center" spacing={4}>
          <Grid item xs={12} sm={9} md={8} lg={7}>
            {!migrated ? (
              <Content>
                <Title variant="h1" gutterBottom>
                  Aguarde estamos <span>migrando</span> seus dados.
                </Title>
                <Grid container justify="center" spacing={4}>
                  <Grid item xs={12} lg={10}>
                    <Subtitle color="textSecondary">
                      Estamos migrando seus planos de estudo e todas atividades
                      cadastradas na plataforma anterior.
                    </Subtitle>
                  </Grid>
                  <Grid item xs={12} lg={10}>
                    <Lottie
                      options={defaultOptions}
                      height={400}
                      width={400}
                      isStopped={false}
                      isPaused={false}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <LinearProgress />
                  </Grid>
                </Grid>
              </Content>
            ) : (
              <Content>
                <Title variant="h1" gutterBottom>
                  Migrado com <span>Sucesso!</span>
                </Title>
                <Grid container justify="center" spacing={4}>
                  <Grid item xs={12} lg={10}>
                    <Subtitle color="textSecondary">
                      Atenção: A migração acontece apenas 1 vez. Os dados
                      inseridos na plataforma anterior ou na nova, não serão
                      mais sincronizados.
                    </Subtitle>
                  </Grid>
                  <Grid item xs={12} lg={10}>
                    <Button
                      variant={"contained"}
                      color={"primary"}
                      onClick={() => history.push("/")}
                    >
                      Página Inicial
                    </Button>
                  </Grid>
                </Grid>
              </Content>
            )}
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
  );
};

export default Introduction;
