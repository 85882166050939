import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import * as Yup from "../../vendor/yup";
import { signIn } from "../../redux/actions/authActions";
import {
  Avatar,
  Checkbox,
  FormControlLabel,
  Button,
  Paper,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { SignInType } from "../../types/auth";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { list as planList, planActive } from "../../redux/actions/planActions";
import GoogleLogin from "react-google-login";
import Box from "@material-ui/core/Box";
import { purple } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/styles";
import Loader from "../../components/Loader";
import { AppStateType } from "../../redux/reducers";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

const BigAvatar = styled(Avatar)`
  width: 92px;
  height: 92px;
  text-align: center;
  margin: 0 auto ${(props) => props.theme.spacing(5)}px;
`;

const ButtonSubmit = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: "#263041",
    "&:hover": {
      backgroundColor: "#415b89",
    },
  },
}))(Button);

const SchemaValidation = Yup.object().shape({
  email: Yup.string()
    .email("Email inválido")
    .max(255)
    .required("Email é obrigatório"),
  password: Yup.string().max(255).required("Senha é obrigatório"),
});
function SignIn() {
  const dispatch = useDispatch();
  const history = useHistory();
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorResponse, setErrorResponse] = useState<string | null>(null);
  const auth = useSelector((state: AppStateType) => state.authReducer);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<SignInType>({
    resolver: yupResolver(SchemaValidation),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  useEffect(() => {
    async function getPlansDispatch() {
      await dispatch(planActive());
      await dispatch(planList());
      history.push("/");
    }
    if (auth.user) {
      if (auth.user.created) {
        window.location.href = "/register-completed";
      } else {
        getPlansDispatch();
      }
    }
  }, [auth, history, dispatch]);

  const onSubmit: SubmitHandler<SignInType> = async (formData) => {
    setLoading(true);
    try {
      await dispatch(signIn(formData));
    } catch (error) {
      if (error.response.status === 422) {
        setErrorResponse("Email ou senha incorretos.");
      } else {
        snackbar.enqueueSnackbar("Não é possível realizar o login", {
          variant: "error",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const responseGoogle = async (response: any) => {
    if (response.tokenId) {
      setLoading(true);
      try {
        await dispatch(signIn({ tokenId: response.tokenId }, "/login-google"));
      } catch (error) {
        if (error.response.status === 422) {
          snackbar.enqueueSnackbar("Usuário não encontrado!", {
            variant: "warning",
          });
        } else {
          snackbar.enqueueSnackbar("Não é possível realizar o login", {
            variant: "error",
          });
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCheckout = async () => {
    window.location.href = process.env.REACT_APP_LINK_PAYMENT as string;
  };

  return (
    <Wrapper>
      <Helmet title="Sign In" />
      <BigAvatar alt="Gestor Concursos" src="/static/img/logo/logo.png" />

      <Typography component="h1" variant="h4" align="center" gutterBottom>
        Gestor Concursos
      </Typography>
      <Typography component="h2" variant="body1" align="center">
        Faça login na sua conta.
      </Typography>
      <Box
        style={{
          marginTop: "20px",
          marginBottom: "20px",
          textAlign: "center",
        }}
      >
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ""}
          buttonText="Login com Google"
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          cookiePolicy={"single_host_origin"}
          style={{ width: "100%" }}
        />
      </Box>
      <Box
        style={{
          position: "relative",
          textAlign: "center",
          display: "block",
          borderTopWidth: "1px",
          borderTopStyle: "solid",
          margin: "10px auto 0",
          height: "10px",
          borderTopColor: "#ebebec",
        }}
      >
        <span
          style={{
            fontSize: "16px",
            color: "#cbcdd2",
            padding: "0 10px",
            backgroundColor: "#fff",
            top: "-9px",
            position: "relative",
          }}
        >
          OU
        </span>
      </Box>

      {errorResponse && (
        <Alert mt={2} mb={1} severity="warning">
          {errorResponse}
        </Alert>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              type="text"
              label="Email"
              error={errors.email !== undefined}
              helperText={errors.email?.message}
              fullWidth
              my={3}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              type="password"
              label="Senha"
              error={errors.password !== undefined}
              helperText={errors.password?.message}
              fullWidth
              my={3}
            />
          )}
        />

        <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label="Remember me"
        />
        <ButtonSubmit
          type="submit"
          fullWidth
          variant="contained"
          disabled={loading}
        >
          {loading ? <Loader size={23} /> : "Estudar"}
        </ButtonSubmit>
        <Button component={Link} to="/auth/reset-password" fullWidth>
          Esqueci a senha
        </Button>

        {/*<Button component={Link} to="/auth/sign-up" fullWidth color="primary">*/}
        {/*  Assinar*/}
        {/*</Button>*/}
        <Button onClick={handleCheckout} fullWidth color="primary">
          Assinar
        </Button>
      </form>
    </Wrapper>
  );
}

export default SignIn;
