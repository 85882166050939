import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useHistory } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Helmet } from "react-helmet-async";

import {
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography as MuiTypography,
} from "@material-ui/core";

import { spacing, display } from "@material-ui/system";
import ButtonIcon from "../../components/ButtonIcon";
import assessmentsHttp from "../../utils/http/assessments-http";
import { ResponseType } from "../../types/response";
import { format, parseISO } from "date-fns";
import { useParams } from "react-router";
import { AssessmentType } from "../../types/assessments";
import { useSnackbar } from "notistack";
import Stats from "../../components/Stats";
import { green } from "@material-ui/core/colors";
import { confirmAlert } from "react-confirm-alert";
import { secToTime } from "../../utils/Times";

const Card = styled(MuiCard)`
  ${spacing};

  box-shadow: none;
`;

const Divider = styled(MuiDivider)(spacing);

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Typography = styled(MuiTypography)(display);

function AssessmentsShow() {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const snackbar = useSnackbar();
  const [data, setData] = useState<AssessmentType | null>(null);

  const handleDelete = (id: any) => {
    confirmAlert({
      title: "Deseja realmente excluir?",
      message: "Todas suas atividades desse plano serão apagadas.",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            assessmentsHttp.delete(id).then(() => {
              history.push("/simulados");
            });
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  };

  useEffect(() => {
    if (!id) {
      return;
    }
    let isSubscribed = true;
    (async () => {
      try {
        const { data } = await assessmentsHttp.getResource<ResponseType<any>>(
          id,
          "show"
        );
        if (isSubscribed) {
          const updateForm = data.data;
          const date = parseISO(updateForm["start"]);
          updateForm["start"] = format(date, "dd/MM/yyyy");
          setData(updateForm);
        }
      } catch (error) {
        console.error(error);
        snackbar.enqueueSnackbar("Não foi possível carregar as informações", {
          variant: "error",
        });
      }
    })();
    return () => {
      isSubscribed = false;
    };
  }, [id, snackbar]);
  return (
    <React.Fragment>
      <Helmet title="Simulado" />

      <Typography variant="h3" gutterBottom display="inline">
        {data?.title}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/simulados">
          Simulados
        </Link>
        <Link component={NavLink} exact to={`/assessment/${id}/edit`}>
          Editar
        </Link>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container justify="center">
        <Grid item xs={12} lg={10}>
          <Shadow>
            <Card px={6} pt={6}>
              <CardContent>
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" gutterBottom>
                      Data do Simulado: {data?.start}
                      <br />
                      Simulado: {data?.title}
                      <br />
                      {data?.duration &&
                        `Tempo: ${secToTime(data.duration, 3)}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={6} xl={3}>
                    <Stats
                      title="Total de Questões"
                      amount={data?.total}
                      chip="Today"
                      percentagetext="100%"
                      percentagecolor={green[500]}
                      illustration="https://material-app.bootlab.io/static/img/illustrations/working.png"
                    />
                  </Grid>
                  <Grid item xs={12} lg={6} xl={3}>
                    <Stats
                      title="Acertos"
                      amount={data?.hits}
                      chip="Today"
                      percentagetext={data?.hits_performance}
                      percentagecolor={green[500]}
                      illustration="https://material-app.bootlab.io/static/img/illustrations/working.png"
                    />
                  </Grid>
                  <Grid item xs={12} lg={6} xl={3}>
                    <Stats
                      title="Errors"
                      amount={data?.erros}
                      chip="Today"
                      percentagetext={data?.erros_performance}
                      percentagecolor={green[500]}
                      illustration="https://material-app.bootlab.io/static/img/illustrations/working.png"
                    />
                  </Grid>
                  <Grid item xs={12} lg={6} xl={3}>
                    <Stats
                      title="Branco"
                      amount={data?.branco}
                      chip="Today"
                      percentagetext={data?.branco_performance}
                      percentagecolor={green[500]}
                      illustration="https://material-app.bootlab.io/static/img/illustrations/working.png"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card px={6}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Matéria</TableCell>
                    <TableCell>Acertos</TableCell>
                    <TableCell>Brancos</TableCell>
                    <TableCell>Errors</TableCell>
                    <TableCell align="right">Aproveitamento</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.assessment_subjects.map((row) => (
                    <TableRow key={row.subject_id}>
                      <TableCell component="th" scope="row">
                        {row.title}
                      </TableCell>
                      <TableCell>{row.acertos}</TableCell>
                      <TableCell>{row.erros}</TableCell>
                      <TableCell>{row.branco}</TableCell>
                      <TableCell align="right">{`${row.performance}%`}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Card>
            <Card pb={6} px={6}>
              <CardContent style={{ textAlign: "center" }}>
                <ButtonIcon
                  title={"Editar"}
                  titleTootip={"Editar Simulado"}
                  icon={"edit"}
                  onClick={() => history.push(`/assessment/${data?.id}/edit`)}
                />
                <ButtonIcon
                  title={"Excluir"}
                  titleTootip={"Excluir Simulado"}
                  icon={"delete"}
                  color={"default"}
                  onClick={() => handleDelete(id)}
                />
              </CardContent>
            </Card>
          </Shadow>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default AssessmentsShow;
