import React, { useState } from "react";
import { InputAdornment, TextField } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";

import { TextFieldProps } from "@material-ui/core/TextField";
import useCollectionManager from "../../../../../hooks/useCollectionManager";

import ButtonIcon from "../../../../../components/ButtonIcon";

import Lane from "../../../../../components/Draging/Lane";
import Content from "../../../../../components/Draging/Content";

interface AssuntoFieldProps {
  topics: any[];
  setTopics: (topics: any) => void;
  textFieldProps?: TextFieldProps;
}

const AssuntoField: React.FC<AssuntoFieldProps> = (props) => {
  const { textFieldProps, topics, setTopics } = props;
  const [dragId, setDragId] = useState<string>();
  const { addItem, removeItem, updateItem } = useCollectionManager(
    topics,
    setTopics
  );

  const [value, setValue] = useState("");
  const [valueIndexEdit, setValueIndexEdit] = useState<number | null>(null);
  const [valueUpdate, setValueUpdate] = useState<string>("");

  const handleDrag = (ev: any) => {
    setDragId(ev.currentTarget.id);
  };

  const handleDrop = (ev: any) => {
    const id = ev.currentTarget.id;
    const dragBox = topics.find((box) => box.id === dragId);
    const dropBox = topics.find((box) => box.id === id);

    if (dragBox === undefined || dropBox === undefined) {
      return;
    }
    const dragBoxOrder = dragBox.order;
    const dropBoxOrder = dropBox.order;

    const newBoxState = topics.map((box) => {
      if (box.id === dragId) {
        box.order = dropBoxOrder;
      }
      if (box.id === id) {
        box.order = dragBoxOrder;
      }
      return box;
    });
    setTopics(newBoxState);
  };

  function add() {
    addItem({
      id: uuidv4(),
      title: value,
      order: topics.length + 1,
    });
    setValue("");
  }

  function changeEdit(item: any, index: number) {
    setValueUpdate(item.title);
    setValueIndexEdit(index);
  }

  function changeUpdate(item: any) {
    updateItem(item.title, valueUpdate);
    setValueIndexEdit(null);
  }

  return (
    <Lane title={"Assuntos"}>
      {topics
        ?.sort((a, b) => a.order - b.order)
        .map((assunto, index) => {
          if (valueIndexEdit === index) {
            return (
              <TextField
                {...textFieldProps}
                autoFocus
                margin="dense"
                id="name"
                label="Assuntos"
                type="text"
                variant={"outlined"}
                fullWidth
                value={valueUpdate}
                onChange={(event) => setValueUpdate(event.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position={"end"}>
                      <ButtonIcon
                        title={"Atualizar"}
                        titleTootip={"Ataulizar Assunto"}
                        icon={"edit"}
                        size={"small"}
                        onClick={() => changeUpdate(assunto)}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            );
          } else {
            return (
              <Content
                id={assunto.id}
                label={assunto.title}
                order={assunto.order}
                onDrag={handleDrag}
                onDrop={handleDrop}
                onEdit={() => changeEdit(assunto, index)}
                onDelete={() => removeItem(assunto.title)}
              />
            );
          }
        })}

      <TextField
        {...textFieldProps}
        autoFocus
        margin="dense"
        id="name"
        label="Assuntos"
        type="text"
        variant={"outlined"}
        fullWidth
        value={value}
        onChange={(event) => setValue(event.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position={"end"}>
              <ButtonIcon
                title={"Adicionar"}
                titleTootip={"Adicionar Assunto"}
                icon={"add"}
                size={"small"}
                onClick={add}
              />
            </InputAdornment>
          ),
        }}
      />
    </Lane>
  );
};

export default AssuntoField;
