import React, { FC, useState } from "react";
import styled from "styled-components/macro";

import { Helmet } from "react-helmet-async";

import FullCalendar, { DatesSetArg } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Grid,
  Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import calendarStyle from "./Calendar.style";
import { ActivityCalendarType } from "../../types/activities";
import { ResponseType } from "../../types/response";
import { useSelector } from "react-redux";
import { AppStateType } from "../../redux/reducers";
import { useSnackbar } from "notistack";
import calendarHttp from "../../utils/http/calendar-http";

const FullCalendarWrapper = styled.div`
  ${calendarStyle}
`;

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Calendario: FC = () => {
  const snackbar = useSnackbar();
  const [activities, setActivities] = useState<ActivityCalendarType[]>([]);
  const plan = useSelector((state: AppStateType) => state.planReducer);
  const handleDateSet = (arg: DatesSetArg) => {
    getActivities(arg.start, arg.end);
  };

  const getActivities = async (start: Date, end: Date) => {
    if (plan.active) {
      try {
        const { data } = await calendarHttp.listRelationship<
          ResponseType<ActivityCalendarType[]>
        >(plan.active.id, {
          queryParam: {
            start,
            end,
          },
        });
        setActivities(data.data);
      } catch (error) {
        console.error(error);
        snackbar.enqueueSnackbar("Não foi possível carregar as informações!", {
          variant: "error",
        });
      }
    }
  };

  return (
    <React.Fragment>
      <Helmet title="Calendar" />
      <Typography variant="h3" gutterBottom display="inline">
        Calendario
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Atividades realizadas</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent p={6}>
              <FullCalendarWrapper>
                <FullCalendar
                  locale={"pt"}
                  buttonText={{
                    day: "Hoje",
                    week: "Semana",
                    month: "Mês",
                  }}
                  initialView="dayGridMonth"
                  initialDate={new Date()}
                  plugins={[dayGridPlugin, interactionPlugin]}
                  events={activities}
                  editable={false}
                  headerToolbar={{
                    left: "prev,next",
                    center: "title",
                    right: "dayGridDay,dayGridWeek,dayGridMonth",
                  }}
                  datesSet={handleDateSet}
                />
              </FullCalendarWrapper>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Calendario;
