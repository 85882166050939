import * as React from "react";
import styled from "styled-components/macro";
import { FilePlus } from "react-feather";
import { useHistory } from "react-router-dom";

import { Tooltip, IconButton as MuiIconButton } from "@material-ui/core";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function ActivityCreate() {
  const history = useHistory();

  const handleClick = async () => {
    history.push("/activity/create");
  };

  return (
    <React.Fragment>
      <Tooltip title="Adicionar Atividade">
        <IconButton
          aria-haspopup="true"
          onClick={handleClick}
          color="inherit"
          id={"activity-manual"}
        >
          <FilePlus />
        </IconButton>
      </Tooltip>
    </React.Fragment>
  );
}

export default ActivityCreate;
