import React from "react";
import { Helmet } from "react-helmet-async";

import { Grid, Typography } from "@material-ui/core";

import Form from "./components/Form";
import {
  a11yProps,
  AntTab,
  AntTabs,
  TabPanel,
} from "../../components/Material/CustomizedTabs";
import Password from "./components/Password";

function Conta() {
  const [abaTop, setAbaTop] = React.useState(0);

  const handleChangeAba = (event: React.ChangeEvent<{}>, newValue: number) => {
    setAbaTop(newValue);
  };

  return (
    <React.Fragment>
      <Helmet title="Conta" />
      <Grid justify="space-between" container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h3" gutterBottom>
            Conta
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <AntTabs
            value={abaTop}
            onChange={handleChangeAba}
            aria-label="ant example"
          >
            <AntTab label="PERFIL" {...a11yProps(0)} />
            <AntTab label="SENHA" {...a11yProps(1)} />
          </AntTabs>
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <TabPanel value={abaTop} index={0}>
            <Form edit />
          </TabPanel>
          <TabPanel value={abaTop} index={1}>
            <Password edit />
          </TabPanel>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Conta;
