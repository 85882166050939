import React, { FC, useEffect, useState } from "react";

import "react-dragula/dist/dragula.css";
import "react-confirm-alert/src/react-confirm-alert.css";

import { Grid } from "@material-ui/core";

import Lane from "../../../components/Draging/Lane";
import Content from "../../../components/Draging/Content";
import ButtonIcon from "../../../components/ButtonIcon";
import MateriaFormCreate from "./MateriaFormCreate";
import { useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import subjectsHttp from "../../../utils/http/subjects-http";
import { SubjectType } from "../../../types/subjects";
import { PlanType } from "../../../types/plans";
import { useDispatch } from "react-redux";
import { planActive } from "../../../redux/actions/planActions";

interface MateriaListProps {
  plan: PlanType | null;
  onGetPLan: () => void;
}

const MateriaList: FC<MateriaListProps> = ({ plan, onGetPLan }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [dragId, setDragId] = useState<string>("");
  const [boxes, setBoxes] = useState<SubjectType[]>([]);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (plan?.subjects !== undefined) {
      setBoxes(plan.subjects);
    }
  }, [plan]);

  const handleDrag = (ev: any) => {
    setDragId(ev.currentTarget.id);
  };

  const update = async (subject: SubjectType) => {
    await subjectsHttp.update(subject.id, subject);
  };

  const handleDelete = (id: any) => {
    confirmAlert({
      title: "Deseja realmente excluir?",
      message: "Todas suas atividades dessa matéria serão apagadas.",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            subjectsHttp
              .delete(id)
              .then(() => {
                onGetPLan();
              })
              .catch((e) => {
                console.log(e);
              });
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  };

  const handleDrop = (ev: any) => {
    const id = ev.currentTarget.id;

    const dragBox: any = boxes.find((box) => box.id === dragId);

    const dropBox: any = boxes.find((box) => box.id === id);

    const dragBoxOrder = dragBox.order;
    const dropBoxOrder = dropBox.order;

    const newBoxState = boxes.map((box) => {
      if (box.id === dragId) {
        box.order = dropBoxOrder;
        update(box);
      }
      if (box.id === id) {
        box.order = dragBoxOrder;
        update(box);
      }
      return box;
    });
    dispatch(planActive());
    setBoxes(newBoxState);
  };
  return (
    <React.Fragment>
      <MateriaFormCreate open={open} onOpen={setOpen} plan={plan} />
      <Lane
        title="Matérias"
        description="Lista de matérias desse plano."
        action={
          <ButtonIcon
            title={"Matéria"}
            size={"small"}
            icon={"add"}
            titleTootip={"Adicionar nova matéria"}
            onClick={() => {
              setOpen(true);
            }}
          />
        }
      >
        <Grid container spacing={2}>
          {boxes.length === 0 && (
            <Grid item xs={12}>
              Adicione uma matéria no seu plano, clique no botão "+ Matéria".
            </Grid>
          )}
          {boxes
            .sort((a, b) => a.order - b.order)
            .map((box) => (
              <Grid item xs={12} md={6} key={box.id}>
                <Content
                  key={box.id}
                  id={box.id}
                  label={box.title}
                  subheader={`${box.topics.length} Assunto(s)`}
                  order={box.order}
                  onDrag={handleDrag}
                  onDrop={handleDrop}
                  onDelete={() => {
                    handleDelete(box.id);
                  }}
                  onEdit={() => {
                    history.push(`/materia/${box.id}/edit`);
                  }}
                />
              </Grid>
            ))}
        </Grid>
      </Lane>
    </React.Fragment>
  );
};

export default MateriaList;
