import mock from "../utils/mock";

const userData = {
  id: "ca735eba-0fa2-456b-ab99-986488fa387d",
  email: "demo@bootlab.io",
  nickname: "Leo",
  name: "Leonardy Cardoso",
  estado: {
    id: 11,
    nome: "Rondônia",
  },
  cidade: {
    id: 1,
    state_id: 11,
    nome: "Alta Floresta",
  },
};

mock.onPost("/api/Auth/sign-in").reply((config: any) => {
  const { email, password } = JSON.parse(config.data);

  if (email === "demo@bootlab.io" && password === "unsafepassword") {
    return [200, userData];
  }

  return [401, { message: "Please check your email and password" }];
});

mock.onPost("/api/Auth/sign-up").reply(() => {
  return [200, userData];
});

mock.onPost("/api/Auth/reset-password").reply(() => {
  return [200, userData];
});
