import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components/macro";
import {
  Card as MuiCard,
  CardContent,
  Grid,
  TextField as MuiTextField,
  Typography,
  Box,
  FormLabel,
  FormControlLabel,
  MenuItem,
  Checkbox,
  FormControl,
  FormGroup,
} from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import * as yup from "../../../vendor/yup";
import { spacing } from "@material-ui/system";
import ButtonIcon from "../../../components/ButtonIcon";
import InputMask from "react-input-mask";
import { useSnackbar } from "notistack";
import { format, isDate, parse, parseISO } from "date-fns";
import { ResponseType } from "../../../types/response";
import { makeStyles } from "@material-ui/styles";
import { ActivityForm } from "../../../types/activities";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../redux/reducers";
import { TopicType } from "../../../types/topics";
import { SubjectType } from "../../../types/subjects";
import TypesField from "./TypesField";
import EditorField from "./EditorField";
import { omit } from "lodash";
import { secToTime, timeToSec } from "../../../utils/Times";
import activitiesHttp from "../../../utils/http/activities-http";
import { useHistory } from "react-router-dom";
import { resetTime } from "../../../redux/actions/timeStudyActions";
import { REVISAOID } from "../../../config/constantes";
import Loader from "../../../components/Loader";
import { Type } from "../../../types/types";
import typesHttp from "../../../utils/http/types-http";
import { isEmpty } from "../../../utils/functions";

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const useStyles = makeStyles({
  root: {
    maxWidth: 600,
    marginLeft: "auto",
    marginRight: "auto",
  },
});

interface FormProps {
  id?: string;
}
const validationUrl = yup.object().shape({
  subjectId: yup
    .string()
    .transform((value) => (!value ? undefined : value))
    .default(""),
  topicId: yup
    .string()
    .transform((value) => (!value ? undefined : value))
    .default(""),
  types: yup.mixed().transform(function (value) {
    const newValue: number[] = [];
    value.forEach((row: any) => {
      if (!isNaN(row)) {
        newValue.push(row);
      }
    });
    return newValue;
  }),
  seconds: yup.mixed().transform(function (value) {
    if (!isNaN(value)) {
      return value;
    }
    return 0;
  }),
});
const SchemaValidation = yup.object({
  date: yup
    .date()
    .nullable()
    .required("Obrigatório")
    .transform(function (value: string, originalValue: string) {
      const date = parse(originalValue, "dd/MM/yyyy", new Date());
      return isDate(date) ? date : new Date("");
    })
    .typeError("Data inválida"),
  hour: yup
    .string()
    .label("Hora")
    .nullable()
    .required("Obrigatório")
    .transform(function (value: string) {
      const hour = parseInt(value.substring(0, 2));
      const minute = parseInt(value.substring(3, 5));
      if (hour > 24 || isNaN(hour)) {
        return false;
      }
      if (minute > 59 || isNaN(hour)) {
        return false;
      }
      return value;
    }),
  subject_id: yup.string().nullable().label("Matéria").required("Obrigatório"),
  topic_id: yup.string().nullable().label("Tópico").required("Obrigatório"),
  time: yup
    .string()
    .required("Obrigatório")
    .min(5, "Inválido")
    .transform((value, originalValue) => {
      let hour = parseInt(originalValue.substr("0", "2"), 10);
      let minute = parseInt(originalValue.substr("3", "2"), 10);
      let seg = parseInt(originalValue.substr("6", "6"), 10);

      hour = Number.isNaN(hour) ? 0 : hour;
      minute = Number.isNaN(minute) ? 0 : minute;
      seg = Number.isNaN(seg) ? 0 : seg;

      if (hour > 23) {
        return "f";
      }
      if (minute > 59) {
        return "f";
      }
      if (seg > 59) {
        return "f";
      }
      return (
        hour.toString().padStart(2, "0") +
        ":" +
        minute.toString().padStart(2, "0") +
        ":" +
        seg.toString().padStart(2, "0")
      );
    }),
  types: yup.array().min(1, "Tipo é Requerido").label("Tipo").required(),
  total: yup.number().transform((value) => (Number.isNaN(value) ? 0 : value)),
  hits: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? 0 : value))
    .when("total", (total: number, campo: any) =>
      total > 0
        ? campo.max(total, "Calma! Olha o total de questões! Rumo aprovação!")
        : campo.max(0)
    ),
  reviews: yup.array(),
});

const Form: FC<FormProps> = ({ id }) => {
  const INITIAL_FORM = useMemo(() => {
    const now = new Date();

    return {
      date: format(now, "dd/MM/yyyy"),
      hour: format(now, "HH:mm"),
      subject_id: "",
      topic_id: "",
      types: [],
      time: "",
      total: 0,
      hits: 0,
      notes: "",
      reviews: [],
    };
  }, []);
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm<ActivityForm>({
    resolver: yupResolver(SchemaValidation),
    defaultValues: INITIAL_FORM,
  });
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const snackbar = useSnackbar();
  const [topics, setTopics] = useState<TopicType[]>([]);
  const [data, setData] = useState({
    id: "",
    notes: null,
  });
  const [optionsType, setOptionsType] = useState<Type[]>([]);
  const [loading, setLoading] = useState(false);

  const plan = useSelector((state: AppStateType) => state.planReducer);

  const watchTypes = watch("types");
  const watchSubjectId = watch("subject_id");

  const getStateFromURL = useCallback(() => {
    const queryParams = new URLSearchParams(history.location.search.substr(1));
    return validationUrl.cast({
      subjectId: queryParams.get("subject_id"),
      topicId: queryParams.get("topic_id"),
      types: queryParams.get("types")?.split(",").map(Number),
      seconds: queryParams.get("seconds"),
      date: queryParams.get("date"),
      hour: queryParams.get("hour"),
    });
  }, [history.location.search]);

  const handleSetOptionsTopics = useCallback(() => {
    const subject: SubjectType | undefined = plan.active?.subjects?.find(
      (item) => item.id === watch("subject_id")
    );
    setTopics(subject?.topics || []);
  }, [plan.active.subjects, watch]);

  const changeTypes = useCallback(
    (value) => {
      setValue("types", value);
    },
    [setValue]
  );

  useEffect(() => {
    let active = true;
    (async () => {
      const response = await typesHttp.list<ResponseType<Type[]>>();
      if (active) {
        setOptionsType(response.data.data);
      }
    })();

    return () => {
      active = false;
    };
  }, []);

  useEffect(() => {
    if (!data.id) {
      const {
        subjectId,
        topicId,
        seconds,
        types,
        date,
        hour,
      } = getStateFromURL();

      if (!subjectId) {
        reset(INITIAL_FORM);
      } else {
        reset({
          ...INITIAL_FORM,
          subject_id: subjectId,
          topic_id: topicId,
          types: optionsType.filter((row) => types?.includes(row.id)),
          ...(seconds > 0 && { time: secToTime(seconds, 3) }),
          ...(date !== null && { date: date }),
          ...(hour !== null && { hour: hour }),
        });
      }
    }
    // return () => reset(INITIAL_FORM);
  }, [getStateFromURL, reset, optionsType, INITIAL_FORM, data.id]);

  useEffect(() => {
    handleSetOptionsTopics();
  }, [handleSetOptionsTopics, watchSubjectId]);

  useEffect(() => {
    if (!id) {
      return;
    }

    let isSubscribed = true;
    setLoading(true);
    (async () => {
      try {
        const { data } = await activitiesHttp.getResource<ResponseType<any>>(
          id,
          "show"
        );
        if (isSubscribed) {
          setData(data.data);

          const dataFormated = omit(data.data, ["start"]);
          const reviews = data.data.reviews.map((item: any) =>
            item.type.toString()
          );

          const date = parseISO(data.data.start);

          dataFormated["date"] = format(date, "dd/MM/yyyy");
          dataFormated["hour"] = format(date, "HH:mm");
          dataFormated["reviews"] = reviews;

          handleSetOptionsTopics();

          reset(dataFormated);
        }
      } catch (error) {
        console.error(error);
        snackbar.enqueueSnackbar("Não foi possível carregar as informações", {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    })();
    return () => {
      isSubscribed = false;
    };
  }, [handleSetOptionsTopics, id, reset, snackbar]);

  function handleSelect(checkedName: string) {
    const checkedValues = getValues("reviews");
    const newNames = checkedValues?.includes(checkedName)
      ? checkedValues?.filter((name) => name !== checkedName)
      : [...(checkedValues ?? []), checkedName];

    return newNames;
  }

  const onSubmit: SubmitHandler<ActivityForm> = async (formData) => {
    if (isEmpty(plan.active)) {
      return;
    }

    const sendData = omit(formData, ["date", "hours"]);
    sendData["duration"] = timeToSec(
      formData.time.substr(0, 2),
      formData.time.substr(3, 2),
      formData.time.substr(6, 2)
    );
    sendData["start"] =
      format(formData.date as any, "yyyy-MM-dd") + " " + formData.hour + ":59";

    sendData["types"] = formData["types"].map((type) => type.id);
    sendData["reviews"] = formData["reviews"]
      ? formData["reviews"].map((review) => parseInt(review))
      : [];

    try {
      const http = !id
        ? activitiesHttp.createRelationship(plan?.active?.id, sendData)
        : activitiesHttp.update(id, sendData);
      await http;
      snackbar.enqueueSnackbar("Salvo com sucesso", {
        variant: "success",
      });
      dispatch(resetTime());
      history.goBack();
    } catch (error) {
      console.log(error);
      snackbar.enqueueSnackbar("Não é possível salvar a atividade", {
        variant: "error",
      });
    }
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Estudo
        </Typography>
        <Typography variant="body2" gutterBottom>
          Cadastre sua atividade de estudo.
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={6}>
            <Grid item md={6}>
              <Controller
                name="date"
                control={control}
                render={({ field }) => (
                  <InputMask {...field} mask={"99/99/9999"}>
                    {(inputProps: any) => (
                      <TextField
                        {...inputProps}
                        id="date-id"
                        label="Data"
                        variant="outlined"
                        fullWidth
                        inputProps={{ inputMode: "numeric" }}
                        error={errors.date !== undefined}
                        helperText={errors.date?.message}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  </InputMask>
                )}
              />
            </Grid>
            <Grid item md={6}>
              <Controller
                name="hour"
                control={control}
                render={({ field }) => (
                  <InputMask {...field} mask={"99:99"}>
                    {(inputProps: any) => (
                      <TextField
                        {...inputProps}
                        id="time"
                        label="Hora"
                        variant="outlined"
                        fullWidth
                        inputProps={{ inputMode: "numeric" }}
                        error={errors.hour !== undefined}
                        helperText={errors.hour?.message}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  </InputMask>
                )}
              />
            </Grid>
          </Grid>

          <Controller
            name="subject_id"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                margin={"normal"}
                select
                fullWidth
                error={errors.subject_id !== undefined}
                helperText={errors.subject_id?.message}
                id="subject-id"
                label="Materia"
                variant={"outlined"}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  setValue("topic_id", "");
                  field.onChange(e);
                }}
              >
                <MenuItem value="">
                  <em>Selecione</em>
                </MenuItem>
                {plan?.active?.subjects?.map((item) => (
                  <MenuItem value={item.id} key={item.id}>
                    {item.title}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />

          <Controller
            name="topic_id"
            control={control}
            render={({ field }) => (
              <TextField
                margin={"normal"}
                select
                fullWidth
                id="topic-id"
                error={errors.topic_id !== undefined}
                helperText={errors.topic_id?.message}
                label="Assunto"
                variant={"outlined"}
                InputLabelProps={{ shrink: true }}
                {...field}
              >
                <MenuItem value="">
                  <em>Selecione</em>
                </MenuItem>
                {topics.map((item) => (
                  <MenuItem value={item.id} key={item.id}>
                    {item.title}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />

          <FormControl component="fieldset">
            <FormGroup row={true}>
              <Controller
                name="topic_completed"
                control={control}
                render={({ field }) => {
                  return (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          // onChange={() => onChange(handleSelect("ds"))}
                        />
                      }
                      label={"Assunto concluído"}
                    />
                  );
                }}
              />
            </FormGroup>
          </FormControl>

          <Controller
            name="time"
            control={control}
            render={({ field }) => (
              <InputMask {...field} mask={"99:99:99"}>
                {(inputProps: any) => (
                  <TextField
                    {...inputProps}
                    margin={"normal"}
                    id="time-id"
                    label="Tempo de Estudo"
                    variant="outlined"
                    fullWidth
                    inputProps={{ inputMode: "numeric" }}
                    error={errors.time !== undefined}
                    helperText={errors.time?.message}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              </InputMask>
            )}
          />
          <TypesField
            //typesParams={getStateFromURL().types}
            //typesId={typesId}
            options={optionsType}
            types={watch("types")}
            setTypes={(value) => changeTypes(value)}
            error={errors.types}
          />

          <Grid container spacing={6}>
            <Grid item md={6}>
              <Controller
                name="hits"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin={"normal"}
                    id="hits"
                    label="Acertos"
                    variant="outlined"
                    fullWidth
                    inputProps={{ inputMode: "numeric" }}
                    error={errors.hits !== undefined}
                    helperText={errors.hits?.message}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            <Grid item md={6}>
              <Controller
                name="total"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin={"normal"}
                    id="total"
                    label="Total de Questões"
                    variant="outlined"
                    fullWidth
                    inputProps={{ inputMode: "numeric" }}
                    error={errors.total !== undefined}
                    helperText={errors.total?.message}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <EditorField
            maxLength={200}
            data={data.notes}
            setEditor={(value) =>
              setValue("notes", value, { shouldValidate: true })
            }
            placeholder={"Breve anotação do seu estudo."}
          />
          {!watchTypes.some((type) => type.id === REVISAOID) && (
            <Grid item sm={12} xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Revisões (Dias)</FormLabel>
                <FormGroup row={true}>
                  <Controller
                    name="reviews"
                    control={control}
                    defaultValue={[]}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <>
                          {[
                            "1",
                            "2",
                            "7",
                            "14",
                            "21",
                            "30",
                            "60",
                            "75",
                            "90",
                            "100",
                            "115",
                            "130",
                            "150",
                            "180",
                            "200",
                            "230",
                            "260",
                          ].map((name) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={value.includes(name)}
                                  onChange={() => onChange(handleSelect(name))}
                                />
                              }
                              key={name}
                              label={name}
                            />
                          ))}
                        </>
                      );
                    }}
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          )}

          <Box style={{ marginTop: "10px" }}>
            <ButtonIcon
              title={"Salvar"}
              titleTootip={"Salvar informações"}
              icon={"save"}
              type={"submit"}
              disabled={loading}
            />
          </Box>
        </form>
      </CardContent>
    </Card>
  );
};

export default Form;
