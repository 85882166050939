import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { useHistory } from "react-router-dom";

import "react-dragula/dist/dragula.css";

import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  IconButton as MuiIconButton,
  CardHeader,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography as MuiTypography,
  Chip as MuiChip,
} from "@material-ui/core";
import { spacing, SpacingProps } from "@material-ui/system";
import { AddBox as AddBoxIcon, Timer as TimerIcon } from "@material-ui/icons";

import planningsHttp from "../../../../utils/http/plannings-http";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../../redux/reducers";
import { weekDays } from "../../../../config/constantes";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Loader from "../../../../components/Loader";
import { Type } from "../../../../types/types";
import {
  resetTime,
  setIsOpen,
  setSubjectId,
  setTopicId,
  setTypeStudy,
} from "../../../../redux/actions/timeStudyActions";
import WarningPage from "../../../../components/WarningPage";

const CardContent = styled(MuiCardContent)<{ pb?: number }>`
  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)};
  }
`;
const Card = styled(MuiCard)(spacing);

const IconButton = styled(MuiIconButton)(spacing);

const Typography = styled(MuiTypography)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
`;
const ChipStyle = withStyles((theme) => ({
  sizeSmall: {
    fontSize: 9,
    padding: 0,
    height: 18,
    marginTop: 5,
  },
  labelSmall: {
    paddingLeft: 3,
    paddingRight: 3,
  },
}))(MuiChip);

interface MuiChipSpacingType extends SpacingProps {
  cor?: string;
}

const Chip = styled(ChipStyle)<MuiChipSpacingType>`
  margin-right: 3px;
  background-color: ${(props) => props.cor && props.cor};
  color: ${(props) => props.cor && props.theme.palette.common.white};
`;

const useStyles = makeStyles({
  through: {
    textDecoration: "line-through",
  },
});

const getDaily = () => {
  const date = new Date();
  const dayweek = date.getDay() === 0 ? 7 : date.getDay();
  const day = weekDays.filter((row) => row.cod === dayweek.toString());
  return day[0];
};

function DailyMetas() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [plannings, setPlannings] = useState<any>({
    max: 0,
    weeks: {
      Seg: [],
      Ter: [],
      Qua: [],
      Qui: [],
      Sex: [],
      Sab: [],
      Dom: [],
    },
  });
  const plan = useSelector((state: AppStateType) => state.planReducer);

  useEffect(() => {
    const getPlanning = async () => {
      setLoading(true);
      if (!plan.active.id) {
        return;
      }
      try {
        const { data } = await planningsHttp.getResource(
          plan.active.id,
          "calendar"
        );
        if (data.data) {
          setPlannings(data.data);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };

    getPlanning();
  }, [plan.active.id]);

  const handleStudy = (
    type: string,
    subjectId: string,
    topicId: string | null,
    types: Type[]
  ) => {
    const typesId = types.map((row) => row.id);
    if (type === "manual") {
      history.push(
        `/activity/create?subject_id=${subjectId}&topic_id=${topicId}&types=${typesId}`
      );
    }
    if (type === "cronometro") {
      dispatch(resetTime());
      dispatch(setSubjectId(subjectId));
      dispatch(setTopicId(topicId));
      dispatch(setTypeStudy(typesId));
      dispatch(setIsOpen(true));
    }
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <React.Fragment>
      {plannings.max > 0 ? (
        <Grid justify="space-between" container spacing={4}>
          <Grid item>
            <Typography variant="caption">Sua meta diária</Typography>
          </Grid>

          <Grid item xs={12}>
            <Card mb={3}>
              <CardHeader title={`Metas Díária - ${getDaily()["weeky"]}`} />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TableWrapper>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Matéria
                            </TableCell>
                            <TableCell component="th" scope="row">
                              Tipo
                            </TableCell>

                            <TableCell width={"25%"}>Ações</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {plannings.weeks[getDaily()["codLabel"]].length >
                          0 ? (
                            plannings.weeks[getDaily()["codLabel"]].map(
                              (row: any) => (
                                <TableRow
                                  key={row.id}
                                  className={`${
                                    row.concluded && classes.through
                                  }`}
                                >
                                  <TableCell>
                                    <b>{row.subject}</b>
                                    <br />
                                    {row.topic && row.topic}
                                  </TableCell>
                                  <TableCell>
                                    {row.types.map(
                                      (row: any, index: number) => (
                                        <Chip
                                          label={row.title}
                                          size={"small"}
                                          cor={row.color}
                                          m={1}
                                          key={index}
                                        />
                                      )
                                    )}
                                  </TableCell>

                                  <TableCell>
                                    <IconButton
                                      mr={2}
                                      color="primary"
                                      onClick={() =>
                                        handleStudy(
                                          "manual",
                                          row.subject_id,
                                          row.topic_id,
                                          row.types
                                        )
                                      }
                                    >
                                      <AddBoxIcon fontSize="default" />
                                    </IconButton>
                                    <IconButton
                                      mr={2}
                                      color="primary"
                                      onClick={() =>
                                        handleStudy(
                                          "cronometro",
                                          row.subject_id,
                                          row.topic_id,
                                          row.types
                                        )
                                      }
                                    >
                                      <TimerIcon fontSize="default" />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              )
                            )
                          ) : (
                            <TableRow>
                              <TableCell colSpan={3}>
                                Nenhuma meta hoje!
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableWrapper>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            {/*<Lane*/}
            {/*  title="Metas diária"*/}
            {/*  description="Atividades para hoje!"*/}
            {/*  onContainerLoaded={onContainerReady}*/}
            {/*>*/}
            {/*  <Task content={demoTasks[0]} />*/}
            {/*  <Task content={demoTasks[1]} />*/}
            {/*</Lane>*/}
          </Grid>
        </Grid>
      ) : (
        <WarningPage
          title={"Nenhuma meta adicionada!"}
          subtitle={
            "Clique em CONFIGURAR METAS para configurar seu cronograma semanal."
          }
          img="/static/img/simulados.svg"
        />
      )}
    </React.Fragment>
  );
}

export default DailyMetas;
