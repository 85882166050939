import React, { ReactNode } from "react";
import styled from "styled-components/macro";
import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  Typography as MuiTypography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";

const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)<{ pb?: number }>`
  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;
const Typography = styled(MuiTypography)(spacing);
interface LaneProps {
  title: string;
  description?: string;
  children: ReactNode;
}

const Lane = ({ title, description, children }: LaneProps) => {
  return (
    <Card mb={6}>
      <CardContent pb={0}>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        {description && (
          <Typography variant="body2" mb={4}>
            {description}
          </Typography>
        )}
        <div id="seg" style={{ minHeight: 100 }}>
          {children}
        </div>
      </CardContent>
    </Card>
  );
};

export default Lane;
