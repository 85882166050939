import "draft-js/dist/Draft.css";
import { FC, useEffect, useMemo, useState } from "react";
import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { convertToHTML } from "draft-convert";

import subjectsHttp from "../../../../utils/http/subjects-http";
import { useFilters, useGlobalFilter, useSortBy, useTable } from "react-table";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { format } from "date-fns";
import { convertFromRaw, EditorState } from "draft-js";
import parse from "html-react-parser";
import { NavLink } from "react-router-dom";
import { Edit as EditIcon } from "@material-ui/icons";

const Card = styled(MuiCard)(spacing);

interface CadernoType {
  subjectId: string;
}

const Notes: FC<CadernoType> = ({ subjectId }) => {
  const [notes, setNotes] = useState<
    {
      id: string;
      topic: string;
      notes: string;
      updated_at: string;
    }[]
  >([]);
  const snackbar = useSnackbar();
  useEffect(() => {
    async function getNotes() {
      if (subjectId) {
        try {
          const { data } = await subjectsHttp.getResource(subjectId, "notes");
          setNotes(data.data);
        } catch (e) {
          console.log(e.error);
          snackbar.enqueueSnackbar("Não foi possível salvar as informações", {
            variant: "error",
          });
        }
      }
    }

    getNotes();
  }, [subjectId, snackbar]);

  const data = useMemo(() => notes, [notes]);
  const columns: any = useMemo(
    () => [
      {
        Header: "Data",
        accessor: "updated_at",
        Cell: ({ value }: any) => {
          return format(new Date(value), "dd/MM/yy HH:mm:ss");
        },
      },
      {
        Header: "Tópico",
        accessor: "topic",
      },
      {
        Header: "Notas",
        accessor: "notes",
        Cell: ({ value }: any) => {
          const text = JSON.parse(value);
          const editor = EditorState.createWithContent(convertFromRaw(text));

          return parse(convertToHTML(editor.getCurrentContent()));
        },
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      manualSortBy: true,
      autoResetSortBy: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );
  const { headerGroups, rows, prepareRow } = tableInstance;
  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Card mb={6}>
          <CardHeader title="Notas" />
          <CardContent>
            <TableContainer>
              <MuiTable
                aria-labelledby="notas"
                size={"medium"}
                aria-label="notas"
              >
                <TableHead>
                  {headerGroups.map((headerGroup, index) => (
                    <TableRow
                      {...headerGroup.getHeaderGroupProps()}
                      key={index}
                    >
                      {headerGroup.headers.map((column: any, i) => (
                        <TableCell {...column.getHeaderProps()} key={i}>
                          {column.render("Header")}
                        </TableCell>
                      ))}
                      <TableCell>Ação</TableCell>
                    </TableRow>
                  ))}
                </TableHead>
                <TableBody>
                  {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <TableRow {...row.getRowProps()} hover key={i}>
                        {row.cells.map((cell: any, index) => (
                          <TableCell {...cell.getCellProps()} key={index}>
                            {cell.render("Cell")}
                          </TableCell>
                        ))}
                        <TableCell>
                          <IconButton
                            aria-label="edit"
                            component={NavLink}
                            to={`/activity/${row.original.id}/edit`}
                            size={"small"}
                          >
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}

                  {notes.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={7}>
                        Nenhuma nota cadastrada.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </MuiTable>
            </TableContainer>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Notes;
