import React, { FC, useEffect } from "react";
import useStopwatch from "../../vendor/react-timer-hook/useStopwatch";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Box, Button, Collapse, Grid as MuiGrid } from "@material-ui/core";
import styled from "styled-components/macro";
import { useHistory } from "react-router-dom";
import { setIsOpen } from "../../redux/actions/timeStudyActions";
import { REVISAOID } from "../../config/constantes";
import { format, parseISO } from "date-fns";

const Grid = styled(MuiGrid)`
  padding-left: 5px;
  padding-right: 5px;
`;

const StopWatch: FC = () => {
  const {
    seconds,
    minutes,
    hours,
    start,
    pause,
    reset,
    isRunning,
  } = useStopwatch();
  const dispatch = useDispatch();
  const snackbar = useSnackbar();
  const history = useHistory();
  const timeStudy = useSelector((state: any) => state.timeStudyReducer);
  const settings = useSelector((state: any) => state.settingsReducer);
  const active = timeStudy.time.seconds > 0;

  useEffect(() => {
    if (timeStudy.isRunning && !settings.timer) {
      pause();
      start();
    }
  }, [start, pause, settings.timer, timeStudy.isRunning]);

  function startStudy() {
    if (!timeStudy.subjectId || !timeStudy.topicId) {
      snackbar.enqueueSnackbar("Selecione uma matéria e um assunto", {
        variant: "warning",
      });
      return;
    }
    start();
  }

  function save() {
    const urlParams = new URLSearchParams({
      subject_id: timeStudy.subjectId,
      topic_id: timeStudy.topicId,
      ...(timeStudy.typeStudy.length > 0 && { types: timeStudy.typeStudy }),
      ...(timeStudy.time.seconds > 0 && { seconds: timeStudy.time.seconds }),
      date: format(
        typeof timeStudy.date === "string"
          ? parseISO(timeStudy.date)
          : timeStudy.date,
        "dd/MM/y"
      ),
      hour: format(
        typeof timeStudy.date === "string"
          ? parseISO(timeStudy.date)
          : timeStudy.date,
        "HH:mm"
      ),
    });

    history.push(`/activity/create?${urlParams}`);
    dispatch(setIsOpen(false));
  }

  return (
    <div style={{ textAlign: "center" }}>
      {active && (
        <div style={{ fontSize: "50px" }}>
          <span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
        </div>
      )}

      <Collapse in={!isRunning}>
        {!active ? (
          <Box my={3} mx={5}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth={true}
              onClick={startStudy}
            >
              {timeStudy.typeStudy.includes(REVISAOID)
                ? "Iniciar Revisão"
                : "Iniciar Estudo"}
            </Button>
          </Box>
        ) : (
          <Grid container>
            <Grid item lg={6} xs={12}>
              <Box my={3} mx={2}>
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  fullWidth={true}
                  onClick={save}
                >
                  Salvar
                </Button>
              </Box>
            </Grid>
            <Grid item lg={6} xs={12}>
              <Box my={3} mx={2}>
                <Button
                  variant="contained"
                  color="default"
                  size="medium"
                  fullWidth={true}
                  onClick={startStudy}
                >
                  Continuar
                </Button>
              </Box>
            </Grid>
          </Grid>
        )}
      </Collapse>
      <Collapse in={isRunning}>
        <Grid container>
          <Grid item lg={6} xs={12}>
            <Box my={3} mx={2}>
              <Button
                variant="contained"
                color="secondary"
                size="medium"
                fullWidth={true}
                onClick={pause}
              >
                Pausar
              </Button>
            </Box>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Box my={3} mx={2}>
              <Button
                variant="contained"
                color="default"
                size="medium"
                fullWidth={true}
                onClick={reset}
              >
                Cancelar
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Collapse>
    </div>
  );
};
export default StopWatch;
