import React from "react";

import Introduction from "./components/Introduction";

function Migration() {
  return (
    <React.Fragment>
      <Introduction />
    </React.Fragment>
  );
}

export default Migration;
