import React from "react";
import styled, { withTheme, ThemeProps } from "styled-components/macro";

import {
  Card as MuiCard,
  CardHeader,
  Paper as MuiPaper,
  LinearProgress as MuiLinearProgress,
  Theme,
  Box,
  Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

interface ProgressoProps extends ThemeProps<Theme> {
  title?: string;
  subtitle?: string;
  value: number;
  alt?: number | string;
  padding?: number | string;
}

const Card = styled(MuiCard)(spacing);

const Paper = styled(MuiPaper)<{ padding: string | number }>`
  padding: ${(props) => props.padding}px;
`;

const LinearProgress = styled(MuiLinearProgress)<ProgressoProps>`
  height: ${(props) => props.alt}px;
  width: 100%;
  border-radius: 3px;
  background: ${(props) => props.theme.palette.grey[200]};
`;

function Progresso({
  title,
  subtitle,
  value,
  alt = 14,
  padding = 10,
}: ProgressoProps) {
  return (
    <Card mb={1}>
      {title && <CardHeader title={title} subheader={subtitle} />}
      <Paper padding={padding}>
        <Box display="flex" alignItems="center">
          <Box width="100%" mr={1}>
            <LinearProgress
              variant="determinate"
              value={value > 100 ? 100 : value}
              color="secondary"
              alt={alt}
            />
          </Box>
          <Box minWidth={35}>
            <Typography variant="body1">{`${value}%`}</Typography>
          </Box>
        </Box>
      </Paper>
    </Card>
  );
}

export default withTheme(Progresso);
