import React from "react";
import styled from "styled-components/macro";

import {
  Box,
  Card,
  CardContent as MuiCardContent,
  CardHeader,
  Chip as MuiChip,
  Typography as MuiTypography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;

const Chip = styled(MuiChip)`
  position: absolute;
  top: 16px;
  right: 16px;
  height: 20px;
  padding: 4px 0;
  font-size: 85%;
  background-color: ${(props) => props.theme.palette.secondary.main};
  color: ${(props) => props.theme.palette.common.white};
  margin-bottom: ${(props) => props.theme.spacing(4)}px;

  span {
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

type CardStatusPropsType = {
  title: string;
  content: any;
  chip?: string;
  footerContent?: any;
};

const CardStatus: React.FC<CardStatusPropsType> = ({
  title,
  content,
  chip,
  footerContent,
}) => {
  return (
    <Card>
      <CardHeader title={title} />
      <CardContent>
        <Typography variant="h3" mb={6}>
          <Box fontWeight="fontWeightRegular">{content}</Box>
        </Typography>
        {footerContent && (
          <Typography variant="subtitle2" color="textSecondary">
            {footerContent}
          </Typography>
        )}
        {chip && <Chip label={chip} />}
      </CardContent>
    </Card>
  );
};

export default CardStatus;
