import React, { FC, useEffect, useState } from "react";
import styled from "styled-components/macro";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid,
  Typography as MuiTypography,
  TextField,
  CircularProgress,
  InputLabel,
  MenuItem,
  Card as MuiCard,
  FormControl as MuiFormControl,
  CardContent as MuiCardContent,
  Select as MuiSelect,
  ListItem,
  ListItemText as MuiListItemText,
  Avatar as MuiAvatar,
  List,
  ListItemSecondaryAction,
  IconButton,
} from "@material-ui/core";

import { spacing, SpacingProps } from "@material-ui/system";
import plansExampleHttp from "../../../../utils/http/plans-examples-http";
import { useDebounce } from "use-debounce";
import { useSnackbar } from "notistack";
import Loader from "../../../../components/Loader";
import { AreaType, PlansExample } from "../../../../types/plans";
import { ResponseType } from "../../../../types/response";
import { useDispatch } from "react-redux";
import { setPlanActive } from "../../../../redux/actions/planActions";
import areasHttp from "../../../../utils/http/areas-http";
import { Save as SaveIcon } from "@material-ui/icons";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Divider = styled(MuiDivider)(spacing);

interface TypographyPropsType extends SpacingProps {
  component?: string;
}

interface SelectMuiType extends SpacingProps {
  mt?: number;
}

const Typography = styled(MuiTypography)<TypographyPropsType>(spacing);

const FormControlSpacing = styled(MuiFormControl)(spacing);

const FormControl = styled(FormControlSpacing)<{ m?: number }>``;

const Select = styled(MuiSelect)<SelectMuiType>(spacing);

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Avatar = styled(MuiAvatar)(spacing);

const ListItemText = styled(MuiListItemText)(spacing);

interface ListPlansType {
  title?: string;
  subtittle?: string;
  onCallbackCopy: () => void;
}

const ListPlans: FC<ListPlansType> = ({ title, subtittle, onCallbackCopy }) => {
  const snackbar = useSnackbar();
  const dispatch = useDispatch();
  const [plans, setPlans] = useState<PlansExample[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [areas, setAreas] = useState<AreaType[]>([]);
  const [area, setArea] = useState<string | unknown>("");

  const [loadingCopy, setLoadingCopy] = useState({
    id: 0,
    loading: false,
  });
  const [isSearching, setIsSearching] = useState(false);
  const [textsearch, setTextSearch] = useState<string>("");
  const [debounceText] = useDebounce(textsearch, 500);

  useEffect(() => {
    setIsSearching(true);
    let isSubscribed = true;
    (async () => {
      try {
        const { data } = await plansExampleHttp.list<
          ResponseType<PlansExample[]>
        >({
          queryParam: { plan: debounceText, area: area },
        });
        if (isSubscribed) {
          setPlans(data.data);
        }
      } catch (error) {
        console.error(error);
        snackbar.enqueueSnackbar("Não foi possível carregar as informações", {
          variant: "error",
        });
      } finally {
        setLoading(false);
        setIsSearching(false);
      }
    })();
    return () => {
      isSubscribed = false;
    };
  }, [debounceText, snackbar, area]);

  useEffect(() => {
    const getAreas = async () => {
      try {
        const { data } = await areasHttp.list<ResponseType<AreaType[]>>();
        setAreas(data.data);
      } catch (error) {
        console.error(error);
        snackbar.enqueueSnackbar("Não foi possível carregar as informações", {
          variant: "error",
        });
      }
    };
    getAreas();
  }, [snackbar]);

  const copy = async (id: number) => {
    setLoadingCopy({
      id: id,
      loading: true,
    });

    try {
      const { data } = await plansExampleHttp.copy(id);
      dispatch(setPlanActive(data.data));
      onCallbackCopy();
    } catch (error) {
      console.error(error);
      snackbar.enqueueSnackbar("Não foi possível copiar o plano", {
        variant: "error",
      });
    } finally {
      setLoadingCopy({
        id: 0,
        loading: false,
      });
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTextSearch(event.target.value);
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <React.Fragment>
      <Grid justify="space-between" container spacing={10}>
        <Grid item>
          {title && (
            <Typography variant="h3" gutterBottom display="inline">
              {title}
            </Typography>
          )}
          {subtittle && (
            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
              <Typography>{subtittle}</Typography>
            </Breadcrumbs>
          )}
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} sm={6}>
          <TextField
            id="standard-search"
            label="Pesquisar"
            variant="outlined"
            fullWidth
            value={textsearch}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <>
                  {isSearching && (
                    <CircularProgress color="inherit" size={20} />
                  )}
                </>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="type-label">Área</InputLabel>
            <Select
              labelId="type-select-outlined-label"
              id="select-area"
              label="type-label"
              value={area}
              onChange={(e) => setArea(e.target.value)}
            >
              <MenuItem value={""}>Todas</MenuItem>
              {areas.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.area}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6} id={"list-plans-prontos"}>
            <CardContent pb={0}>
              <Typography variant="h6" gutterBottom>
                Planos de Estudos Prontos
              </Typography>
              <Typography variant="body2" gutterBottom>
                Escolha um e comece a estudar!
              </Typography>
            </CardContent>
            <List>
              {plans.map((plan, index) => {
                const isLoading =
                  loadingCopy.id === plan.id ? loadingCopy.loading : false;
                return (
                  <ListItem button onClick={() => copy(plan.id)} key={plan.id}>
                    <Avatar mr={3}>
                      <img
                        src={
                          plan.thumbnail
                            ? `/storage/${plan.thumbnail}`
                            : "/static/img/logo/logo.png"
                        }
                        alt={plan.plan}
                      />
                    </Avatar>

                    <ListItemText
                      primary={`${plan.plan} - ${plan.area.area}`}
                      secondary={`Edital do ano de ${plan.year}`}
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => copy(plan.id)}
                      >
                        {isLoading ? <Loader /> : <SaveIcon />}
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ListPlans;
