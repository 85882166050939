/* eslint-disable import/first */
import React from "react";

import {
  Calendar as CalendarIcon,
  CreditCard,
  Users,
  Folder,
  Archive,
  Clipboard,
  RefreshCcw,
  Inbox,
  PieChart,
} from "react-feather";

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Guards
import AuthGuard from "../components/AuthGuard";

// Auth components
import SignIn from "../pages/Auth/SignIn";
//import SignUp from "../pages/Auth/SignUp";
import ResetPassword from "../pages/Auth/ResetPassword";
import Page404 from "../pages/Auth/Page404";
import Page500 from "../pages/Auth/Page500";

//Planning
import Cycle from "../pages/Cycle";

//Resumo
import Painel from "../pages/Resumo";

//Materia
import Materia from "../pages/Materia";

//Activity
import ActivityForm from "../pages/Activity/ActivityForm";

//Revisões
import Revisoes from "../pages/Revisoes";

// Simulados
import Simulados from "../pages/Simulados";
import AssessmentsForm from "../pages/Simulados/AssessmentsForm";
import AssessmentsShow from "../pages/Simulados/AssessmentsShow";

import Relatorios from "../pages/Simulados/Relatorios";

//Calendario
import Calendario from "../pages/Calendario";

// Histórico
import Historico from "../pages/Historico";

// Planos de Estudo
import Meus from "../pages/Planos/meus";
import PlanoFormEdit from "../pages/Planos/meus/PlanoFormEdit";
import MateriaFormEdit from "../pages/Planos/meus/MateriaFormEdit";

//Assinatura
import Assinatura from "../pages/Assinatura/";

//Conta
import Conta from "../pages/Conta/";

import Configure from "../pages/Cycle/configure";

import Migration from "../pages/Migration";
import FullScreen from "../pages/Planos/prontos/FullScreen";
import ResetPasswordForm from "../pages/Auth/ResetPasswordForm";
import Schedule from "../pages/Planning/Schedule ";
import Success from "../pages/Assinatura/Success";
import Manage from "../pages/Assinatura/Manage";
import Suporte from "../pages/Suporte";
import Completed from "../pages/Registration/Completed";

const resumoRoutes = {
  id: "Estudos ",
  path: "/",
  header: "Principal",
  icon: <Inbox />,
  component: Painel,
  children: null,
  guard: AuthGuard,
};

const planningRoutes = {
  id: "Planejamento",
  path: "/planning",
  icon: <Clipboard />,
  children: [
    {
      path: "/planning/cycle",
      name: "Ciclo de Estudos",
      component: Cycle,
      guard: AuthGuard,
    },
    {
      path: "/planning/schedule",
      name: "Cronograma Semanal",
      component: Schedule,
      guard: AuthGuard,
    },
  ],
  component: null,
  guard: AuthGuard,
};

// const cycleRoutes = {
//   id: "Ciclo de Estudo",
//   path: "/cycle-study",
//   icon: <RefreshCw />,
//   component: Cycle,
//   children: null,
//   guard: AuthGuard,
// };

const cycleConfigure = {
  id: "Configurar Ciclo",
  path: "/planning/cycle/configure",
  component: Configure,
  children: null,
  guard: AuthGuard,
};
// const dashboardsRoutes = {
//   id: "Metas",
//   path: "/goals",
//   component: Metas,
//   icon: <BookOpen />,
//   containsHome: true,
//   children: null,
//   guard: AuthGuard,
// };

const materiasRoutes = {
  id: "Relatórios",
  path: "/materia",
  icon: <PieChart />,
  children: [
    {
      path: "/materia",
      name: "Todas matérias",
      component: Materia,
      guard: AuthGuard,
    },
  ],
  component: null,
  guard: AuthGuard,
};

const materiaLinkRoute = {
  id: "Materia ID",
  path: "/materia/:id",
  name: "materia",
  component: Materia,
  children: null,
  guard: AuthGuard,
};

const simuladosRoutes = {
  id: "Simulados",
  path: "/simulados",
  icon: <Clipboard />,
  children: [
    {
      path: "/simulados",
      name: "Lista",
      component: Simulados,
      guard: AuthGuard,
    },
    {
      path: "/simulados/resumo",
      name: "Relatórios",
      component: Relatorios,
      guard: AuthGuard,
    },
  ],
  component: null,
  guard: AuthGuard,
};

const assessmentsCreate = {
  id: "Adicionar Simulado",
  path: "/assessments/create",
  component: AssessmentsForm,
  children: null,
  guard: AuthGuard,
};

const assessmentEdit = {
  id: "Editar Simulados",
  path: "/assessment/:id/edit",
  component: AssessmentsForm,
  children: null,
  guard: AuthGuard,
};

const assessmentShow = {
  id: "Exibir Simulados",
  path: "/assessment/:id/show",
  component: AssessmentsShow,
  children: null,
  guard: AuthGuard,
};

const revisoesRoutes = {
  id: "Revisões",
  path: "/revisoes",
  icon: <RefreshCcw />,
  component: Revisoes,
  children: null,
  guard: AuthGuard,
};

const calendarRoutes = {
  id: "Calendario",
  path: "/Calendario",
  icon: <CalendarIcon />,
  component: Calendario,
  children: null,
  guard: AuthGuard,
};

const historicoRoutes = {
  id: "Histórico",
  path: "/historico",
  icon: <Archive />,
  component: Historico,
  children: null,
  guard: AuthGuard,
};

const authRoutes = {
  id: "Auth",
  path: "/Auth",
  icon: <Users />,
  children: [
    {
      path: "/Auth/sign-in",
      name: "Sign In",
      component: SignIn,
    },
    // {
    //   path: "/Auth/sign-up",
    //   name: "Sign Up",
    //   component: SignUp,
    // },
    {
      path: "/Auth/reset-password",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: "/Auth/reset-form",
      name: "Reset Password Form",
      component: ResetPasswordForm,
    },
    {
      path: "/Auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/Auth/500",
      name: "500 Page",
      component: Page500,
    },
  ],
  component: null,
};

const planosRoutes = {
  id: "Plano de Estudo",
  path: "/planos/meus-planos",
  header: "Configurações",
  icon: <Folder />,
  children: null,
  guard: AuthGuard,
  component: Meus,
};

const planosFullScreenRoutes = {
  id: "Planos ",
  path: "/planos/selecionar",
  component: FullScreen,
  children: null,
  guard: AuthGuard,
};

// const planosForm = {
//   id: "Cadastro Plano",
//   path: "/Planos/create",
//   icon: <CalendarIcon />,
//   component: PlanosForm,
//   children: null,
// };

const planosEdit = {
  id: "Editar Plano",
  path: "/plano/:id/edit",
  component: PlanoFormEdit,
  children: null,
  guard: AuthGuard,
};

const materiaEdit = {
  id: "Editar Materia",
  path: "/materia/:id/edit",
  component: MateriaFormEdit,
  children: null,
  guard: AuthGuard,
};

const activityCreate = {
  id: "Criar Atividade",
  path: "/activity/create",
  component: ActivityForm,
  children: null,
  guard: AuthGuard,
};
const activityEdit = {
  id: "Criar Atividade",
  path: "/activity/:id/edit",
  component: ActivityForm,
  children: null,
  guard: AuthGuard,
};

const assinaturaRoutes = {
  id: "Assinatura",
  path: "/billing",
  icon: <CreditCard />,
  component: Assinatura,
  children: null,
  guard: AuthGuard,
};

const billingSuccessRoutes = {
  id: "Success",
  path: "/billing/success",
  icon: <CreditCard />,
  component: Success,
  children: null,
  guard: AuthGuard,
};

const billingManageRoutes = {
  id: "Manage",
  path: "/billing/manage",
  icon: <CreditCard />,
  component: Manage,
  children: null,
  guard: AuthGuard,
};

const contaRoutes = {
  id: "Conta",
  path: "/conta",
  icon: <Users />,
  component: Conta,
  children: null,
  guard: AuthGuard,
};

const migrationRoutes = {
  id: "Migration",
  path: "/migration",
  component: Migration,
  children: null,
  guard: AuthGuard,
};

const supportRoutes = {
  id: "Suporte",
  path: "/support",
  component: Suporte,
  children: null,
};

const registrationCompletedRoutes = {
  id: "register",
  path: "/register-completed",
  component: Completed,
  children: null,
  guard: AuthGuard,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  resumoRoutes,
  planningRoutes,
  materiasRoutes,
  materiaLinkRoute,
  revisoesRoutes,
  simuladosRoutes,
  assessmentsCreate,
  assessmentEdit,
  assessmentShow,
  calendarRoutes,
  historicoRoutes,
  planosRoutes,
  planosEdit,
  materiaEdit,
  assinaturaRoutes,
  billingSuccessRoutes,
  billingManageRoutes,
  contaRoutes,
  activityCreate,
  activityEdit,
  cycleConfigure,
  supportRoutes,
];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes that are protected
export const presentationLayoutRoutes = [
  migrationRoutes,
  planosFullScreenRoutes,
  registrationCompletedRoutes,
];

// Routes visible in the sidebar
export const sidebarRoutes = [
  resumoRoutes,
  planningRoutes,
  revisoesRoutes,
  simuladosRoutes,
  materiasRoutes,
  calendarRoutes,
  historicoRoutes,
  planosRoutes,
  assinaturaRoutes,
  contaRoutes,
];
