import { useSnackbar } from "notistack";
import { clone } from "lodash";
const useCollectionManager = (
  collection: any[],
  setCollection: (item: any) => void
) => {
  const snackbar = useSnackbar();
  function findIndex(value: string) {
    return collection.findIndex((i: any) => i.title === value);
  }

  function isExist(value: string) {
    return collection.find((i) => i.title === value);
  }

  return {
    addItem(item: any) {
      if (!item || item.title === "") {
        return;
      }

      if (isExist(item.title)) {
        snackbar.enqueueSnackbar("Item já adicionado", { variant: "info" });
        return;
      }
      const newCollection = clone(collection);
      newCollection.push(item);
      setCollection(newCollection);
    },
    removeItem(item: any) {
      const index = findIndex(item);
      if (index === -1) {
        return;
      }
      const newCollection = clone(collection);
      newCollection.splice(index, 1);

      /* reordernando */

      newCollection.map((item, index) => (item.order = index + 1));
      setCollection(newCollection);
    },
    updateItem(value: string, valueNew: string) {
      if (valueNew === "") {
        return;
      }

      if (isExist(valueNew)) {
        snackbar.enqueueSnackbar("Item já adicionado", { variant: "info" });
        return;
      }

      const index = findIndex(value);
      if (index === -1) {
        return;
      }
      collection[index] = { ...collection[index], title: valueNew };
      setCollection(collection);
    },
  };
};

export default useCollectionManager;
