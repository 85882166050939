import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import {
  Grid,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { ResponsePaginateType } from "../../types/response";
import { useSnackbar } from "notistack";
import { AppStateType } from "../../redux/reducers";
import { useSelector } from "react-redux";
import activitiesHttp from "../../utils/http/activities-http";
import Search from "./Components/Search";
import Table from "./Components/Table";
import { ActivityType } from "../../types/activities";
import useFilter from "./Hooks/useFilter";
import { confirmAlert } from "react-confirm-alert";
import WarningPage from "../../components/WarningPage";
import Loader from "../../components/Loader";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function Historico() {
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const { pushHistory, filter, dispatch, formatSearchParams } = useFilter();

  const [activities, setActivities] = useState<
    ResponsePaginateType<ActivityType>
  >({
    data: [],
    links: {
      first: "",
      last: "",
      prev: "",
      next: "",
    },
    meta: {
      current_page: 0,
      from: 0,
      last_page: 0,
      path: "",
      per_page: 0,
      to: 0,
      total: 0,
    },
  });
  const plan = useSelector((state: AppStateType) => state.planReducer);
  const getActivities = useCallback(async () => {
    setLoading(true);
    if (plan.active.id) {
      pushHistory();
      try {
        const { data } = await activitiesHttp.listRelationship<
          ResponsePaginateType<ActivityType>
        >(plan.active.id, { queryParam: formatSearchParams() });

        setActivities(data);
      } catch (error) {
        console.error(error);
        snackbar.enqueueSnackbar("Não foi possível carregar as informações!", {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    }
  }, [formatSearchParams, plan.active, snackbar, pushHistory]);

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getActivities();
    }
    return () => {
      isSubscribed = false;
    };
  }, [getActivities]);

  const handleDelete = (id: any) => {
    confirmAlert({
      title: "Deseja realmente excluir?",
      message: "Sua atividade de estudo será excluída permanente",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            activitiesHttp.delete(id).then(() => {
              getActivities();
            });
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <React.Fragment>
      <Helmet title="Histórico" />
      <Typography variant="h3" gutterBottom display="inline">
        Histórico
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Histórico</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} xl={3}>
          <Search
            plan={plan.active}
            getActivities={getActivities}
            filter={filter}
            dispatch={dispatch}
          />
        </Grid>
        <Grid item xs={12} xl={9}>
          {loading ? (
            <Loader />
          ) : activities.data.length === 0 ? (
            <WarningPage
              title={"Nenhuma atividade de estudo!"}
              subtitle={"E aí? Está esperando o que para começar a estudar?!?!"}
              img="https://material-app.bootlab.io/static/img/illustrations/waiting.png"
              titleButton={"Como começo?"}
              onClick={() => {
                window.open(
                  "https://www.youtube.com/watch?v=lnjRV2aA_ew",
                  "_blank"
                );
              }}
            />
          ) : (
            <Table
              activities={activities}
              getActivities={getActivities}
              onDelete={handleDelete}
              filter={filter}
              dispatch={dispatch}
            />
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Historico;
