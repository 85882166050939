import * as React from "react";
import { Dialog, DialogContent } from "@material-ui/core";
import Form from "./Form";

interface FormType {
  open: boolean;
  setOpen: (value: boolean) => void;
  getPlanning: () => void;
}
const MetaForm: React.FC<FormType> = ({ open, setOpen, getPlanning }) => {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogContent>
        <Form onClose={handleClose} getPlanning={getPlanning} />
      </DialogContent>
    </Dialog>
  );
};

export default MetaForm;
