import * as types from "../../constants";
import { TimerType, TimeType } from "../reducers/timeStudyReducer";

export type SetIsOpenType = {
  type: typeof types.TIME_STUDY_IS_OPEN;
  isOpen: boolean;
};

export type SetSubjectIdType = {
  type: typeof types.TIME_STUDY_SUBJECT_ID;
  subjectId: unknown;
};

export type SetTopicIdType = {
  type: typeof types.TIME_STUDY_TOPIC_ID;
  topicId: unknown;
};

export type SetIsRunningType = {
  type: typeof types.TIME_STUDY_IS_RUNNING;
  isRunning: boolean;
};
export type SetTimeType = {
  type: typeof types.TIME_STUDY_TIME;
  time: TimeType;
};

export type SetTimerType = {
  type: typeof types.TIME_STUDY_TIMER;
  timer: TimerType;
};

export type SetSecondsInitial = {
  type: typeof types.TIME_STUDY_SECONDS_INITIAL;
  timer: { secondsInitial: number };
};

export type SetMode = {
  type: typeof types.TIME_STUDY_MODE;
  mode: string;
};

export type SetIntervalType = {
  type: typeof types.TIME_STUDY_INTERVAL_ID;
  interval: NodeJS.Timer | null;
};

export type SetTypeStudyType = {
  type: typeof types.TIME_STUDY_TYPE_STUDY;
  typeStudy: number[];
};

export type ResetType = {
  type: typeof types.TIME_STUDY_RESET;
};

export function setIsOpen(value: boolean): SetIsOpenType {
  return {
    type: types.TIME_STUDY_IS_OPEN,
    isOpen: value,
  };
}

export function setSubjectId(value: unknown): SetSubjectIdType {
  return {
    type: types.TIME_STUDY_SUBJECT_ID,
    subjectId: value,
  };
}

export function setTopicId(value: unknown): SetTopicIdType {
  return {
    type: types.TIME_STUDY_TOPIC_ID,
    topicId: value,
  };
}

export function setIsRunning(value: boolean): SetIsRunningType {
  return {
    type: types.TIME_STUDY_IS_RUNNING,
    isRunning: value,
  };
}

export function setTime(value: TimeType): SetTimeType {
  return {
    type: types.TIME_STUDY_TIME,
    time: value,
  };
}

export function setTimer(value: TimerType): SetTimerType {
  return {
    type: types.TIME_STUDY_TIMER,
    timer: value,
  };
}

export function setSecondsInitial(value: number): SetSecondsInitial {
  return {
    type: types.TIME_STUDY_SECONDS_INITIAL,
    timer: { secondsInitial: value },
  };
}

export function setMode(value: string): SetMode {
  return {
    type: types.TIME_STUDY_MODE,
    mode: value,
  };
}

export function setIntervalId(value: NodeJS.Timer | null): SetIntervalType {
  return {
    type: types.TIME_STUDY_INTERVAL_ID,
    interval: value,
  };
}

export function setTypeStudy(value: number[]): SetTypeStudyType {
  return {
    type: types.TIME_STUDY_TYPE_STUDY,
    typeStudy: value,
  };
}

export function resetTime(): ResetType {
  return {
    type: types.TIME_STUDY_RESET,
  };
}
