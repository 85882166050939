import React, { FC, useMemo, useState } from "react";
import {
  IconButton,
  Paper as MuiPaper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { format } from "date-fns";
import { NavLink } from "react-router-dom";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { Settings, Trash2 } from "react-feather";
import plansHttp from "../../../utils/http/plans-http";
import { useFilters, useGlobalFilter, useSortBy, useTable } from "react-table";
import Loader from "../../../components/Loader";
import { PlanType } from "../../../types/plans";
import Chips from "../../../components/Chips";
import { useDispatch, useSelector } from "react-redux";
import { planActive } from "../../../redux/actions/planActions";
import StarIcon from "@material-ui/icons/Star";
import { AppStateType } from "../../../redux/reducers";

const Paper = styled(MuiPaper)(spacing);

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

const EnhancedTableToolbar = () => {
  return (
    <Toolbar>
      <ToolbarTitle>
        <Typography variant="h6" id="tableTitle">
          Planos de Estudos
        </Typography>
      </ToolbarTitle>
    </Toolbar>
  );
};

interface PlanoListType {
  plans: PlanType[];
  getPlans: () => void;
  loading: boolean;
}

const PlanoList: FC<PlanoListType> = ({ plans, getPlans, loading }) => {
  const dispatch = useDispatch();
  const plan = useSelector((state: AppStateType) => state.planReducer);
  const [loadingDefault, setDefaultLoading] = useState<boolean>(false);

  const columns: any = useMemo(
    () => [
      {
        Header: "Plano",
        accessor: "plan",
      },
      {
        Header: "Área",
        accessor: "area",
        Cell: (value: any) => {
          const color = value.value.color;
          return (
            <Chips
              size="small"
              mr={1}
              mb={1}
              label={value.value.area}
              color={color}
            />
          );
        },
      },
      {
        Header: "Padrão Cespe",
        accessor: "cespe",
        Cell: ({ value }: any) => {
          return value ? "Sim" : "Não";
        },
      },
      {
        Header: "Data da Prova",
        accessor: "date_exam",
        Cell: ({ value }: any) => {
          if (value) {
            return format(new Date(value), "dd/MM/yyyy");
          } else {
            return "Sem data definida";
          }
        },
      },
    ],
    []
  );
  const tableInstance = useTable(
    {
      columns,
      data: plans,
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );

  const { headerGroups, rows, prepareRow } = tableInstance;

  const handleDelete = (id: any) => {
    confirmAlert({
      title: "Deseja realmente excluir?",
      message: "Todas suas atividades desse plano serão apagadas.",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            plansHttp.delete(id).then(() => {
              getPlans();
              dispatch(planActive());
            });
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  };

  const handleSetDefault = async (id: string) => {
    try {
      setDefaultLoading(true);
      await plansHttp.setDefault(id);
      dispatch(planActive());
    } catch (e) {
      console.log(e);
    } finally {
      setDefaultLoading(false);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <Paper>
        <EnhancedTableToolbar />
        <TableContainer>
          <MuiTable
            aria-labelledby="tableplanos"
            size={"medium"}
            aria-label="enhanced table"
          >
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => {
                    return (
                      <TableCell
                        {...column.getHeaderProps(
                          (column as any).getSortByToggleProps()
                        )}
                      >
                        <TableSortLabel
                          active={column.isSorted}
                          direction={column.isSortedDesc ? "desc" : "asc"}
                        >
                          {column.render("Header")}
                        </TableSortLabel>
                      </TableCell>
                    );
                  })}
                  <TableCell>Default</TableCell>
                  <TableCell>Ação</TableCell>
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {rows.map((row, i) => {
                const id = row.original.id;
                prepareRow(row);
                return (
                  <TableRow {...row.getRowProps()} hover>
                    {row.cells.map((cell: any) => (
                      <TableCell {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </TableCell>
                    ))}
                    <TableCell align={"center"}>
                      <IconButton
                        onClick={() => handleSetDefault(id as string)}
                        disabled={loadingDefault}
                      >
                        <StarIcon
                          color={plan.active.id === id ? "primary" : "disabled"}
                        />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="details"
                        component={NavLink}
                        size={"small"}
                        to={`/plano/${id}/edit`}
                      >
                        <Settings />
                      </IconButton>
                      <IconButton
                        aria-label="details"
                        size={"small"}
                        onClick={() => handleDelete(id)}
                      >
                        <Trash2 />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </MuiTable>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default PlanoList;
