import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components/macro";
import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  Divider as MuiDivider,
  Grid,
  IconButton as MuiIconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography as MuiTypography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { spacing } from "@material-ui/system";
import Progresso from "../../components/Progresso";
import ButtonIcon from "../../components/ButtonIcon";
import { format, parseISO } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../redux/reducers";
import { useSnackbar } from "notistack";

import { secToTime } from "../../utils/Times";

import cyclesHttp from "../../utils/http/cycles-http";
import { CycleStatusType } from "../../types/cycles";
import { AddBox as AddBoxIcon, Timer as TimerIcon } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import {
  resetTime,
  setIsOpen,
  setSecondsInitial,
  setSubjectId,
} from "../../redux/actions/timeStudyActions";
import Loader from "../../components/Loader";
import { Helmet } from "react-helmet-async";

const Card = styled(MuiCard)(spacing);

const IconButton = styled(MuiIconButton)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
`;

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#243043",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableCellRow = withStyles((theme) => ({
  root: {
    border: "1px solid #243043",
  },
}))(TableCell);

const LabelInformation = styled.span`
  font-size: 11px;
  color: ${(props) => props.color};
  font-weight: bold;
`;
const Typography = styled(MuiTypography)(spacing);

const Divider = styled(MuiDivider)(spacing);

function TextInformation(cycle: CycleStatusType) {
  let color;
  let text;
  if (cycle.extra > 0) {
    color = "green";
    text = `Extra: ${secToTime(cycle.extra, 1)}`;
  } else if (cycle.remaining > 0) {
    color = "red";
    text = `Falta: ${secToTime(cycle.remaining)} `;
  } else {
    color = "#4782da";
    text = "Concluído";
  }
  return <LabelInformation color={color}>{text}</LabelInformation>;
}

const initialCycle = {
  cycleConcluded: {
    totalSeconds: 0,
    totalDone: 0,
    percentage: 0,
  },
  cycle: {
    updated_at: "",
  },
  status: [],
};

const Cycle = () => {
  const snackbar = useSnackbar();
  const plan = useSelector((state: AppStateType) => state.planReducer);
  const history = useHistory();
  const dispatch = useDispatch();
  const [cycle, setCycle] = useState(initialCycle);
  const [loading, setLoading] = useState<boolean>(false);

  const hangleAction = (row: CycleStatusType, type: string) => {
    if (type === "manual") {
      history.push(`/activity/create?subject_id=${row.subject_id}`);
    }
    if (type === "cronometro") {
      dispatch(resetTime());
      dispatch(setSecondsInitial(row.remaining));
      dispatch(setSubjectId(row.subject_id));
      dispatch(setIsOpen(true));
    }
  };

  const getCycle = useCallback(async () => {
    setLoading(true);
    if (plan.active.id) {
      try {
        const { data } = await cyclesHttp.listRelationshipResource(
          plan?.active?.id,
          "status"
        );
        if (data.data.length === 0) {
          history.push("/planning/cycle/configure");
        }
        setCycle(data.data);
      } catch (error) {
        console.error(error);
        snackbar.enqueueSnackbar("Não foi possível carregar as informações", {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    }
  }, [plan.active, snackbar, history]);

  const reloadCycle = async () => {
    if (!plan.active.id) {
      return;
    }
    try {
      await cyclesHttp.listRelationshipResource(plan?.active?.id, "reload");
      getCycle();
      snackbar.enqueueSnackbar("Ciclo reiniciado!", {
        variant: "success",
      });
    } catch (error) {
      console.error(error);
      snackbar.enqueueSnackbar("Não foi possível carregar as informações", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getCycle();
    }
    return () => {
      isSubscribed = false;
    };
  }, [getCycle]);

  if (loading) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <Helmet title="Ciclo de Estudo" />

      <Grid justify="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            Ciclo de Estudo
          </Typography>
        </Grid>

        <Grid item>
          <ButtonIcon
            title={"Editar"}
            icon={"edit"}
            titleTootip={"Editar Ciclo"}
            onClick={() => history.push("cycle/configure")}
          />
          <ButtonIcon
            title={"Reiniciar ciclo"}
            icon={"replay"}
            titleTootip={"Reiniciar ciclo de estudo"}
            onClick={reloadCycle}
          />
        </Grid>
      </Grid>
      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={3}>
            <CardHeader
              title="Matérias do Ciclo"
              subheader={` Inicío do ciclo:
              ${
                cycle.cycle.updated_at &&
                format(parseISO(cycle.cycle.updated_at), "dd/MM/yyyy HH:mm:ss")
              }`}
            />
            <CardContent>
              <Grid container spacing={3}>
                {cycle.cycleConcluded.percentage === 100 && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant={"h4"}>
                        Parabéns! Ciclo Concluído.
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <ButtonIcon
                        title={"Reniciar o ciclo"}
                        icon={"replay"}
                        titleTootip={"Reniciar ciclo"}
                        onClick={reloadCycle}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  {" "}
                  <Progresso
                    title={`${secToTime(
                      cycle.cycleConcluded.totalDone
                    )}/${secToTime(cycle.cycleConcluded.totalSeconds)}`}
                    value={cycle.cycleConcluded.percentage}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card mb={3}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TableWrapper>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell component="th" scope="row">
                            Matéria
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            Meta
                          </StyledTableCell>
                          <StyledTableCell>Progresso</StyledTableCell>
                          <StyledTableCell width={"20%"}>Ações</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {cycle.status.map((row: any) => (
                          <TableRow key={row.subject_id}>
                            <StyledTableCellRow>{row.title}</StyledTableCellRow>
                            <StyledTableCellRow>
                              {secToTime(row.meta)}
                              <br />
                              {TextInformation(row)}
                            </StyledTableCellRow>
                            <StyledTableCellRow>
                              <Progresso
                                value={
                                  row.concluded > 100 ? 100 : row.concluded
                                }
                              />
                            </StyledTableCellRow>
                            <StyledTableCellRow>
                              <IconButton
                                mr={2}
                                color="primary"
                                onClick={() => hangleAction(row, "manual")}
                              >
                                <AddBoxIcon fontSize="default" />
                              </IconButton>
                              <IconButton
                                mr={2}
                                color="primary"
                                onClick={() => hangleAction(row, "cronometro")}
                              >
                                <TimerIcon fontSize="default" />
                              </IconButton>
                            </StyledTableCellRow>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableWrapper>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Cycle;
