import React, { ChangeEvent, useEffect, useState } from "react";

import useTimer from "../../vendor/react-timer-hook/useTimer";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { setIsOpen, setTimer } from "../../redux/actions/timeStudyActions";
import { Box, Button, Collapse, Grid, TextField } from "@material-ui/core";
import { REVISAOID } from "../../config/constantes";
import InputMask from "react-input-mask";
import { secToTime, timeToSec } from "../../utils/Times";
import { format, parseISO } from "date-fns";
import useSound from "use-sound";
import AlertTimeExpiration from "./AlertTimeExpiration";

const TimerWatch = () => {
  const time = new Date();
  time.setSeconds(time.getSeconds() + 60); // 10 minutes timer
  const [play] = useSound("/static/sons/alert.wav", { volume: 0.25 });
  const {
    seconds,
    minutes,
    hours,
    secondsPassed,
    isRunning,
    start,
    pause,
    reset,
    resume,
  } = useTimer({
    expiryTimestamp: time,
    onExpire: () => {
      timeExpired();
    },
  });
  const [openAlertExpired, setOpenAlertExpired] = useState<boolean>(false);
  const timeStudy = useSelector((state: any) => state.timeStudyReducer);
  const snackbar = useSnackbar();
  const history = useHistory();
  const dispatch = useDispatch();
  const settings = useSelector((state: any) => state.settingsReducer);

  const active = timeStudy.timer.secondsPassed > 0;

  const timeExpired = () => {
    play();
    setOpenAlertExpired(true);
  };
  useEffect(() => {
    if (timeStudy.isRunning && !settings.timer) {
      pause();
      resume();
    }
  }, [resume, pause, settings.timer, timeStudy.isRunning]);

  function startStudy() {
    if (!timeStudy.subjectId || !timeStudy.topicId) {
      snackbar.enqueueSnackbar("Selecione uma matéria e um assunto", {
        variant: "warning",
      });
      return;
    }
    start(timeStudy.timer.secondsInitial);
  }

  function save() {
    setOpenAlertExpired(false);
    const urlParams = new URLSearchParams({
      subject_id: timeStudy.subjectId,
      topic_id: timeStudy.topicId,
      date: format(
        typeof timeStudy.date === "string"
          ? parseISO(timeStudy.date)
          : timeStudy.date,
        "dd/MM/y"
      ),
      hour: format(
        typeof timeStudy.date === "string"
          ? parseISO(timeStudy.date)
          : timeStudy.date,
        "HH:mm"
      ),
      ...(timeStudy.typeStudy.length > 0 && { types: timeStudy.typeStudy }),
      ...(timeStudy.timer.secondsPassed > 0 && {
        seconds:
          timeStudy.timer.seconds === 1
            ? (timeStudy.timer.secondsPassed + 1).toString()
            : timeStudy.timer.secondsPassed.toString(),
      }),
    });
    history.push(`/activity/create?${urlParams}`);
    dispatch(setIsOpen(false));
  }

  const onChangeTimer = (e: ChangeEvent<HTMLInputElement>) => {
    const time = e.target.value;
    let timeToSecond = timeToSec(
      time.substr(0, 2),
      time.substr(3, 2),
      time.substr(6, 2)
    );
    if (isNaN(timeToSecond)) {
      timeToSecond = 0;
    }
    dispatch(setTimer({ ...timeStudy.timer, secondsInitial: timeToSecond }));
  };

  return (
    <>
      <div style={{ textAlign: "center" }}>
        {active ? (
          <>
            <div style={{ fontSize: "50px" }}>
              <span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
            </div>
            <div style={{ fontSize: "15px" }}>
              <span>{secondsPassed.hours}</span>:
              <span>{secondsPassed.minutes}</span>:
              <span>{secondsPassed.seconds}</span>
            </div>
          </>
        ) : (
          <Box px={4} my={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <InputMask
                  mask={"99:99:99"}
                  value={secToTime(timeStudy.timer.secondsInitial, 3)}
                  onChange={onChangeTimer}
                >
                  {(inputProps: any) => (
                    <TextField
                      {...inputProps}
                      margin={"normal"}
                      id="time-id"
                      label="Tempo do Timer"
                      variant="outlined"
                      fullWidth
                      inputProps={{
                        inputMode: "numeric",
                        style: { fontSize: 30, textAlign: "center" },
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                </InputMask>
              </Grid>
            </Grid>
          </Box>
        )}

        <Collapse in={!isRunning}>
          {!active ? (
            <Box my={3} mx={5}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth={true}
                onClick={startStudy}
              >
                {timeStudy.typeStudy.includes(REVISAOID)
                  ? "Iniciar Revisão"
                  : "Iniciar Estudo"}
              </Button>
            </Box>
          ) : (
            <Grid container>
              <Grid item lg={6} xs={12}>
                <Box my={3} mx={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    fullWidth={true}
                    onClick={save}
                  >
                    Salvar
                  </Button>
                </Box>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Box my={3} mx={2}>
                  {timeStudy.timer.secondsPassed ===
                  timeStudy.timer.secondsInitial ? (
                    <Button
                      variant="contained"
                      color="default"
                      size="medium"
                      fullWidth={true}
                      onClick={reset}
                    >
                      Cancelar
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="default"
                      size="medium"
                      fullWidth={true}
                      onClick={resume}
                    >
                      Continuar
                    </Button>
                  )}
                </Box>
              </Grid>
            </Grid>
          )}
        </Collapse>
        <Collapse in={isRunning}>
          <Grid container>
            <Grid item lg={6} xs={12}>
              <Box my={3} mx={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="medium"
                  fullWidth={true}
                  onClick={pause}
                >
                  Pausar
                </Button>
              </Box>
            </Grid>
            <Grid item lg={6} xs={12}>
              <Box my={3} mx={2}>
                <Button
                  variant="contained"
                  color="default"
                  size="medium"
                  fullWidth={true}
                  onClick={reset}
                >
                  Cancelar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Collapse>
      </div>
      <AlertTimeExpiration open={openAlertExpired} onSave={save} />
    </>
    // <div style={{ textAlign: "center" }}>
    //   <div style={{ fontSize: "12px" }}>
    //     <span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
    //   </div>
    //   <p>{isRunning ? "Running" : "Not running"}</p>
    //   <button onClick={start}>Começar</button>
    //   <button onClick={() => pause()}>Pause</button>
    //
    //   <button
    //     onClick={() => {
    //       // Restarts to 5 minutes timer
    //       const time = new Date();
    //
    //       time.setSeconds(time.getSeconds() + 120);
    //       restart(time);
    //     }}
    //   >
    //     Restart
    //   </button>
    // </div>
  );
};

export default TimerWatch;
