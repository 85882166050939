import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import Lottie from "react-lottie";
import animationData from "../../lotties/three-dots-loading.json";
import {
  Container,
  Grid,
  Typography as MuiTypography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { Redirect } from "react-router-dom";

const Typography = styled(MuiTypography)(spacing);

const Wrapper = styled.div`
  padding-top: 3.5rem;
  position: relative;
  text-align: center;
  overflow: hidden;
`;

const Content = styled.div`
  padding: ${(props) => props.theme.spacing(6)}px 0;
  line-height: 150%;
`;

const Title = styled(Typography)`
  opacity: 0.9;
  line-height: 1.4;
  font-size: 1.75rem;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};

  ${(props) => props.theme.breakpoints.up("sm")} {
    font-size: 2rem;
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 2.5rem;
  }

  span {
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

const Subtitle = styled(Typography)`
  font-size: ${(props) => props.theme.typography.h6.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  font-family: ${(props) => props.theme.typography.fontFamily};
  margin: ${(props) => props.theme.spacing(2)}px 0;
`;

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Completed = () => {
  const [seconds, setSeconds] = useState<number>(5);

  useEffect(() => {
    setTimeout(() => {
      setSeconds((oldState) => oldState - 1);
    }, 1000);
  });
  return (
    <Wrapper>
      {seconds <= 0 && <Redirect to={"/"} />}
      <Container>
        <Grid container alignItems="center" justify="center" spacing={4}>
          <Grid item xs={12} sm={9} md={8} lg={7}>
            <Content>
              <Title variant="h1" gutterBottom>
                Cadastro <span>realizado!</span>
              </Title>
              <Grid container justify="center" spacing={4}>
                <Grid item xs={12}>
                  <Lottie
                    options={defaultOptions}
                    width={100}
                    isStopped={false}
                    isPaused={false}
                  />
                </Grid>
                <Grid item xs={12} lg={10}>
                  <Subtitle color="textSecondary">
                    Estamos preparando o sistema para você.
                  </Subtitle>
                  <Subtitle color="textSecondary">
                    Você será redirecionado em {seconds}.
                  </Subtitle>
                </Grid>
              </Grid>
            </Content>
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
  );
};

export default Completed;
