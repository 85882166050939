import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import "./mocks";

import { Provider } from "react-redux";
import { store, persistor } from "./redux/store/index";
import Loader from "./components/Loader";
import { setUpdateUser, signOut } from "./redux/actions/authActions";
import httpAxios from "./utils/http";
//import * as Sentry from "@sentry/react";
//import { BrowserTracing } from "@sentry/tracing";

// Sentry.init({
//   dsn:
//     "https://eece82ad019e48a3bb8f91aeb20aefd0@o1168676.ingest.sentry.io/6260798",
//   integrations: [new BrowserTracing()],
//
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<Loader />} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

const UNAUTHORIZED = 401;
const NOTPAYMET = 402;
const { dispatch } = store;

httpAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === UNAUTHORIZED) {
      dispatch(signOut() as any);
      return Promise.reject();
    }
    if (error.response?.status === NOTPAYMET) {
      dispatch(setUpdateUser() as any);
      return Promise.reject();
    }
    return Promise.reject(error);
  }
);
